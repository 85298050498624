import React, { SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import FocusLock from 'react-focus-lock';
import Button from '../../atoms/Button/Button';
import Input from '../../atoms/Input/Input';
import { searchValidationConfig } from '../../../types/ValidationTypes';

import searchActions from '../../../store/search/actions';

import { AppState } from '../../../store/appstate';
import Term from '../../atoms/Term/Term';
import {
    createEncodedSearchQueryString,
    generateQuoteSearch,
    parseQuery,
    parseSearchUrl,
} from '../../../services/helpers/queryStringHelpers';
import { parseFilters } from '../../../services/helpers/filterHelpers';

import CheckBox from '../../atoms/Checkbox/Checkbox';
import { ConnectedStore } from '../../../types/ConnectedStore';
import RelativeOperationTypes from '../../../types/RelativeOperation';

import DateRange from '../../atoms/DateRange/DateRange';
import {
    saveExactSearch,
    setPerPage,
    UserSettingsState,
} from '../../../store/userSettings/userSettingsSlice';
import { SearchState } from '../../../store/search/searchSlice';
import { useAppDispatch } from '../../../store';
import StoreTerm from '../../atoms/Term/StoreTerm';
import OrderTabType from '../../../types/order/OrderTabType';
import {
    getAccountSearchFilters,
    getAccountSearchFiltersForQuery,
    getOrderSearchFilters,
} from '../../../services/helpers/search/orderAccountSearchHelper';
import Flex from '../../atoms/Box/Flex';
import CloseLineIcon from 'remixicon-react/CloseLineIcon';
import OrderSearchTabType from '../../../types/order/OrderTabType';
import TermCategory from '../../../types/filter/TermCategory';

type Inputs = {
    searchPhrase: string;
    filter: string[];
};

export enum SearchTerms {
    PLACED_AT = 'placedAt',
    EXPIRES_AT = 'expiresAt',
    INVOICES_EXPIRES_AT = 'connectedAccounts.invoices.dueDate',
}

const today = new Date();

const relativeCreatedRanges = [
    { name: 'today', label: 'Today', value: '0d' },
    { name: 'yesterday', label: 'Yesterday', value: 'yesterday' },
    { name: 'last7days', label: 'Last 7 days', value: '-7d' },
    { name: 'last30days', label: 'Last 30 days', value: '-30d' },
    {
        name: 'custom',
        label: 'Custom',
        value: '-1d',
    },
];

const relativeExpiresRanges = [
    { name: 'today', label: 'Today', value: '0d' },
    { name: 'within7days', label: 'Within 7 days', value: '+7d' },
    { name: 'within30days', label: 'Within 30 days', value: '+30d' },
    {
        name: 'custom',
        label: 'Custom',
        value: '+1d',
    },
];

const dateRanges = [
    {
        maxDate: today,
        label: 'Created',
        SearchTerm: SearchTerms.PLACED_AT,
        ranges: relativeCreatedRanges,
        relativeLabel: { type: 'Last', operation: RelativeOperationTypes.SUBTRACT },
    },
    {
        label: 'Expires',
        SearchTerm: SearchTerms.EXPIRES_AT,
        ranges: relativeExpiresRanges,
        relativeLabel: { type: 'Within', operation: RelativeOperationTypes.ADD },
    },
    {
        label: 'Notification due dates',
        SearchTerm: SearchTerms.INVOICES_EXPIRES_AT,
    },
];

interface SearchFormProps {
    setResetTableSelects: React.Dispatch<SetStateAction<boolean>>;
    theme: DefaultTheme;
}

const SearchForm: React.FC<SearchFormProps> = ({
    setResetTableSelects,
    theme,
}: SearchFormProps) => {
    const SEARCH_PHRASE = 'searchPhrase';
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm<Inputs>();
    const { terms, isSearching, searchCancelToken, isSearchingAccounts, accountSearchCancelToken } =
        useSelector<AppState, SearchState>(s => s.search);
    const { orderColumnFilters: sessionColumns, exactSearch: sessionExactSearch = false } =
        useSelector<AppState, UserSettingsState>(s => s.userSettings);
    const availableStores =
        useSelector<AppState, ConnectedStore[]>(s => s.session.availableStores) ?? [];

    const { pathname, search } = useLocation();
    const { page, sort, query, columns, perPage, filterMap, tab } = parseSearchUrl(
        search,
        sessionColumns
    );

    const isExactPhrase = (x: string): boolean => !!x && x.startsWith('"') && x.endsWith('"');
    const clearQuotes = (x: string): string => (isExactPhrase(x) ? x.replace(/"/g, '') : x);

    const isExactSearch = (x: string): boolean => {
        if (x.includes(':')) return false;
        if (isExactPhrase(x)) return true;
        return sessionExactSearch;
    };

    const initialExactSearch = isExactSearch(query);
    const currentQuery = clearQuotes(query);

    const [exactSearch, setExactSearch] = useState(initialExactSearch);

    const dispatchSearch = (q: string) => {
        const orderQueryString = createEncodedSearchQueryString(
            parseQuery(q, exactSearch),
            getOrderSearchFilters(filterMap),
            sort,
            perPage,
            columns,
            page,
            OrderSearchTabType.Orders
        );

        const accountQueryStringForDispatch = createEncodedSearchQueryString(
            parseQuery(q, exactSearch),
            getAccountSearchFilters(filterMap),
            sort,
            perPage,
            columns,
            page,
            OrderSearchTabType.Invoices
        );

        dispatch(searchActions.searchOrders(orderQueryString));
        dispatch(searchActions.searchAccounts(accountQueryStringForDispatch));
    };

    const makeSearch = (q: string) => {
        if (isSearching && searchCancelToken) searchCancelToken.cancel();

        if (isSearchingAccounts && accountSearchCancelToken) accountSearchCancelToken.cancel();

        setResetTableSelects(true);

        const currentPhrase = parseQuery(q, isExactPhrase(q));
        const queryString = createEncodedSearchQueryString(
            currentPhrase,
            tab === OrderTabType.Orders
                ? getOrderSearchFilters(filterMap)
                : getAccountSearchFiltersForQuery(filterMap),
            sort,
            perPage,
            columns,
            '1',
            tab
        );

        navigate(
            `${pathname}?${
                search !== '' && search === queryString ? search.replace('?', '') : queryString
            }`
        );
    };

    const onSubmit = (data: Inputs) => {
        const { searchPhrase } = data;

        if (isExactPhrase(searchPhrase) && !exactSearch) {
            setExactSearch(true);
            setValue(SEARCH_PHRASE, searchPhrase.replace(/"/g, ''));
            makeSearch(generateQuoteSearch(getValues(SEARCH_PHRASE)));
            return;
        }

        if (searchPhrase === query.replace(/"/g, '')) {
            dispatchSearch(query);
            return;
        }

        if (!isExactPhrase(searchPhrase) && exactSearch) {
            makeSearch(generateQuoteSearch(getValues(SEARCH_PHRASE)));
            return;
        }

        makeSearch(searchPhrase);
    };

    const handleFilterChange = (termKey: string | undefined, termFilter: string[]) => {
        if (!termKey) return;
        filterMap.set(termKey, termFilter);
        makeSearch(getValues(SEARCH_PHRASE));
    };

    const onExactSearchChange = () => {
        const searchPhrase = getValues(SEARCH_PHRASE);

        setExactSearch(!exactSearch);
        dispatch(saveExactSearch(!exactSearch));

        if (isExactPhrase(query)) {
            makeSearch(searchPhrase);
            return;
        }

        if (exactSearch) {
            makeSearch(searchPhrase.replace(/"/g, ''));
            return;
        }

        makeSearch(generateQuoteSearch(searchPhrase));
    };

    const clearAllFilters = () => {
        filterMap.clear();
        setValue(SEARCH_PHRASE, '');
        makeSearch(getValues(SEARCH_PHRASE));
    };

    const renderTerms = () => {
        return (
            <Flex row gap={theme.layout.gap.xsmall} flexWrap="wrap">
                {tab === OrderTabType.Orders &&
                    dateRanges.map(x => (
                        <DateRange
                            key={x.label}
                            maxDate={x.maxDate}
                            label={x.label}
                            onDateChange={handleFilterChange}
                            initialValue={parseFilters(x.SearchTerm, filterMap)}
                            dateRangeType={x.SearchTerm}
                            relativeRanges={x.ranges}
                            relativeLabel={x.relativeLabel}
                        />
                    ))}

                {!terms.some(x => x.key === 'storeId') && (
                    <StoreTerm
                        key={'storeId'}
                        currentFilters={parseFilters('storeId', filterMap)}
                        availableStores={availableStores}
                        onTermFilterChange={handleFilterChange}
                    />
                )}

                {tab === OrderTabType.Orders &&
                    terms.map(x => (
                        <Term
                            key={x.key}
                            category={TermCategory.Order}
                            term={x}
                            onTermFilterChange={handleFilterChange}
                            currentFilters={parseFilters(x.key, filterMap)}
                            showSearchbar={['storeId']}
                            availableStores={x.key === 'storeId' ? availableStores : []}
                        />
                    ))}
                <ClearAllButton
                    title="Clear all search"
                    data-testid="search-clear-all-button"
                    onClick={() => clearAllFilters()}
                    disabled={filterMap.size === 0 && getValues(SEARCH_PHRASE) === ''}
                >
                    <CloseLineIcon size={theme.icon.size.small} />
                </ClearAllButton>
            </Flex>
        );
    };

    const searchPhrase = register(SEARCH_PHRASE, searchValidationConfig);

    useEffect(() => {
        dispatch(searchActions.terms());
        dispatch(setPerPage(perPage));
    }, [perPage]);

    useEffect(() => {
        dispatchSearch(query);
    }, [search]);

    return (
        <Flex gap={theme.layout.gap.xsmall} column>
            <form onSubmit={handleSubmit(onSubmit)} data-testid="form">
                <Flex row gap={theme.layout.gap.small}>
                    <InputWrapper>
                        <FocusLock disabled={!isSearching}>
                            <Input
                                data-testid="search-input"
                                type="text"
                                name={searchPhrase.name}
                                defaultValue={currentQuery}
                                setRef={searchPhrase.ref}
                                placeholder="What are you looking for?"
                                onChange={searchPhrase.onChange}
                                id="order-search"
                            />
                        </FocusLock>
                    </InputWrapper>

                    <Button type="submit" primary large data-testid="order-search-button">
                        Search
                    </Button>

                    <CheckBox
                        dataTestId="exactMatch"
                        checked={exactSearch}
                        onChange={() => onExactSearchChange()}
                    >
                        Exact match
                    </CheckBox>
                </Flex>
            </form>

            {terms && terms.length > 0 && renderTerms()}

            {errors.searchPhrase && (
                <p className="error-message" role="alert">
                    {errors.searchPhrase?.message}
                </p>
            )}
        </Flex>
    );
};

const InputWrapper = styled.div`
    width: 40rem;
`;

const ClearAllButton = styled.button`
    display: flex;
    align-items: center;
    background: ${props => props.theme.colors.button.light};
    color: ${props => props.theme.colors.black};
    border-radius: 0.5rem;
    border: none;

    &:disabled {
        opacity: 0.5;
    }

    &:hover:enabled {
        background: ${props => props.theme.colors.black};
        color: ${props => props.theme.colors.light};
        transition: all 0.6s;
        cursor: pointer;
    }
`;

export default withTheme(SearchForm) as React.ComponentType<Omit<SearchFormProps, 'theme'>>;
