enum PermissionType {
    accountDetails = 'accountdetails',
    checkoutDetails = 'checkoutdetails',
    creditAccount = 'creditaccount',
    customerDetails = 'customerdetails',
    orderDetails = 'orderdetails',
    organization = 'organization',
    settlementDetails = 'settlementdetails',
    periodicReportDetails = 'periodicreportdetails',
}
export default PermissionType;
