import styled, { DefaultTheme, withTheme } from 'styled-components';
import React, { ReactNode } from 'react';

type Props = {
    background?: string;
    children: ReactNode;
    theme: DefaultTheme;
    color: string;
};

type StatusDivProps = {
    color: string;
};

const Pill: React.FC<Props> = ({ children, background, theme, color }: Props) => {
    return (
        <FlexContainer background={background}>
            <StatusContainerDiv>
                <StatusDiv color={color} />
            </StatusContainerDiv>
            <StatusText>{children}</StatusText>
        </FlexContainer>
    );
};

Pill.defaultProps = {
    background: undefined,
};

interface FlexProps {
    background?: string;
}

const FlexContainer = styled.div<FlexProps>`
    display: inline-flex;
    flex-direction: row;
    background-color: ${props => props.background ?? 'transparent'};
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5rem;
    white-space: nowrap;
`;

const StatusContainerDiv = styled.div`
    padding-right: ${props => props.theme.layout.padding.small};
    align-self: center;
`;

const StatusDiv = styled.div<StatusDivProps>`
    width: 1rem;
    height: 1rem;
    border-radius: 5rem;
    align-self: center;
    background-color: ${props => props.color};
`;

const StatusText = styled.span`
    margin-right: ${props => props.theme.layout.padding.xsmall};
    align-self: center;
`;

export default withTheme(Pill) as React.ComponentType<Omit<Props, 'theme'>>;
