import styled, { DefaultTheme, withTheme } from 'styled-components';
import React from 'react';

import { UseFormReturn } from 'react-hook-form';
import Flex from '../../atoms/Box/Flex';
import CheckBox from '../../atoms/Checkbox/Checkbox';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import { PurchaseInputs } from '../../../containers/paylinks/CreatePurchase';
import useFocusInputById from '../../../services/hooks/useFocusInputById';
import ValidationError from '../../atoms/Validation/ValidationError';

interface B2bPurchaseFormProps {
    form: UseFormReturn<PurchaseInputs>;
    theme: DefaultTheme;
}

const B2bPurchaseForm: React.FC<B2bPurchaseFormProps> = ({ theme, form }: B2bPurchaseFormProps) => {
    const {
        register,
        watch,
        getValues,
        formState: { isValid, errors },
    } = form;

    const watchSameAsDelivery = watch('purchase.sameAsDelivery', true);

    const email = register('purchase.email', {
        required: { value: true, message: 'Email required' },
    });
    const phoneNumber = register('purchase.phoneNumber');
    const companyName = register('purchase.companyName', {
        required: { value: true, message: 'Company name required' },
    });

    const sameAsDelivery = register('purchase.sameAsDelivery');
    const organisationNumber = register('purchase.organisationNumber', {
        required: { value: true, message: 'Organisation number required' },
    });
    const reference = register('purchase.reference');
    const costCenter = register('purchase.costCenter');

    const deliveryAddress = register('purchase.delivery.address', {
        required: { value: true, message: 'Delivery address required' },
    });
    const deliveryAddress2 = register('purchase.delivery.address2');
    const deliveryCoAddress = register('purchase.delivery.coAddress');
    const deliveryCity = register('purchase.delivery.city', {
        required: { value: true, message: 'Delivery address city required' },
    });
    const deliveryPostalCode = register('purchase.delivery.postalCode', {
        required: { value: true, message: 'Delivery address postal code required' },
    });

    const invoiceAddress = register('purchase.invoice.address', {
        required: !watchSameAsDelivery
            ? { value: true, message: 'Invoice address required' }
            : undefined,
    });
    const invoiceAddress2 = register('purchase.invoice.address2');
    const invoiceCoAddress = register('purchase.invoice.coAddress');
    const invoiceCity = register('purchase.invoice.city', {
        required: !watchSameAsDelivery
            ? { value: true, message: 'Invoice address city required' }
            : undefined,
    });
    const invoicePostalCode = register('purchase.invoice.postalCode', {
        required: !watchSameAsDelivery
            ? { value: true, message: 'Invoice address postal code required' }
            : undefined,
    });

    useFocusInputById('companyName');

    return (
        <>
            <Flex row gap={theme.layout.gap.large}>
                <Flex column gap={theme.layout.gap.medium} grow={1}>
                    <Flex column gap={theme.layout.gap.small}>
                        <FormTitle>Company</FormTitle>

                        <div>
                            <FormRow first>
                                <GridInput
                                    type="text"
                                    id="companyName"
                                    placeholderRaised={!!getValues('purchase.companyName')}
                                    placeholder="Company Name"
                                    name={companyName.name}
                                    onChange={e => companyName.onChange(e)}
                                    setRef={companyName.ref}
                                    validationError={!!errors.purchase?.companyName}
                                />
                            </FormRow>

                            <FormRow first>
                                <GridInput
                                    type="text"
                                    id="organisationNumber"
                                    placeholderRaised={!!getValues('purchase.organisationNumber')}
                                    placeholder="Organisation Number"
                                    name={organisationNumber.name}
                                    onChange={e => organisationNumber.onChange(e)}
                                    setRef={organisationNumber.ref}
                                    validationError={!!errors.purchase?.organisationNumber}
                                />
                            </FormRow>

                            <FormRow last>
                                <GridInput
                                    type="email"
                                    id="email"
                                    placeholderRaised={!!getValues('purchase.email')}
                                    placeholder="Email"
                                    name={email.name}
                                    onChange={e => email.onChange(e)}
                                    setRef={email.ref}
                                />
                                <GridInput
                                    type="text"
                                    id="phoneNumber"
                                    placeholderRaised={!!getValues('purchase.phoneNumber')}
                                    placeholder="Phone Number (optional)"
                                    name={phoneNumber.name}
                                    onChange={e => phoneNumber.onChange(e)}
                                    setRef={phoneNumber.ref}
                                />
                            </FormRow>
                            {!isValid && (
                                <ValidationError>
                                    <Flex column>
                                        {errors.purchase?.email && (
                                            <span>{errors.purchase.email.message}</span>
                                        )}
                                        {errors.purchase?.organisationNumber && (
                                            <span>
                                                {errors.purchase.organisationNumber.message}
                                            </span>
                                        )}
                                        {errors.purchase?.companyName && (
                                            <span>{errors.purchase.companyName.message}</span>
                                        )}
                                    </Flex>
                                </ValidationError>
                            )}
                        </div>
                    </Flex>

                    <div>
                        <Flex column gap={theme.layout.gap.medium}>
                            <FormRow first last>
                                <GridInput
                                    type="text"
                                    id="reference"
                                    placeholderRaised={!!getValues('purchase.reference')}
                                    placeholder="Reference (optional)"
                                    name={reference.name}
                                    onChange={e => reference.onChange(e)}
                                    setRef={reference.ref}
                                />
                                <GridInput
                                    type="text"
                                    id="costCenter"
                                    placeholderRaised={!!getValues('purchase.costCenter')}
                                    placeholder="Cost center (optional)"
                                    name={costCenter.name}
                                    onChange={e => costCenter.onChange(e)}
                                    setRef={costCenter.ref}
                                />
                            </FormRow>
                        </Flex>
                    </div>
                </Flex>
                <Flex column gap={theme.layout.gap.small} grow={1}>
                    <Flex column>
                        <FormTitle>Delivery address</FormTitle>
                    </Flex>

                    <Flex column>
                        <FormRow first>
                            <GridInput
                                type="text"
                                id="purchase.delivery.address"
                                placeholderRaised={!!getValues('purchase.delivery.address')}
                                placeholder="Address"
                                name={deliveryAddress.name}
                                onChange={e => deliveryAddress.onChange(e)}
                                setRef={deliveryAddress.ref}
                                validationError={!!errors.purchase?.delivery?.address}
                            />
                            <GridInput
                                type="text"
                                id="purchase.delivery.address2"
                                placeholderRaised={!!getValues('purchase.delivery.address2')}
                                placeholder="Apartment, suite etc. (optional)"
                                name={deliveryAddress2.name}
                                onChange={e => deliveryAddress2.onChange(e)}
                                setRef={deliveryAddress2.ref}
                            />
                        </FormRow>

                        <FormRow last>
                            <GridInput
                                type="text"
                                id="purchase.delivery.coAddress"
                                placeholderRaised={!!getValues('purchase.delivery.coAddress')}
                                placeholder="C/O (optional)"
                                name={deliveryCoAddress.name}
                                onChange={e => deliveryCoAddress.onChange(e)}
                                setRef={deliveryCoAddress.ref}
                            />
                            <GridInput
                                type="text"
                                id="purchase.delivery.city"
                                placeholderRaised={!!getValues('purchase.delivery.city')}
                                placeholder="City"
                                name={deliveryCity.name}
                                onChange={e => deliveryCity.onChange(e)}
                                setRef={deliveryCity.ref}
                                validationError={!!errors.purchase?.delivery?.city}
                            />
                            <GridInput
                                type="text"
                                id="purchase.delivery.postalCode"
                                placeholderRaised={!!getValues('purchase.delivery.postalCode')}
                                placeholder="Postal Code"
                                name={deliveryPostalCode.name}
                                onChange={e => deliveryPostalCode.onChange(e)}
                                setRef={deliveryPostalCode.ref}
                                validationError={!!errors.purchase?.delivery?.postalCode}
                            />
                        </FormRow>
                    </Flex>
                    {!isValid && (
                        <ValidationError>
                            <Flex column>
                                {errors.purchase?.delivery?.address && (
                                    <span>{errors.purchase?.delivery?.address.message}</span>
                                )}
                                {errors.purchase?.delivery?.city && (
                                    <span>{errors.purchase?.delivery?.city.message}</span>
                                )}
                                {errors.purchase?.delivery?.postalCode && (
                                    <span>{errors.purchase?.delivery?.postalCode.message}</span>
                                )}
                            </Flex>
                        </ValidationError>
                    )}

                    <div>
                        <Flex row alignItems="center" justifyContent="space-between">
                            <FormTitle>Invoice address</FormTitle>
                            <CheckBox
                                id="purchase.sameAsDelivery"
                                name={sameAsDelivery.name}
                                onChange={e => sameAsDelivery.onChange(e)}
                                setRef={sameAsDelivery.ref}
                                onBlur={sameAsDelivery.onBlur}
                            >
                                Same as delivery address
                            </CheckBox>
                        </Flex>

                        {!watchSameAsDelivery && (
                            <>
                                <FormRow first>
                                    <GridInput
                                        type="text"
                                        id="purchase.invoice.address"
                                        placeholderRaised={!!getValues('purchase.invoice.address')}
                                        placeholder="Address"
                                        name={invoiceAddress.name}
                                        onChange={e => invoiceAddress.onChange(e)}
                                        setRef={invoiceAddress.ref}
                                        validationError={!!errors.purchase?.invoice?.address}
                                    />
                                    <GridInput
                                        type="text"
                                        id="purchase.invoice.address2"
                                        placeholderRaised={!!getValues('purchase.invoice.address2')}
                                        placeholder="Apartment, suite etc. (optional)"
                                        name={invoiceAddress2.name}
                                        onChange={e => invoiceAddress2.onChange(e)}
                                        setRef={invoiceAddress2.ref}
                                    />
                                </FormRow>

                                <FormRow last>
                                    <GridInput
                                        type="text"
                                        id="purchase.invoice.coAddress"
                                        placeholderRaised={
                                            !!getValues('purchase.invoice.coAddress')
                                        }
                                        placeholder="C/O (optional)"
                                        name={invoiceCoAddress.name}
                                        onChange={e => invoiceCoAddress.onChange(e)}
                                        setRef={invoiceCoAddress.ref}
                                    />
                                    <GridInput
                                        type="text"
                                        id="purchase.invoice.city"
                                        placeholderRaised={!!getValues('purchase.invoice.city')}
                                        placeholder="City"
                                        name={invoiceCity.name}
                                        onChange={e => invoiceCity.onChange(e)}
                                        setRef={invoiceCity.ref}
                                        validationError={!!errors.purchase?.invoice?.city}
                                    />
                                    <GridInput
                                        type="text"
                                        id="purchase.invoice.postalCode"
                                        placeholderRaised={
                                            !!getValues('purchase.invoice.postalCode')
                                        }
                                        placeholder="Postal Code"
                                        name={invoicePostalCode.name}
                                        onChange={e => invoicePostalCode.onChange(e)}
                                        setRef={invoicePostalCode.ref}
                                        validationError={!!errors.purchase?.invoice?.postalCode}
                                    />
                                </FormRow>

                                {!isValid && (
                                    <ValidationError>
                                        <Flex column>
                                            {errors.purchase?.invoice?.address && (
                                                <span>
                                                    {errors.purchase?.invoice?.address.message}
                                                </span>
                                            )}
                                            {errors.purchase?.invoice?.city && (
                                                <span>
                                                    {errors.purchase?.invoice?.city.message}
                                                </span>
                                            )}
                                            {errors.purchase?.invoice?.postalCode && (
                                                <span>
                                                    {errors.purchase?.invoice?.postalCode.message}
                                                </span>
                                            )}
                                        </Flex>
                                    </ValidationError>
                                )}
                            </>
                        )}
                    </div>
                </Flex>
            </Flex>
        </>
    );
};

export default withTheme(B2bPurchaseForm) as React.ComponentType<
    Omit<B2bPurchaseFormProps, 'theme'>
>;

const FormTitle = styled.div`
    font-size: ${props => props.theme.text.size.large};
    font-family: ${props => props.theme.text.font.bold};
`;
