import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import { AppState } from '../../../store/appstate';
import Spinner from '../../atoms/Spinner/Spinner';
import { AppThunkAction } from '../../../store/thunk';
import { DashboardState } from '../../../store/dashboard/dashboardSlice';
import { formatNumberWithoutDecimals } from '../../../services/helpers/numberFormats';
import Logger from '../../../services/logging/logger';
import { useAppDispatch } from '../../../store';

interface ShortcutProps {
    title: string;
    id: string;
    queryString: string;
    theme: DefaultTheme;
    path: string;
    dispatchMethod: AppThunkAction<Promise<void>>;
}

const Shortcut: React.FC<ShortcutProps> = ({
    theme,
    id,
    title,
    queryString,
    path,
    dispatchMethod,
}: ShortcutProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { dashboardSearches } = useSelector<AppState, DashboardState>(s => s.dashboard);

    const getPropertyTitleFromSearches = () => {
        if (id in dashboardSearches)
            return (
                <>
                    <Title>{title}</Title>
                    <TotalOrders>{formatNumberWithoutDecimals(dashboardSearches[id])}</TotalOrders>
                </>
            );
        return <Spinner color={theme.colors.primary} size={8} loading />;
    };

    const onCardClick = () => {
        Logger.information('Clicked on dashboardcard', {
            query: queryString,
        });

        navigate(`${path}?${queryString}`);

        window.location.reload();
    };

    useEffect(() => {
        if (Object.keys(dashboardSearches).length === 0 || !(id in dashboardSearches)) {
            dispatch(dispatchMethod);
        }
    }, [dashboardSearches]);

    return <Container onClick={() => onCardClick()}>{getPropertyTitleFromSearches()}</Container>;
};

export default withTheme(Shortcut) as React.ComponentType<Omit<ShortcutProps, 'theme'>>;

export const Container = styled.div`
    padding: 1rem;
    display: flex;
    flex-direction: row;
    gap: 4rem;
    margin: 0.5rem 0;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: 0.5rem;
    border: 0.2rem solid ${props => props.theme.colors.border};
    margin-bottom: 0.3rem;
    background: white;
    height: 2.5rem;

    &:hover {
        background: #f0f0f0;
        cursor: pointer;
    }
`;

const Title = styled.div`
    color: ${props => props.theme.colors.text.primary};
`;

const TotalOrders = styled.div`
    color: ${props => props.theme.colors.text.secondary};
    font-size: 1.4rem;
`;
