/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { groupNameValidationConfig } from '../../../../types/ValidationTypes';
import { AppState } from '../../../../store/appstate';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import GridInput from '../../../atoms/Input/GridInput';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import FormRow from '../../../atoms/Form/FormRow';
import Spinner from '../../../atoms/Spinner/Spinner';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import { GroupsState } from '../../../../store/admin/groups/groupsSlice';
import SuccessMessage from '../../../atoms/Message/Success/SuccessMessage';
import Button from '../../../atoms/Button/Button';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';

interface CreateGroupFormProps {
    onCreate: (organization: string, groupName: string) => void;
    hide: () => void;
    organizationId: string;
    organizationName: string;
    theme: DefaultTheme;
}

type Inputs = {
    groupName: string;
};

const CreateNewGroupForm: React.FC<CreateGroupFormProps> = ({
    onCreate,
    hide,
    organizationId,
    organizationName,
    theme,
}: CreateGroupFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const navigate = useNavigate();
    const [disabled, setDisabled] = useState(false);
    const [groupName, setGroupName] = useState('');
    const { groupCreatedResponse, groupWasCreated, isGroupCreating, createError } = useSelector<
        AppState,
        GroupsState
    >(s => s.admin.groups);

    const hasError = errors.groupName !== undefined || false;

    const onSubmit = (data: Inputs) => {
        setDisabled(true);
        onCreate(organizationId, data.groupName);
        setGroupName(data.groupName);
    };

    const onGotoButtonClick = () => {
        hide();

        navigate(`/admin/groups/${groupCreatedResponse.entityId}`, {
            state: { tab: 'permissions' },
        });
    };

    const groupNameReg = register('groupName', groupNameValidationConfig);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Create Group in {organizationName}</HeaderTitle>
            </ModalContainer>
            <ModalContainer position="content">
                <Form onSubmit={handleSubmit(onSubmit)} data-testid="form" id="group-form">
                    <InputContainer>
                        <FormRow first last>
                            <GridInput
                                type="text"
                                id="groupName"
                                placeholder="Group name"
                                setRef={groupNameReg.ref}
                                name={groupNameReg.name}
                                onChange={groupNameReg.onChange}
                                disabled={disabled}
                                validationError={hasError}
                            />
                        </FormRow>
                    </InputContainer>

                    {groupWasCreated && (
                        <>
                            <FormItemContainer>
                                <SuccessMessage>Group {groupName} was created!</SuccessMessage>
                            </FormItemContainer>
                        </>
                    )}
                </Form>
            </ModalContainer>
            <ModalContainer position="error">
                {createError && (
                    <ErrorMessage error={createError} errorHeader={ErrorType.LoadGroups} />
                )}
            </ModalContainer>
            <ModalContainer position="footer">
                <FooterButtonContainer>
                    {!groupWasCreated && (
                        <Button onClick={hide} large>
                            Cancel
                        </Button>
                    )}
                    {!groupWasCreated && (
                        <Button
                            tabIndex={0}
                            type="submit"
                            form="group-form"
                            primary
                            disabled={disabled}
                            large
                        >
                            {isGroupCreating ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Create Group</span>
                            )}
                        </Button>
                    )}
                    {groupWasCreated && (
                        <Button onClick={onGotoButtonClick} large primary>
                            Set Group Permission
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const Form = styled.form`
    display: block;
    height: 100%;
`;

const InputContainer = styled.div`
    width: 100%;
    padding-bottom: ${props => props.theme.layout.padding.xxl};
`;

const FormItemContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2.5rem;
`;

export default withTheme(CreateNewGroupForm) as React.ComponentType<
    Omit<CreateGroupFormProps, 'theme'>
>;
