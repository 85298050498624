import React from 'react';
import styled from 'styled-components';

interface Props {
    countryCode: string | undefined;
}

const FlagIcon: React.FC<Props> = ({ countryCode }: Props) => {
    return (
        <>
            {countryCode && (
                <Img
                    src={`${process.env.PUBLIC_URL}/img/flags/${countryCode}.png`}
                    alt={countryCode}
                />
            )}
        </>
    );
};

const Img = styled.img`
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    align-self: center;
`;

export default FlagIcon;
