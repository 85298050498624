import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import TimeStamp from '../Timeline/TimeStamp';
import { formatShortDate, formatTime } from '../../../services/helpers/dateTimeFormats';
import groupBy from '../../../services/helpers/groupBy';
import { CheckoutEvent } from '../../../types/paylink/CheckoutSession';
import StyledLinkWithCopy from '../../atoms/Link/StyledLinkWithCopy';

interface PaylinkTimelineProps {
    events: CheckoutEvent[];
    theme: DefaultTheme;
}

const PaylinkTimeline: React.FC<PaylinkTimelineProps> = ({
    events,
    theme,
}: PaylinkTimelineProps) => {
    const sortedEvents = [...events].sort(
        (a, b) => new Date(b.occurredAt).getTime() - new Date(a.occurredAt).getTime()
    );

    const grouped: Map<Date, Array<CheckoutEvent>> = groupBy(
        sortedEvents,
        (event: { occurredAt: string }) => formatShortDate(new Date(event.occurredAt))
    );

    const parseToEventTitle = (title: string) => {
        switch (title) {
            case 'PaymentFailed':
                return 'Payment failed';
            case 'PaymentSelected':
                return 'Payment selected';
            case 'PaymentCompleted':
                return 'Payment completed';
            case 'PaylinkSent':
                return 'Pay Link sent';
            case 'PaylinkCreated':
                return 'Pay Link created';
            case 'PaylinkExpired':
                return 'Pay Link expired';
            case 'MerchantNotificationFailed':
                return 'Merchant notification failed';
            case 'PaylinkAborted':
                return 'Pay Link aborted';
            default:
                return 'Event title not found';
        }
    };

    return (
        <Timeline>
            <Header>
                <TimeLineIcon size={theme.icon.size.normal} />
                <h3>Pay Link events</h3>
            </Header>

            {Array.from(grouped.values()).map((groupedEvents, index) => {
                const isFirst = index === 0;

                return groupedEvents.map((event, eventIndex) => (
                    <EventContainer key={`container-${event.eventType}-${event.occurredAt}`}>
                        {eventIndex === 0 && (
                            <TimeLineDateGroup>
                                <DateColumn>
                                    <DateTitle>
                                        {formatShortDate(new Date(event.occurredAt))}
                                    </DateTitle>
                                </DateColumn>
                            </TimeLineDateGroup>
                        )}

                        <TimeLineDateGroup>
                            <TimeColumn>
                                <TimeStampColored>
                                    {formatTime(new Date(event.occurredAt))}
                                </TimeStampColored>
                            </TimeColumn>
                        </TimeLineDateGroup>

                        <Event key={`event-${event.eventType}`}>
                            {isFirst && eventIndex === 0 && <StatusText>Latest</StatusText>}
                            <EventType>{parseToEventTitle(event.eventType)}</EventType>

                            {event.metaData && (
                                <>
                                    {event.metaData.message && (
                                        <Reason>{event.metaData.message}</Reason>
                                    )}

                                    {event.metaData.orderId && (
                                        <Reason>With orderId {event.metaData.orderId}</Reason>
                                    )}

                                    {event.metaData.paymentMethod && (
                                        <Reason>
                                            <Value>{event.metaData.paymentMethod}</Value>{' '}
                                            {event.metaData.productCode && (
                                                <>
                                                    with product code{' '}
                                                    <Value>{event.metaData.productCode}</Value>
                                                </>
                                            )}
                                        </Reason>
                                    )}

                                    {event.metaData.abortedBy && (
                                        <Reason>
                                            Aborted By{' '}
                                            <Value>
                                                {event.metaData.performedBy ||
                                                    event.metaData.abortedBy}
                                            </Value>
                                        </Reason>
                                    )}

                                    <Sent>
                                        {event.metaData.value && (
                                            <Reason>
                                                Paylink sent to{' '}
                                                <Value>{event.metaData.value}</Value>
                                            </Reason>
                                        )}
                                        {event.metaData.type && (
                                            <Reason>
                                                by <Value>{event.metaData.type}</Value>
                                            </Reason>
                                        )}
                                    </Sent>

                                    {event.metaData.url && (
                                        <Reason>
                                            Failed with url{' '}
                                            <StyledLinkWithCopy href={event.metaData.url}>
                                                {event.metaData.url}
                                            </StyledLinkWithCopy>
                                        </Reason>
                                    )}
                                </>
                            )}
                        </Event>
                    </EventContainer>
                ));
            })}
        </Timeline>
    );
};

export default withTheme(PaylinkTimeline) as React.ComponentType<
    Omit<PaylinkTimelineProps, 'theme'>
>;

const Timeline = styled.div`
    margin-top: 3rem;
    list-style: none;
    background-color: ${props => props.theme.colors.light};
    border: 0.1rem solid ${props => props.theme.colors.border};
    border-radius: 0.5rem;
    padding: 1rem 2rem 2rem;
`;

const EventContainer = styled.div`
    margin-top: 1rem;
`;

const Header = styled.div`
    margin-right: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
`;

const Value = styled.div`
    color: ${props => props.theme.colors.text.primary};
`;

const Reason = styled.div`
    display: flex;
    gap: 0.5rem;
    color: ${props => props.theme.colors.text.secondary};
    margin: 0.5rem 0;
`;

const StatusText = styled.span`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    margin-bottom: 1rem;
`;

const Event = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0 0 12rem;
    position: relative;
    padding-bottom: 0.5rem;
    background-color: #fbfbfb;
    padding: 2rem;
    width: 40rem;
    color: ${props => props.theme.colors.text.secondary};
    font-size: ${props => props.theme.text.size.xsmall};
`;

const TimeLineDateGroup = styled.li`
    margin-left: 12rem;
    position: relative;
`;

const TimeStampColored = styled(TimeStamp)`
    color: ${props => props.theme.colors.text.secondary};
`;

const DateColumn = styled.div`
    position: absolute;
    left: -12rem;
    width: 10rem;
    text-align: right;
`;

const TimeColumn = styled.div`
    position: absolute;
    left: -7rem;
    margin-top: 0.3rem;
    width: 5rem;
    text-align: right;
`;

const DateTitle = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    height: 3rem;
`;

const Sent = styled.div`
    display: flex;
    gap: 0.5rem;
    font-family: ${props => props.theme.text.size.small};
`;

const EventType = styled.div`
    grid-area: 'content';
    font-family: ${props => props.theme.text.type.bold};
    font-size: ${props => props.theme.text.size.medium};
    color: ${props => props.theme.colors.text.primary};

    > * {
        &:last-child {
            margin-left: 2rem;
        }
    }
`;
