import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FileCopyLine from 'remixicon-react/FileCopyLineIcon';
import { darken } from 'polished';

type Props = {
    value: string;
    tooltip?: string;
    theme: DefaultTheme;
};

const CopyToClipboard: React.FC<Props> = ({ value, tooltip, theme }: Props) => {
    return (
        <NoneStyledButton
            type="button"
            onClick={e => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
            }}
            title={tooltip}
            data-testid="copy-button"
        >
            <FileCopyLine size={theme.icon.size.normal} />
        </NoneStyledButton>
    );
};

const NoneStyledButton = styled.button`
    border: none;
    background: none;
    padding: 0;

    border-radius: 0.5rem;

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.colors.button.light};
    }

    &:active {
        background: ${props => darken(0.25, props.theme.colors.button.light)};
    }
`;

CopyToClipboard.defaultProps = {
    tooltip: undefined,
};

export default withTheme(CopyToClipboard) as React.ComponentType<Omit<Props, 'theme'>>;
