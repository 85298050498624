import React, { ReactNode } from 'react';
import { darken, transparentize } from 'polished';
import styled, { DefaultTheme, withTheme } from 'styled-components';

interface Props {
    type: 'Error' | 'Warning' | 'Info';
    header?: string;
    children: ReactNode;
    theme: DefaultTheme;
}

interface StyledProps {
    color: string;
}

const MessageContainer: React.FC<Props> = ({ header, children, type, theme }: Props) => {
    let color = theme.colors.error;

    if (type === 'Error') color = theme.colors.error;
    if (type === 'Warning') color = theme.colors.warning;
    if (type === 'Info') color = theme.colors.info;

    return (
        <Container data-testid={type} color={color}>
            {header && <Header color={color}>{header}</Header>}
            {children}
        </Container>
    );
};

const Header = styled.h4<StyledProps>`
    color: ${props => darken(0.2, props.color)};
    margin: ${props => props.theme.layout.padding.small} 0
        ${props => props.theme.layout.padding.medium} 0;
`;

const Container = styled.div<StyledProps>`
    color: ${props => props.theme.colors.text.primary};
    background: ${props => transparentize(0.9, props.color)};
    border: thin solid ${props => transparentize(0.4, props.color)};
    border-left: 0.5rem solid ${props => transparentize(0.4, props.color)};
    padding: ${props => props.theme.layout.padding.medium};
    margin: ${props => props.theme.layout.padding.medium} 0;
`;

MessageContainer.defaultProps = {
    header: undefined,
};

export default withTheme(MessageContainer) as React.ComponentType<Omit<Props, 'theme'>>;
