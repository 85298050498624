import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { AppState } from '../../store/appstate';
import { PermissionOperation } from '../../types/PermissionOperation';

const usePermissions = (tags: string[] | undefined) => {
    const permissions = useSelector(
        createSelector(
            (state: AppState) => state.session.user?.permissions ?? [],
            sessionPermissions => sessionPermissions
        )
    );
    const filteredPermissions = permissions.filter(p => tags?.includes(p.tag));
    const read = filteredPermissions.some(x => x.operation === PermissionOperation.read);
    const write = filteredPermissions.some(x => x.operation === PermissionOperation.write);

    return {
        hasReadAndWrite: read && write,
        hasRead: read,
        hasWrite: write,
        hasOperation: (operation: string): boolean =>
            filteredPermissions.some(x => x.operation == operation),
    };
};

export default usePermissions;
