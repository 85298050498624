import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/appstate';
import { UserGroup } from '../../../../types/UserGroup';
import { User } from '../../../../types/User';
import permissionGroups from '../../../../services/helpers/groupPermissions';
import { SessionOrganization } from '../../../../types/SessionUser';
import AddUserToGroup from './AddUserToGroup';
import StyledLink from '../../../atoms/Link/StyledLink';

const collectedPermissions = permissionGroups.flatMap(x => x.permissions);

const GroupHeader: React.FC = () => {
    const organization = useSelector<AppState, SessionOrganization | undefined>(
        s => s.session.selectedOrganization
    );

    const userList = useSelector<AppState, Array<User>>(
        s => s.admin.groups.groupListUsersResponse.data
    );
    const fetchedUsersInGroup = useSelector<AppState, boolean>(
        s => s.admin.groups.fetchedUsersInGroup
    );

    const numberOfStores = useSelector<AppState, string | number | undefined>(s => {
        if (s.session.isLoadingOrganization || s.admin.groups.isSearching) return '-';
        const { group } = s.admin.groups;

        const { length } = group.connectedStores || [];
        return length > 0 ? length : organization?.connectedStores?.length;
    });
    const groupData = useSelector<AppState, UserGroup>(s => s.admin.groups.group);
    const groupName = groupData ? groupData.name : '-';
    const groupPermissions = groupData ? groupData.permissions : [];

    const lonePermissions = (permission: string) =>
        !collectedPermissions.includes(permission.split('-')[1]);

    const findPermissions = (category: string[]) => {
        const read = groupPermissions.find(perm => perm === `read-${category[0]}`);
        const write = groupPermissions.find(perm => perm === `write-${category[0]}`);

        return [read, write].filter(x => x);
    };

    const currentPermissionsByCategory = groupPermissions
        ? [
              ...groupPermissions.filter(lonePermissions),
              ...permissionGroups.flatMap(group => findPermissions(group.permissions)),
          ]
        : [];

    return (
        <Row>
            <Col>
                <Label>Manage Organization</Label>
                <UserRow>
                    <StyledLink to="/admin/manage-access#groups">
                        <BoldText>{organization?.name}</BoldText>
                    </StyledLink>
                </UserRow>
            </Col>
            <Col>
                <Label>Group</Label>
                <BoldText>{groupName}</BoldText>
            </Col>
            <Col>
                <Label>Users</Label>
                <UserRow>{fetchedUsersInGroup ? userList.length : '-'}</UserRow>
            </Col>
            <Col>
                <Label>Permissions</Label>
                <div data-testid="number-of-permissions">{currentPermissionsByCategory.length}</div>
            </Col>
            <Col>
                <Label>Stores</Label>
                <>{numberOfStores}</>
            </Col>
            <Actions>
                {organization && (
                    <AddUserToGroup organizationId={organization.id} groupId={groupData.id} />
                )}
            </Actions>
        </Row>
    );
};

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    font-size: ${props => props.theme.text.size.medium};
`;

const UserRow = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;
const Col = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 ${props => props.theme.layout.padding.medium};
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;
const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
`;

const Actions = styled.div`
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

export default GroupHeader;
