/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DefaultTheme, withTheme } from 'styled-components';
import Lock2LineIcon from 'remixicon-react/Lock2LineIcon';
import Spinner from '../components/atoms/Spinner/Spinner';
import { lighten } from 'polished';
import { AppState } from '../store/appstate';
import { SessionState } from '../store/session/sessionSlice';
import { WithMsalProps, withMsal } from '@azure/msal-react';

interface UserNotFoundProps extends WithMsalProps {
    theme: DefaultTheme;
}

const UserWithoutPermission: React.FC<UserNotFoundProps> = ({
    theme,
    msalContext,
}: UserNotFoundProps) => {
    document.title = 'Merchant Hub - User missing permissions';

    const { isFetchingUser } = useSelector<AppState, SessionState>(s => s.session);

    return (
        <Container>
            {isFetchingUser && (
                <LoadingContainer>
                    <div>Loading User</div>
                    <Spinner color={theme.colors.primary} size={14} loading />
                </LoadingContainer>
            )}
            {!isFetchingUser && (
                <MessageContainer>
                    <Lock2LineIcon size={theme.icon.size.large} />
                    <h1>You are missing permissions</h1>
                    <div>Please contact your administrator to be added to a group.</div>
                    <RefreshButton onClick={() => msalContext.instance.logout()} type="button">
                        Take me back to login
                    </RefreshButton>
                </MessageContainer>
            )}
        </Container>
    );
};

export default withTheme(withMsal(UserWithoutPermission)) as React.ComponentType<
    Omit<UserNotFoundProps, 'theme' | 'msalContext'>
>;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const MessageContainer = styled.div`
    color: ${props => props.theme.colors.text.primary};
    line-height: 2.5rem;
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const RefreshButton = styled.button`
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 0.4rem;
    min-width: 9rem;
    border: 0.15rem solid #3d388b;
    background: #3d388b;
    color: #ffffff;
    font-family: 'HKGrotesk-Medium', Helvetica, Arial;
    padding: 0.5rem;
    margin-top: 2rem;

    &:hover {
        background: ${lighten(0.08, '#3d388b')};
    }
`;
