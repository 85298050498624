import React, { useEffect, useMemo, useRef, useState } from 'react';

import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import LinkIcon from 'remixicon-react/LinkIcon';

import NotImplemented from '../../components/molecules/NotImplemented';
import CreatePaylinkForm from '../../components/molecules/Paylink/CreatePaylinkForm';
import PaylinkFormRow from '../../types/paylink/PaylinkFormRow';
import SelectInput from '../../components/atoms/Input/SelectInput';
import { AppState } from '../../store/appstate';
import { ConnectedStore } from '../../types/ConnectedStore';
import { CheckoutState, clearCheckoutInit } from '../../store/checkout/checkoutSlice';
import ModalContainer from '../../components/atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../components/atoms/Modal/FooterButtonContainer';
import Button from '../../components/atoms/Button/Button';
import Spinner from '../../components/atoms/Spinner/Spinner';
import FormRow from '../../components/atoms/Form/FormRow';
import GridInput from '../../components/atoms/Input/GridInput';
import StoreSelect from '../../components/molecules/StoreSelect';
import Money from '../../components/atoms/Money/Money';
import CustomErrorMessage from '../../components/atoms/Message/Error/CustomErrorMessage';
import { ErrorType } from '../../types/response/ErrorCodes';
import ErrorMessage from '../../components/atoms/Message/Error/ErrorMessage';
import { useAppDispatch } from '../../store';
import EmptyState from '../../components/atoms/Information/EmptyState';
import { hasPermissions } from '../../services/helpers/hasPermissions';
import Radio from '../../components/atoms/Input/Radio';
import B2bPurchaseForm from '../../components/molecules/Paylink/B2bPurchaseForm';
import Flex from '../../components/atoms/Box/Flex';
import { InvoiceSendTypeToNumber } from '../../types/InvoiceSendType';
import CheckoutType from '../../types/CheckoutType';
import { CheckoutInitRows } from '../../types/requests/CheckoutInitRequest';
import CustomerSalesType from '../../types/customer/CustomerSalesType';
import CheckBox from '../../components/atoms/Checkbox/Checkbox';
import { genericPhoneNumberValidationConfig } from '../../types/ValidationTypes';
import { PermissionOperation } from '../../types/PermissionOperation';
import PermissionType from '../../types/PermissionType';
import AutoHeightTransition from '../../components/atoms/Transition/AutoHeightTransition';

const currencies = { SE: 'SEK', FI: 'EUR', NO: 'NOK', DK: 'DKK', DE: 'EUR' } as Record<
    string,
    string
>;

export enum CaptureStrategy {
    Uncaptured = 'Uncaptured',
    Autocaptured = 'Autocaptured',
}

const options = [
    {
        label: 'Sweden (SEK)',
        value: 'SE',
    },
    {
        label: 'Finland (EUR)',
        value: 'FI',
    },
    {
        label: 'Norway (NOK)',
        value: 'NO',
    },
    {
        label: 'Denmark (DKK)',
        value: 'DK',
    },
    {
        label: 'Germany (EUR)',
        value: 'DE',
    },
];

interface CreatePurchaseProps {
    theme: DefaultTheme;
    onHide: () => void;
    type: CheckoutType;
    typeFriendlyName: string;
    onSendForm: (
        formData: PurchaseInputs,
        store: ConnectedStore,
        items: CheckoutInitRows[],
        type: CheckoutType
    ) => void;
    onCompleted: (setState: () => void) => void;
}

export type PurchaseInputs = {
    orderNumber: string;
    email: string;
    phone: string;
    prefill: boolean;
    captureStrategy: CaptureStrategy;
    purchase: {
        invoiceAs: number;
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        organisationNumber: string;
        reference: string;
        costCenter: string;
        companyName: string;
        sameAsDelivery: boolean;
        delivery: {
            address: string;
            address2: string;
            coAddress: string;
            city: string;
            postalCode: string;
        };
        invoice: {
            address: string;
            address2: string;
            coAddress: string;
            city: string;
            postalCode: string;
        };
    };
};

const CreatePurchase: React.FC<CreatePurchaseProps> = ({
    theme,
    onHide,
    type,
    typeFriendlyName,
    onSendForm,
    onCompleted,
}: CreatePurchaseProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const isB2BPurchase =
        type === CheckoutType.SERVICE_INVOICE_PURCHASE || type === CheckoutType.NO_FRAUD_PURCHASE;

    const dispatch = useAppDispatch();

    const [validate, setValidate] = useState(false);
    const [formValues, setFormValues] = useState([] as PaylinkFormRow[]);

    const getOperationBasedOnType = () => {
        switch (type) {
            case CheckoutType.NO_FRAUD_PURCHASE:
                return PermissionOperation.write_fraud_risk_invoice;

            case CheckoutType.SERVICE_INVOICE_PURCHASE:
                return PermissionOperation.write_service_invoice;

            default:
                return PermissionOperation.write;
        }
    };

    const storesFilteredByPermission = (
        useSelector<AppState, ConnectedStore[]>(s => s.session.availableStores) ?? []
    ).filter(x => hasPermissions(PermissionType.checkoutDetails, getOperationBasedOnType(), x.id));

    const countryCodes = storesFilteredByPermission.map(x => {
        if (x.isCheckout) return x.countryCode;
        return false;
    });

    const countryCodeOptions = options.filter(x => countryCodes.includes(x && x.value)) ?? [];
    const [countryCode, setCountryCode] = useState<string>(
        countryCodeOptions.length !== 0 ? countryCodeOptions[0].value : 'SE'
    );

    const filterStore = (store: ConnectedStore) => {
        if (isB2BPurchase)
            return (
                store.countryCode === countryCode &&
                store.customerSalesType === CustomerSalesType.B2B &&
                store.active
            );

        return store.countryCode === countryCode && store.isCheckout && store.active;
    };

    const filteredStoresFromChosenCurrency = useMemo(
        () => storesFilteredByPermission.filter(x => filterStore(x)) ?? [],
        [countryCode]
    );

    const { isInitializingCheckout, initCheckoutFailed } = useSelector<AppState, CheckoutState>(
        s => s.checkout
    );

    const [step, setStep] = useState<number>(1);
    const [store, setStore] = useState<string | number>('');
    const [createIsClicked, setCreateIsClicked] = useState(false);

    const isProcessingPurchase = (isInitializingCheckout || createIsClicked) && !initCheckoutFailed;

    const form = useForm<PurchaseInputs>({
            mode: 'onSubmit',
            defaultValues: {
                captureStrategy: CaptureStrategy.Uncaptured,
                prefill: false,
                purchase: {
                    invoiceAs: InvoiceSendTypeToNumber.EMAIL,
                    sameAsDelivery: true,
                },
            },
            shouldUseNativeValidation: true,
        }),
        {
            register,
            handleSubmit,
            getValues,
            watch,
            formState: { errors },
        } = form;

    const watchCaptureStrategy = watch('captureStrategy', CaptureStrategy.Uncaptured);
    const watchPrefill = watch('prefill', false);

    const captureStrategy = register('captureStrategy');
    const orderNumber = register('orderNumber');
    const email = register('email');
    const phone = register('phone', genericPhoneNumberValidationConfig);
    const prefill = register('prefill');
    const orgNr = register('purchase.organisationNumber');
    const firstName = register('purchase.firstName');
    const lastName = register('purchase.lastName');

    const chosenStore = filteredStoresFromChosenCurrency.find(x => x.id === store);

    const orderTotal =
        formValues
            .filter(x => Object.keys(x).length > 0 && x.quantity && x.unitPrice)
            .reduce((a, b) => a + b.quantity * b.unitPrice, 0) ?? 0;

    const onClickNext = () => {
        if (
            formRef.current &&
            !formRef.current?.reportValidity() &&
            !formRef.current?.checkValidity()
        )
            return;

        setValidate(true);

        setStep(step + 1);
        setValidate(false);
        dispatch(clearCheckoutInit());
    };

    const onClickBack = () => {
        setStep(step - 1);
        setCreateIsClicked(false);
    };

    const onHideCreatePurchaseModal = () => {
        setCreateIsClicked(false);
        dispatch(clearCheckoutInit());
        onHide();
    };

    const onSubmit = (data: PurchaseInputs) => {
        if (!chosenStore) return;

        const cleanedRows = formValues
            .filter(row => row.articleNumber)
            .map(x => {
                return {
                    id: x.articleNumber,
                    description: x.description,
                    quantity: x.quantity,
                    unitPrice: x.unitPrice,
                    vat: x.vatRate,
                    requiresElectronicId: true,
                };
            });

        onSendForm(data, chosenStore, cleanedRows, type);
        setCreateIsClicked(true);
    };

    const renderErrorMessage = (code: number) => {
        switch (code) {
            case 401:
                return (
                    <CustomErrorMessage errorHeader={ErrorType.CreatePaylink}>
                        You do not have permission to create purchases on this store.
                    </CustomErrorMessage>
                );
            default:
                return (
                    <ErrorMessage
                        errorHeader={ErrorType.CreatePaylink}
                        error={initCheckoutFailed}
                    />
                );
        }
    };

    useEffect(() => {
        onCompleted(onHideCreatePurchaseModal);
    }, [isInitializingCheckout]);

    useEffect(() => {
        setStore(
            filteredStoresFromChosenCurrency.length === 1
                ? filteredStoresFromChosenCurrency[0].id
                : ''
        );
    }, [filteredStoresFromChosenCurrency]);

    const renderStep1 = () => {
        return (
            <>
                <h3>Add articles</h3>
                <Flex row>
                    <SelectStoreWrapper>
                        <SelectInput
                            id="label"
                            dataTestId="country-selector"
                            placeholder="Country"
                            onChange={setCountryCode}
                            defaultValue="No country chosen"
                            value={countryCode}
                            options={countryCodeOptions}
                        />
                    </SelectStoreWrapper>
                    <Buffert />
                </Flex>

                <form ref={formRef}>
                    <CreatePaylinkForm
                        countryCode={countryCode}
                        validate={validate}
                        setFormValues={setFormValues}
                        formValues={formValues}
                        onSend={onClickNext}
                        setValidate={setValidate}
                    />
                </form>
            </>
        );
    };

    const renderStep2 = () => {
        return (
            <ConfirmContainer column gap={theme.layout.gap.small}>
                <ConfirmContent>
                    {step === 2 && (
                        <MoneyRow row gap={theme.layout.gap.xLarge} alignItems="center">
                            <div>Order total</div>
                            <MoneyRowValue>
                                <Money>{orderTotal}</Money> {currencies[countryCode]}
                            </MoneyRowValue>
                        </MoneyRow>
                    )}

                    {step === 2 && (
                        <StoreSelect
                            dataTestId="select-store"
                            placeholder="Select store"
                            onChange={setStore}
                            value={store}
                            availableStores={filteredStoresFromChosenCurrency}
                        />
                    )}
                </ConfirmContent>

                <ContentRow column gap={theme.layout.gap.medium}>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        data-testid="create-paylink-form"
                        id="create-paylink-form"
                    >
                        <ConfirmContent>
                            {step === 2 && (
                                <>
                                    <FormRow first last>
                                        <GridInput
                                            type="text"
                                            id="orderNumber"
                                            placeholderRaised={!!getValues('orderNumber')}
                                            placeholder="Order number (optional)"
                                            name={orderNumber.name}
                                            onChange={e => orderNumber.onChange(e)}
                                            setRef={orderNumber.ref}
                                        />
                                    </FormRow>
                                    {chosenStore && type === CheckoutType.PAYLINK && (
                                        <>
                                            <br />
                                            <Flex
                                                row
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <CheckBox
                                                    id="prefill"
                                                    name={prefill.name}
                                                    onChange={e => prefill.onChange(e)}
                                                    setRef={prefill.ref}
                                                    onBlur={prefill.onBlur}
                                                >
                                                    Prefill customer information (all fields
                                                    optional)
                                                </CheckBox>
                                            </Flex>
                                            <AutoHeightTransition
                                                expand={!!watchPrefill}
                                                transitionTime="0.3s"
                                            >
                                                <IndentPrefillContent>
                                                    {chosenStore.customerSalesType ===
                                                    CustomerSalesType.B2B ? (
                                                        <>
                                                            <h3>Company details</h3>
                                                            <FormRow first last>
                                                                <GridInput
                                                                    type="text"
                                                                    id="organisationNumber"
                                                                    placeholderRaised={
                                                                        !!getValues(
                                                                            'purchase.organisationNumber'
                                                                        )
                                                                    }
                                                                    placeholder="Organization number"
                                                                    name={orgNr.name}
                                                                    onChange={e =>
                                                                        orgNr.onChange(e)
                                                                    }
                                                                    setRef={orgNr.ref}
                                                                />
                                                            </FormRow>
                                                            <FormItemRow>
                                                                <h3>Buyer details</h3>
                                                                <FormRow first>
                                                                    <GridInput
                                                                        type="text"
                                                                        id="firstName"
                                                                        placeholderRaised={
                                                                            !!getValues(
                                                                                'purchase.firstName'
                                                                            )
                                                                        }
                                                                        placeholder="First name"
                                                                        name={firstName.name}
                                                                        onChange={e =>
                                                                            firstName.onChange(e)
                                                                        }
                                                                        setRef={firstName.ref}
                                                                    />
                                                                    <GridInput
                                                                        type="text"
                                                                        id="lastName"
                                                                        placeholderRaised={
                                                                            !!getValues(
                                                                                'purchase.lastName'
                                                                            )
                                                                        }
                                                                        placeholder="Family name"
                                                                        name={lastName.name}
                                                                        onChange={e =>
                                                                            lastName.onChange(e)
                                                                        }
                                                                        setRef={lastName.ref}
                                                                    />
                                                                </FormRow>
                                                            </FormItemRow>
                                                            <FormRow last>
                                                                <GridInput
                                                                    type="email"
                                                                    id="email"
                                                                    placeholderRaised={
                                                                        !!getValues('email')
                                                                    }
                                                                    placeholder="Email"
                                                                    name={email.name}
                                                                    onChange={e =>
                                                                        email.onChange(e)
                                                                    }
                                                                    setRef={email.ref}
                                                                />
                                                                <GridInput
                                                                    type="text"
                                                                    id="phone"
                                                                    placeholderRaised={
                                                                        !!getValues('phone')
                                                                    }
                                                                    placeholder="Phone"
                                                                    name={phone.name}
                                                                    onChange={e =>
                                                                        phone.onChange(e)
                                                                    }
                                                                    setRef={phone.ref}
                                                                    validationError={!!errors.phone}
                                                                />
                                                            </FormRow>
                                                        </>
                                                    ) : (
                                                        <FormRow first last>
                                                            <GridInput
                                                                type="email"
                                                                id="email"
                                                                placeholderRaised={
                                                                    !!getValues('email')
                                                                }
                                                                placeholder="Email"
                                                                name={email.name}
                                                                onChange={e => email.onChange(e)}
                                                                setRef={email.ref}
                                                            />
                                                            <GridInput
                                                                type="text"
                                                                id="phone"
                                                                placeholderRaised={
                                                                    !!getValues('phone')
                                                                }
                                                                placeholder="Phone"
                                                                name={phone.name}
                                                                onChange={e => phone.onChange(e)}
                                                                setRef={phone.ref}
                                                                validationError={!!errors.phone}
                                                            />
                                                        </FormRow>
                                                    )}
                                                </IndentPrefillContent>
                                            </AutoHeightTransition>
                                            <br />
                                        </>
                                    )}

                                    <FormItemRow>
                                        <h3>Select sales delivery method</h3>

                                        <RadioContainer row gap={theme.layout.gap.xLarge}>
                                            <Radio
                                                id="captureStrategy-delivery"
                                                name={captureStrategy.name}
                                                defaultChecked
                                                value={CaptureStrategy.Uncaptured}
                                                onChange={e => captureStrategy.onChange(e)}
                                                setRef={captureStrategy.ref}
                                            >
                                                <label htmlFor="captureStrategy-delivery">
                                                    Remote
                                                </label>
                                            </Radio>

                                            <Radio
                                                id="captureStrategy-store"
                                                name={captureStrategy.name}
                                                value={CaptureStrategy.Autocaptured}
                                                onChange={e => captureStrategy.onChange(e)}
                                                setRef={captureStrategy.ref}
                                            >
                                                <label htmlFor="captureStrategy-store">
                                                    In-store
                                                </label>
                                            </Radio>
                                        </RadioContainer>
                                        <Tooltip>
                                            {watchCaptureStrategy == CaptureStrategy.Uncaptured && (
                                                <span>
                                                    Use this option when you sell items on distance,
                                                    for example over phone or email. You will need
                                                    to &#34;Activate&#34; the order when the items
                                                    are about to be shipped to the customer.
                                                </span>
                                            )}

                                            {watchCaptureStrategy ==
                                                CaptureStrategy.Autocaptured && (
                                                <span>
                                                    Use this option when the customer receives the
                                                    items directly, for example in your physical
                                                    store. The order will later get
                                                    &#34;Activated&#34; when the customer has
                                                    completed their purchase.
                                                </span>
                                            )}
                                        </Tooltip>
                                    </FormItemRow>
                                </>
                            )}
                        </ConfirmContent>
                        {step === 3 && isB2BPurchase && <B2bPurchaseForm form={form} />}
                    </form>
                </ContentRow>
            </ConfirmContainer>
        );
    };

    if (
        storesFilteredByPermission &&
        storesFilteredByPermission.filter(x => x.isCheckout).length === 0
    ) {
        return (
            <>
                <ModalContainer position="header">
                    <PaylinkTitle>New Purchase</PaylinkTitle>
                </ModalContainer>
                <ModalContainer position="content">
                    <EmptyState
                        title="There is no stores configured for Purchase"
                        icon={
                            <LinkIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
                        }
                    >
                        If you want a purchase configured please contact help@walleypay.com
                    </EmptyState>
                </ModalContainer>
                <ModalContainer position="footer">
                    <FooterButtonContainer>
                        <Button onClick={onHideCreatePurchaseModal} large>
                            Close
                        </Button>
                    </FooterButtonContainer>
                </ModalContainer>
            </>
        );
    }

    return storesFilteredByPermission ? (
        <>
            <ModalContainer position="header">
                <PaylinkTitle>New purchase - {typeFriendlyName}</PaylinkTitle>
            </ModalContainer>

            <ModalContainer position="content">
                {step === 1 && renderStep1()}
                {(step === 2 || step === 3) && renderStep2()}
            </ModalContainer>

            {initCheckoutFailed && (
                <ModalContainer position="error">
                    {renderErrorMessage(initCheckoutFailed.status)}
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <div>
                    <FooterButtonContainer>
                        <Button
                            disabled={isInitializingCheckout}
                            onClick={onHideCreatePurchaseModal}
                            large
                        >
                            Cancel
                        </Button>
                        <Button disabled={step === 1} onClick={() => onClickBack()} large>
                            Back
                        </Button>

                        {((step === 2 && !isB2BPurchase) || step === 3) && (
                            <Button
                                data-testid="form-submit"
                                tabIndex={0}
                                form="create-paylink-form"
                                type="submit"
                                primary
                                large
                                disabled={isProcessingPurchase || store === ''}
                            >
                                {isProcessingPurchase ? (
                                    <Spinner color={theme.colors.light} size={8} loading />
                                ) : (
                                    <span>Create</span>
                                )}
                            </Button>
                        )}

                        {(step < 2 || (isB2BPurchase && step === 2)) && (
                            <Button
                                data-testid="form-next"
                                onClick={() => onClickNext()}
                                tabIndex={0}
                                primary
                                large
                                disabled={
                                    isInitializingCheckout ||
                                    formValues.length === 1 ||
                                    (step === 2 && store === '')
                                }
                            >
                                {isProcessingPurchase ? (
                                    <Spinner color={theme.colors.light} size={8} loading />
                                ) : (
                                    <span>Next</span>
                                )}
                            </Button>
                        )}
                    </FooterButtonContainer>
                </div>
            </ModalContainer>
        </>
    ) : (
        <NotImplemented />
    );
};

export default withTheme(CreatePurchase) as React.ComponentType<Omit<CreatePurchaseProps, 'theme'>>;

const ContentRow = styled(Flex)`
    margin-bottom: 2rem;
`;

const MoneyRow = styled(Flex)`
    margin-bottom: 3rem;
`;

const MoneyRowValue = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    font-size: ${props => props.theme.text.size.xlarge};
`;

const ConfirmContainer = styled(Flex)`
    padding: 2rem;
`;

const ConfirmContent = styled.div`
    width: 50%;
`;

const Buffert = styled.div`
    width: 2.7rem;
`;

const PaylinkTitle = styled.a`
    font-family: ${props => props.theme.text.font.bold};
    margin-right: 2rem;
    font-size: ${props => props.theme.text.size.xlarge};
    line-height: 2.5rem;
`;

const SelectStoreWrapper = styled.div`
    width: 20rem;
    justify-content: flex-end;
    margin-left: auto;
    align-self: center;
`;

const RadioContainer = styled(Flex)`
    padding: 1rem 2rem;
    margin: 0.5rem 0;
    flex: 1;
`;

const Tooltip = styled.div`
    background-color: ${props => props.theme.colors.accent};
    padding: 2rem;
`;

const FormItemRow = styled.div`
    margin-top: 2rem;
`;

const IndentPrefillContent = styled.div`
    margin-left: 3rem;
`;
