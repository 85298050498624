/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { config } from '../../config';
import { ConnectedStore } from '../../types/ConnectedStore';
import { Organization } from '../../types/Organization';
import ResponseError from '../../types/response/ResponseError';
import { SessionOrganization, SessionUser } from '../../types/SessionUser';

export interface CesSurvery {
    expiryTime?: number;
    show: boolean;
    tab?: boolean;
}

interface Surveys {
    [key: string]: CesSurvery;
}

export interface SessionState {
    isAuthenticated: boolean;
    token?: string;
    user?: SessionUser;
    expiryTime: number;
    nextRefresh?: number;
    globalError?: unknown;
    selectedOrganization: SessionOrganization;
    isFetchingUser?: boolean;
    organization?: string;
    availableStores: ConnectedStore[];
    isLoadingOrganization: boolean;
    showProcessingNotCompletedMessage: boolean;
    selectedOrganizationPending: boolean;
    hasSelectedOrganization: boolean;
    failedFetchingUser: boolean;
    cesSurvey: CesSurvery;
    cesMultiSurvey?: Surveys;
}

const initialState: SessionState = {
    isAuthenticated: false,
    token: undefined,
    user: {} as SessionUser,
    expiryTime: 0,
    nextRefresh: undefined,
    globalError: undefined,
    selectedOrganization: { id: '', name: '', blockInvites: false },
    isFetchingUser: false,
    organization: '',
    availableStores: [],
    isLoadingOrganization: false,
    showProcessingNotCompletedMessage: false,
    selectedOrganizationPending: false,
    failedFetchingUser: false,
    hasSelectedOrganization: false,
    cesSurvey: {
        expiryTime: undefined,
        show: false,
        tab: false,
    },
    cesMultiSurvey: {},
};
export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        userAuthenticated: (
            state,
            action: PayloadAction<{
                token: string;
                expiryTime: number;
            }>
        ) => {
            state.isAuthenticated = true;
            state.token = action.payload.token;
            state.expiryTime = action.payload.expiryTime;
        },
        userLoggedOut: state => {
            state.isAuthenticated = false;
            state.token = undefined;
            state.user = {} as SessionUser;
            state.selectedOrganization = { id: '', name: '', blockInvites: false };
            state.failedFetchingUser = false;
        },
        getUserSucceeded: (
            state,
            action: PayloadAction<{
                user: SessionUser;
                nextRefresh: number;
            }>
        ) => {
            state.user = {
                id: action.payload.user.id,
                email: action.payload.user.email,
                name: action.payload.user.name,
                permissions: action.payload.user.permissions,
                organization: action.payload.user.organization,
                isInternal: action.payload.user.organization?.id === config.InternalOrganization,
            } as SessionUser;

            if (!state.hasSelectedOrganization) {
                const org = action.payload.user.organization ?? {
                    id: state.user.organization?.id ?? '',
                    name: state.user.organization?.name ?? '',
                    blockInvites: state.user.organization?.blockInvites ?? false,
                };
                state.selectedOrganization = { ...org, connectedStores: state.availableStores };
            }

            state.nextRefresh = action.payload.nextRefresh;
            state.isFetchingUser = false;
        },
        userErrorOccured: (state, action: PayloadAction<ResponseError>) => {
            state.globalError = action.payload;
            state.isFetchingUser = false;
            state.failedFetchingUser = true;
        },
        userRefreshErrorOccurred: (state, action: PayloadAction<ResponseError>) => {
            state.globalError = action.payload;
            state.isFetchingUser = false;
        },
        setSelectedOrganizationStarted: state => {
            state.selectedOrganizationPending = true;
        },
        setSelectedOrganizationSucceeded: (state, action: PayloadAction<Organization>) => {
            state.selectedOrganization = action.payload;
            state.selectedOrganizationPending = false;
            state.hasSelectedOrganization = true;
        },
        setSelectedOrganizationFailed: state => {
            state.selectedOrganization = state.user?.organization ?? {
                id: '',
                name: '',
                blockInvites: false,
            };
            state.selectedOrganizationPending = false;
            state.hasSelectedOrganization = false;
        },
        isFetchingUser: (state, action: PayloadAction<boolean>) => {
            state.isFetchingUser = action.payload;
            state.failedFetchingUser = false;
        },
        getOrganizationStarted: state => {
            state.isLoadingOrganization = true;
        },
        getOrganizationSucceeded: (state, action: PayloadAction<Organization>) => {
            state.availableStores = action.payload.connectedStores;
            state.isLoadingOrganization = false;
            if (!state.hasSelectedOrganization)
                state.selectedOrganization.connectedStores = action.payload.connectedStores;
        },
        getOrganizationFailed: state => {
            state.isLoadingOrganization = false;
            state.availableStores = [];
        },
        showProcessingNotComplete: (state, action: PayloadAction<boolean>) => {
            state.showProcessingNotCompletedMessage = action.payload;
        },
        setCesSurvey: (state, action: PayloadAction<{ survey: CesSurvery; key: string }>) => {
            state.cesMultiSurvey = {
                ...(state.cesMultiSurvey ?? {}),
                [action.payload.key]: {
                    ...((state.cesMultiSurvey ?? {})[action.payload.key] ?? {}),
                    ...action.payload.survey,
                },
            };

            state.cesSurvey = {
                ...state.cesSurvey,
                ...action.payload.survey,
            };
        },
    },
});

export const {
    userAuthenticated,
    userLoggedOut,
    getUserSucceeded,
    userErrorOccured,
    userRefreshErrorOccurred,
    setSelectedOrganizationStarted,
    setSelectedOrganizationSucceeded,
    setSelectedOrganizationFailed,
    isFetchingUser,
    getOrganizationStarted,
    getOrganizationSucceeded,
    getOrganizationFailed,
    showProcessingNotComplete,
    setCesSurvey,
} = sessionSlice.actions;

export default sessionSlice.reducer;
