/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';

interface AccordionProps {
    children: ReactNode;
    expandItemWithIndex?: number;
    headerCanBeClicked?: (key: string) => boolean;
    theme: DefaultTheme;
}

const Accordion: React.FC<AccordionProps> = ({
    children,
    expandItemWithIndex,
    headerCanBeClicked,
    theme,
}: AccordionProps) => {
    const items = React.Children.toArray(children).filter(x => x);
    const [isActivePanel, setActivePanel] = useState(
        items.map((_, i) => (i === expandItemWithIndex ? true : undefined)) as (
            | boolean
            | undefined
        )[]
    );
    const toggleItem = (index: number) => {
        if (items.length === 1) return;
        setActivePanel(prevState => ({ ...prevState, [index]: !prevState[index] }));
    };

    return (
        <>
            {items.map((child: any, i) => {
                const itemKey = `key${i}`;
                if (Array.isArray(child.props.children)) {
                    return (
                        <AccordionContainer key={itemKey} isOpen={isActivePanel[i]}>
                            <Header
                                data-testid="accordion-header"
                                onClick={() => toggleItem(i)}
                                canBeClicked={items.length > 1}
                            >
                                {items.length > 1 && (
                                    <AccordionArrowContainer>
                                        {isActivePanel[i] ? (
                                            <ArrowUpSLineIcon size={theme.icon.size.small} />
                                        ) : (
                                            <ArrowDownSLineIcon size={theme.icon.size.small} />
                                        )}
                                    </AccordionArrowContainer>
                                )}
                                <HeaderData>{child.props.children[0]}</HeaderData>
                            </Header>
                            <Content data-testid="accordion-content" isOpen={isActivePanel[i]}>
                                {child.props.children[1]}
                                {child.props.children[2]}
                            </Content>
                        </AccordionContainer>
                    );
                }

                return (
                    <React.Fragment key={itemKey}>
                        <Header
                            canBeClicked={headerCanBeClicked ? headerCanBeClicked(child.key) : true}
                            color={child.props.children?.props?.color}
                        >
                            <ArrowReplacement />
                            <HeaderData>{child.props.children}</HeaderData>
                        </Header>
                    </React.Fragment>
                );
            })}
        </>
    );
};

Accordion.defaultProps = {
    headerCanBeClicked: undefined,
    expandItemWithIndex: undefined,
};

interface HeaderProps {
    canBeClicked?: boolean;
    color?: boolean;
}

const Header = styled.div<HeaderProps>`
    display: flex;
    border-bottom: 0.1rem solid ${props => props.theme.colors.card.border};
    padding-left: 0.4rem;
    &:hover {
        ${props => props.canBeClicked && `cursor: pointer;`}
    }
    ${props => props.color && `background-color:${props.color};`}
`;

const Content = styled.div<any>`
    display: ${props => (props.isOpen ? 'block' : 'none')};
`;

const ArrowReplacement = styled.div`
    margin-left: 3rem;
`;

const AccordionContainer = styled.div<any>`
    border-radius: 0.5rem;
`;

const HeaderData = styled.div`
    width: 100%;
`;

const AccordionArrowContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${props => props.theme.layout.padding.small};
    color: ${props => props.theme.colors.table};
    width: 2rem;
`;

export default withTheme(Accordion) as React.ComponentType<Omit<AccordionProps, 'theme'>>;
