import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { AppState } from '../../../../store/appstate';

import { ColumnDef } from '@tanstack/react-table';
import AddLineIcon from 'remixicon-react/AddLineIcon';
import usePermissions from '../../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../../services/hooks/useTags';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import Button from '../../../atoms/Button/Button';
import Card from '../../../atoms/Card/Card';
import CardBody from '../../../atoms/Card/CardBody';
import EmptyState from '../../../atoms/Information/EmptyState';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import Spinner from '../../../atoms/Spinner/Spinner';
import Table from '../../../atoms/Table/Table';
import { Webhook } from '../../../../types/Webhook';
import { WebhookState } from '../../../../store/admin/webhooks/webhooksSlice';
import MultiactionButton from '../../../atoms/Button/MultiactionButton';
import More2LineIcon from 'remixicon-react/More2LineIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import Notification4LineIcon from 'remixicon-react/Notification4LineIcon';
import NotificationOffLineIcon from 'remixicon-react/NotificationOffLineIcon';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import { useAppDispatch } from '../../../../store';
import webhooksActions from '../../../../store/admin/webhooks/actions';
import { TableRowContent } from '../../../../types/Layout/TableRowContent';
import { Modal } from '../../../atoms/Modal/Modal';
import TestWebhookForm from './TestWebhookForm';
import useModal from '../../../../services/hooks/useModal';
import { transparentize } from 'polished';
import { ConnectedStore } from '../../../../types/ConnectedStore';

interface SearchResultsProps {
    searchResults: Webhook[];
    theme: DefaultTheme;
    toggleCreate: () => void;
}

interface WebhookForTable extends Webhook, TableRowContent {}

const WebhooksSearchResults: React.FC<SearchResultsProps> = ({
    searchResults,
    theme,
    toggleCreate,
}: SearchResultsProps) => {
    const dispatch = useAppDispatch();
    const { webhooksTags } = useManagementTags();
    const webhooksPermissions = usePermissions(webhooksTags);

    const availableStores = useSelector<AppState, ConnectedStore[]>(
        s => s.session.selectedOrganization?.connectedStores || []
    );

    const { isSearching, searchError, patchError, deleteError, isTesting } = useSelector<
        AppState,
        WebhookState
    >(s => s.admin.webhooks);
    const { isShown: isTestWebhookShown, toggle: toggleTestWebhook } = useModal();
    const [webhookToTest, setwebhookToTest] = useState<Webhook>();

    const onHideTestWebhookModal = () => {
        if (isTesting) return;

        dispatch(webhooksActions.clearTest());
        toggleTestWebhook();
    };

    const data = searchResults || [];

    const statusBadge = (enabled: boolean, size: string) => {
        return (
            <StatusBadge enabled={enabled}>
                {enabled ? (
                    <Notification4LineIcon size={size} />
                ) : (
                    <NotificationOffLineIcon size={size} />
                )}
            </StatusBadge>
        );
    };

    const columns: ColumnDef<WebhookForTable>[] = [
        {
            header: 'Webhook endpoint',
            accessorKey: 'url',
            enableSorting: false,
        },
        {
            header: 'Events',
            accessorKey: 'subscribedEvents',
            enableSorting: false,
            cell: (props: { row: { original: Webhook } }) => {
                const { subscribedEvents } = props.row.original;

                if (!subscribedEvents || subscribedEvents.length === 0) {
                    return <span>None</span>;
                }

                if (subscribedEvents.length === 1) {
                    return <span>{subscribedEvents[0]}</span>;
                }

                return (
                    <span title={subscribedEvents.join(',\n')}>
                        {subscribedEvents.length} Events
                    </span>
                );
            },
        },
        {
            header: 'Stores',
            accessorKey: 'storeIds',
            enableSorting: false,
            cell: (props: { row: { original: Webhook } }) => {
                const { storeIds } = props.row.original;

                if (!storeIds || storeIds.length === 0) {
                    return <span>None</span>;
                }

                return (
                    <span
                        title={storeIds
                            .map<[number, ConnectedStore?]>(x => [
                                x,
                                availableStores.find(s => s.id === `${x}`),
                            ])
                            .map(([id, store]) =>
                                store
                                    ? `${store.storeName} (${store.customerSalesType} ${store.countryCode})`
                                    : id
                            )
                            .join(',\n')}
                    >
                        {storeIds.length} {storeIds.length === 1 ? 'Store' : 'Stores'}
                    </span>
                );
            },
        },
        {
            header: 'Authentication',
            accessorKey: 'authorizationMethod',
            enableSorting: false,
        },
        {
            header: 'Status',
            accessorKey: 'enabled',
            enableSorting: false,
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            cell: (props: { row: { original: Webhook } }) => {
                const { enabled } = props.row.original;
                return <>{statusBadge(enabled, theme.icon.size.small)}</>;
            },
        },
        {
            header: '',
            accessorKey: 'actions',
            enableSorting: false,
            cell: (props: { row: { original: Webhook } }) => {
                return (
                    <MultiactionButton
                        actions={[
                            {
                                key: 'webhook-toggle-status',
                                dataTestId: 'webhook-toggle-status',
                                label: props.row.original.enabled ? (
                                    <>
                                        <NotificationOffLineIcon size={theme.icon.size.small} />{' '}
                                        Disable
                                    </>
                                ) : (
                                    <>
                                        <Notification4LineIcon size={theme.icon.size.small} />{' '}
                                        Enable
                                    </>
                                ),
                                action: () => {
                                    dispatch(webhooksActions.toggleWebhook(props.row.original));
                                },
                                show: webhooksPermissions.hasWrite,
                            },
                            {
                                key: 'webhook-test',
                                dataTestId: 'webhook-test',
                                label: (
                                    <>
                                        <LinkIcon size={theme.icon.size.small} /> Test configuration
                                    </>
                                ),
                                action: () => {
                                    setwebhookToTest(props.row.original);
                                    toggleTestWebhook();
                                },
                                show: webhooksPermissions.hasWrite,
                            },
                            {
                                key: 'webhook-delete',
                                dataTestId: 'webhook-delete',
                                label: (
                                    <>
                                        <DeleteBinLineIcon size={theme.icon.size.small} /> Delete
                                    </>
                                ),
                                action: () => {
                                    dispatch(webhooksActions.deleteWebhook(props.row.original));
                                },
                                show: webhooksPermissions.hasWrite,
                            },
                        ]}
                        dataTestId={`webhook-actions-${props.row.original.id}`}
                        noneButton
                    >
                        <More2LineIcon size={theme.icon.size.normal} />
                    </MultiactionButton>
                );
            },
        },
    ];

    const loadWebhooks = () => {
        if (searchError) {
            return <ErrorMessage error={searchError} errorHeader={ErrorType.Webhook} />;
        }

        if (data.length === 0) {
            return (
                <EmptyState
                    title="You haven't added any webhooks yet"
                    icon={
                        <AddLineIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
                    }
                >
                    <div>Start by adding your first with the button below.</div>
                    {webhooksPermissions.hasWrite && (
                        <Button large primary onClick={() => toggleCreate()}>
                            Add webhook +
                        </Button>
                    )}
                </EmptyState>
            );
        }

        return (
            <>
                {patchError && <ErrorMessage error={patchError} errorHeader={ErrorType.Webhook} />}
                {deleteError && (
                    <ErrorMessage error={deleteError} errorHeader={ErrorType.Webhook} />
                )}
                <Card>
                    <CardBody>
                        <Table<WebhookForTable>
                            data={data as WebhookForTable[]}
                            columns={columns}
                        />
                    </CardBody>
                </Card>
                <Modal medium isShown={isTestWebhookShown} hide={onHideTestWebhookModal}>
                    <TestWebhookForm
                        hide={onHideTestWebhookModal}
                        onSave={(webhook: Webhook, payload: string) => {
                            dispatch(webhooksActions.testWebhook(webhook, payload));
                        }}
                        webhook={webhookToTest}
                    />
                </Modal>
            </>
        );
    };

    return isSearching ? <Spinner loading={isSearching} /> : <>{loadWebhooks()}</>;
};

const StatusBadge = styled.div.attrs<{ enabled: boolean }>(props => ({
    title: props.enabled ? 'Enabled' : 'Disabled',
}))`
    height: 1.5rem;
    display: inline-flex;
    flex-direction: row;
    background-color: ${props =>
        transparentize(
            0.9,
            props.enabled
                ? props.theme.colors.apiSecret.status.activate
                : props.theme.colors.apiSecret.status.expired
        )};
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 5rem;
    white-space: nowrap;
`;

export default withTheme(WebhooksSearchResults) as React.ComponentType<
    Omit<SearchResultsProps, 'theme'>
>;
