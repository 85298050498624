import React from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import FocusLock from 'react-focus-lock';
import { requiredValueConfig } from '../../../types/ValidationTypes';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import StyledLinkWithCopy from '../../atoms/Link/StyledLinkWithCopy';

import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Money from '../../atoms/Money/Money';
import checkoutActions from '../../../store/checkout/actions';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { AppState } from '../../../store/appstate';
import { CheckoutState } from '../../../store/checkout/checkoutSlice';
import { ErrorType } from '../../../types/response/ErrorCodes';
import ValidationError from '../../atoms/Validation/ValidationError';
import Spinner from '../../atoms/Spinner/Spinner';
import SuccessMessage from '../../atoms/Message/Success/SuccessMessage';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface SendPaylinkModalProps {
    hide: () => void;
    paylinkTotal: number;
    paylink: string;
    orderNumber: string | null;
    privateId: string;
    currency: string;
    theme: DefaultTheme;
    paylinkSlug: string;
}

type Inputs = {
    phoneNumber: string;
};

const SendPaylinkModal: React.FC<SendPaylinkModalProps> = ({
    hide,
    paylinkTotal,
    paylink,
    orderNumber,
    privateId,
    theme,
    currency,
    paylinkSlug,
}: SendPaylinkModalProps) => {
    const dispatch = useAppDispatch();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onSubmit',
    });
    const phoneNumber = register('phoneNumber', requiredValueConfig);
    const { sendPaylinkFailed, isSendingPaylink, paylinkSent } = useSelector<
        AppState,
        CheckoutState
    >(s => s.checkout);
    const onSubmit = (data: Inputs) => {
        dispatch(checkoutActions.sendPaylink(privateId, data.phoneNumber));
    };

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Send Pay Link</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content" noScroll>
                {isSendingPaylink && !paylinkSent && (
                    <Spinner
                        text="Sending Pay Link..."
                        color={theme.colors.primary}
                        size={8}
                        loading
                    />
                )}

                {!isSendingPaylink && paylinkSent && (
                    <ResultContainer>
                        <SuccessMessage>The Pay Link was successfully sent!</SuccessMessage>
                    </ResultContainer>
                )}

                {!isSendingPaylink && !paylinkSent && (
                    <>
                        <TextRow>
                            <div>Order total</div>
                            <div>
                                <TotalMoney>{paylinkTotal}</TotalMoney> {currency}
                            </div>
                        </TextRow>
                        <TextRow>
                            <div>Pay link</div>
                            <div>
                                {paylink !== '' ? (
                                    <StyledLinkWithCopy href={paylink}>
                                        {paylinkSlug ?? 'Paylink'}
                                    </StyledLinkWithCopy>
                                ) : (
                                    <span>No Pay Link to show</span>
                                )}
                            </div>
                        </TextRow>
                        {orderNumber && (
                            <TextRow>
                                <div>Order number</div>
                                <div>
                                    <div>{orderNumber}</div>
                                </div>
                            </TextRow>
                        )}

                        <ContentRow>
                            <FocusLock>
                                <form
                                    onSubmit={handleSubmit(onSubmit)}
                                    data-testid="send-paylink-form"
                                    id="send-paylink-form"
                                >
                                    <FormRow first last>
                                        <GridInput
                                            type="text"
                                            id="phoneNumber"
                                            placeholder="Mobile phone"
                                            name={phoneNumber.name}
                                            setRef={phoneNumber.ref}
                                            onChange={e => phoneNumber.onChange(e)}
                                            validationError={!!errors.phoneNumber}
                                        />
                                    </FormRow>
                                </form>
                            </FocusLock>
                        </ContentRow>
                    </>
                )}

                <ValidationError>
                    <p>
                        {errors.phoneNumber &&
                            'Mobile phone is a required field and needs to be filled in before proceeding.'}
                    </p>
                </ValidationError>
            </ModalContainer>

            {sendPaylinkFailed && (
                <ModalContainer position="error">
                    <ErrorMessage error={sendPaylinkFailed} errorHeader={ErrorType.SendPaylink} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large>
                        {paylinkSent ? 'Close' : 'Cancel'}
                    </Button>

                    {!paylinkSent && !isSendingPaylink && (
                        <Button tabIndex={0} form="send-paylink-form" type="submit" primary large>
                            <span>Send</span>
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(SendPaylinkModal) as React.ComponentType<
    Omit<SendPaylinkModalProps, 'theme'>
>;

const ContentRow = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
`;

const TextRow = styled.div`
    display: grid;
    grid-template-columns: 10rem auto;
    gap: 4rem;
    margin-bottom: 2rem;
`;

const TotalMoney = styled(Money)`
    font-family: ${props => props.theme.text.font.medium};
    font-size: ${props => props.theme.text.size.large};
`;

const ResultContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2.5rem;
`;
