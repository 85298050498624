import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../store/appstate';

const Timer = () => {
    const tokenExpire = useSelector<AppState, number>(s => s.session.expiryTime ?? '');
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = tokenExpire;

    const getTime = () => {
        const time = deadline - Date.now();

        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        const interval = setInterval(() => getTime(), 1000);

        return () => clearInterval(interval);
    }, []);

    const renderTime = () => {
        if (minutes > 0 && seconds === 0) return `${minutes}:00`;
        if (seconds > 0) return `${minutes}:${seconds}`;

        return '00:00';
    };

    return <div>Token expire: {renderTime()}</div>;
};

export default Timer;
