/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { DefaultTheme, withTheme } from 'styled-components';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import { lighten } from 'polished';
import Spinner from '../components/atoms/Spinner/Spinner';
import { AppState } from '../store/appstate';
import { WithMsalProps, withMsal } from '@azure/msal-react';

interface UserNotFoundProps extends WithMsalProps {
    theme: DefaultTheme;
}

const UserNotFound: React.FC<UserNotFoundProps> = ({ theme, msalContext }: UserNotFoundProps) => {
    document.title = 'Merchant Hub - User not found';
    const isFetching = useSelector<AppState, boolean | undefined>(s => s.session.isFetchingUser);

    return (
        <Container>
            {isFetching && (
                <LoadingContainer>
                    <div>Loading User</div>
                    <Spinner color={theme.colors.primary} size={14} loading />
                </LoadingContainer>
            )}
            {!isFetching && (
                <MessageContainer>
                    <UserLineIcon size={theme.icon.size.large} />
                    <h1>User not found</h1>
                    <div>Please contact your administrator for an invitation to Merchant Hub.</div>
                    <RefreshButton onClick={() => msalContext.instance.logout()} type="button">
                        Take me back to login
                    </RefreshButton>
                </MessageContainer>
            )}
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
`;

const MessageContainer = styled.div`
    color: ${props => props.theme.colors.text.primary};
    line-height: 2.5rem;
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const RefreshButton = styled.button`
    font-size: 1.1rem;
    cursor: pointer;
    border-radius: 0.4rem;
    min-width: 9rem;
    border: 0.15rem solid #3d388b;
    background: #3d388b;
    color: #ffffff;
    font-family: 'HKGrotesk-Medium', Helvetica, Arial;
    padding: 0.5rem;
    margin-top: 2rem;

    &:hover {
        background: ${lighten(0.08, '#3d388b')};
    }
`;

export default withTheme(withMsal(UserNotFound)) as React.ComponentType<
    Omit<UserNotFoundProps, 'theme' | 'msalContext'>
>;
