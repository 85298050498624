import React from 'react';
import styled, { css } from 'styled-components';

interface Props {
    first?: boolean;
    last?: boolean;
    children: React.ReactNode;
    width?: string;
    noTop?: boolean;
}

const FormRow: React.FC<Props> = ({ first, last, children, width, noTop }: Props) => {
    return (
        <Row first={first} last={last} width={width} noTop={noTop}>
            {children}
        </Row>
    );
};

const Row = styled.div<Props>`
    overflow: visible;
    display: flex;
    width: ${props => props.width ?? '100%'};
    ${props => !props.noTop && `border-top: 0.1rem solid ${props.theme.colors.input.border};`}

    > * {
        &:first-child {
            border-left: 0.1rem solid ${props => props.theme.colors.input.border};
        }
        &:not(:first-child) {
            border-left: 0.1rem solid ${props => props.theme.colors.input.border};
        }
        &:last-child {
            border-right: 0.1rem solid ${props => props.theme.colors.input.border};
        }
    }

    ${props =>
        props.last &&
        css`
            border-bottom: 0.1rem solid ${props.theme.colors.input.border};
        `};
`;

FormRow.defaultProps = {
    first: false,
    last: false,
    width: undefined,
    noTop: undefined,
};

export default FormRow;
