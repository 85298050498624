import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { AppState } from '../../../../store/appstate';
import { User } from '../../../../types/User';
import Button from '../../../atoms/Button/Button';
import groupsActions from '../../../../store/admin/groups/actions';
import { useManagementTags } from '../../../../services/hooks/useTags';
import usePermissions from '../../../../services/hooks/usePermissions';
import { useAppDispatch } from '../../../../store';
import SearchSelect from '../../../atoms/Input/SearchSelect';

type SelectUserType = {
    value: string;
    label: string;
};

interface Props {
    groupId: string;
    organizationId: string;
}

const AddUserToGroup: React.FC<Props> = ({ groupId, organizationId }: Props) => {
    const dispatch = useAppDispatch();

    const { userManagementGroupTags } = useManagementTags();
    const { hasWrite } = usePermissions(userManagementGroupTags);

    const [selectedValue, setSelectedValue] = useState('');

    const usersInGroup =
        useSelector<AppState, Array<User>>(s => s.admin.groups.groupListUsersResponse.data) || [];
    const allUsersInOrganization =
        useSelector<AppState, Array<User>>(s => s.admin.users.userSearchResults.data) || [];

    const usersNotInGroup = allUsersInOrganization
        .filter(u1 => !usersInGroup.some(u2 => u1.id === u2.id))
        .map((x): SelectUserType => {
            return { value: x.id, label: x.name };
        });

    const disableAddUserBtn = selectedValue.length <= 0;

    const handleSelectChange = (selected: string) => {
        setSelectedValue(selected);
    };

    const handleAddToGroup = () => {
        dispatch(groupsActions.addUserToGroup(organizationId, selectedValue, groupId));
        setSelectedValue('');
    };

    return (
        <SelectContainer>
            {usersNotInGroup && (
                <SelectWrapper>
                    <SearchSelect
                        dataTestId="select-user-to-group"
                        disabled={!hasWrite || usersNotInGroup.length === 0}
                        placeholder="Select User"
                        value={selectedValue}
                        defaultValue={selectedValue}
                        options={usersNotInGroup}
                        onChange={s => handleSelectChange(s)}
                    />
                </SelectWrapper>
            )}
            <Button
                large
                primary
                onClick={handleAddToGroup}
                disabled={disableAddUserBtn || !hasWrite}
            >
                Add user
            </Button>
        </SelectContainer>
    );
};

const SelectContainer = styled.div`
    margin-left: auto;
    display: inline-flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
`;

const SelectWrapper = styled.div`
    width: 20rem;
    justify-content: flex-end;
    margin-left: auto;
    align-self: center;
`;

export default AddUserToGroup;
