import React, { useMemo } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { formatShortDateNumerical } from '../../../services/helpers/dateTimeFormats';
import { isToday } from '../../../services/helpers/dateTimeHelper';

interface DaysOfMonthProps {
    month: number;
    days: number;
    year: number;
    selectDay: (day: string) => void;
    range: string[];
    maxDate?: Date;
    minDate?: Date;
    theme: DefaultTheme;
}

const Days: React.FC<DaysOfMonthProps> = ({
    days,
    month,
    year,
    selectDay,
    range,
    maxDate,
    minDate,
    theme,
}: DaysOfMonthProps) => {
    const firstDayOfMonth = (currentYear: number, currentMonth: number) => {
        const firstDay = new Date(currentYear, currentMonth, 1);

        return firstDay.getDay() === 0 ? 6 : firstDay.getDay() - 1;
    };

    const daysInMonth = useMemo(() => Array.from({ length: days }, (k, v) => v + 1), [days]);
    const dayToBeginTheMonthFrom = firstDayOfMonth(year, month);

    const blanks = Array(dayToBeginTheMonthFrom).fill('empty');
    const totalSlots = [...blanks, ...daysInMonth];

    const getColor = (day: string) => {
        const dayDate = new Date(day);
        const dayFormatted = formatShortDateNumerical(dayDate);

        const [from, to] = range;

        if (dayFormatted === from || dayFormatted === to) {
            return {
                backgroundColor: theme.colors.primary,
                color: 'white',
                borderRadius: '0.5rem',
            };
        }

        if (from === '' || to === '') return {};

        if (dayDate >= new Date(from) && dayDate < new Date(to))
            return {
                backgroundColor: theme.colors.accent,
            };

        return {};
    };

    const padStart = (value: number) => value.toString().padStart(2, '0');

    return (
        <DaysContainer>
            {totalSlots.map((day, i) => {
                const formattedDate = `${year}-${padStart(month + 1)}-${padStart(day)}`;
                const key = `${day}${i}`;

                return day === 'empty' ? (
                    <Empty key={key} />
                ) : (
                    <DayContainer key={`day-${key}`}>
                        <Day
                            data-testid={`day-${day}`}
                            type="button"
                            disabled={
                                (maxDate && maxDate < new Date(formattedDate)) ||
                                (minDate && minDate > new Date(formattedDate))
                            }
                            onClick={() => selectDay(formattedDate)}
                            style={getColor(formattedDate)}
                        >
                            <DayNumber isToday={isToday(new Date(formattedDate))}>{day}</DayNumber>
                        </Day>
                    </DayContainer>
                );
            })}
        </DaysContainer>
    );
};

Days.defaultProps = {
    maxDate: undefined,
    minDate: undefined,
};

const DaysContainer = styled.div`
    color: ${props => props.theme.colors.subtle.regular};
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    place-items: center;
    row-gap: 0.5rem;
`;

const Empty = styled.div`
    height: 2rem;
`;

interface DayNumberProps {
    isToday: boolean;
}

const DayNumber = styled.div<DayNumberProps>`
    padding: ${props => props.theme.layout.padding.xxsmall};
    box-shadow: ${props =>
        props.isToday ? `0px 0.2rem  ${props.theme.colors.primary}` : `0px 0.2rem  transparent`};
    box-sizing: border-box;
`;

const Day = styled.button`
    margin-bottom: ${props => props.theme.layout.margin.xxsmall};
    font-size: ${props => props.theme.text.size.medium};
    background-color: transparent;
    -webkit-appearance: none;
    justify-content: center;
    text-decoration: none;
    -moz-appearance: none;
    text-align: center;
    align-items: center;
    cursor: pointer;
    display: flex;
    outline: none;
    border: none;
    &:hover {
        background: ${props => props.theme.colors.medium};
        cursor: pointer;
        transition: 0.5s;
    }
    &:focus {
        border: 0.2rem solid ${props => props.theme.colors.primary};
        border-radius: 0.5rem;
        outline: none;
    }
    width: 100%;
    height: 100%;
`;

const DayContainer = styled.div`
    height: 3.5rem;
    font-size: 1.4rem;
    display: inline-block;
    background: transparent;
    width: 100%;
`;

export default withTheme(Days) as React.ComponentType<Omit<DaysOfMonthProps, 'theme'>>;
