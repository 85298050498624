import React from 'react';
import styled from 'styled-components';
import SummaryCard from './SummaryCard';
import { AppState } from '../../../store/appstate';
import { SettlementState } from '../../../store/report/settlement/settlementSlice';
import { useSelector } from 'react-redux';

const Summaries: React.FC = () => {
    const {
        searchResult: { metaData },
    } = useSelector<AppState, SettlementState>(s => s.report.settlement);
    return (
        <SummariesWrapper>
            {metaData &&
                metaData.currencyAggregates &&
                metaData.currencyAggregates.map(x => (
                    <SummaryCard
                        key={x.totalAmount}
                        payouts={x.payouts}
                        totalAmount={x.totalAmount}
                        currancy={x.currency}
                        totalAdjustment={x.adjustment}
                        totalCompensation={x.compensation}
                        totalNetSales={x.netSales}
                        totalRefunds={x.refunds}
                        totalGrossSales={x.captured}
                    />
                ))}
        </SummariesWrapper>
    );
};

const SummariesWrapper = styled.div`
    display: flex;
    gap: 1.5rem;
`;

export default Summaries;
