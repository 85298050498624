/* eslint-disable @typescript-eslint/no-explicit-any */
import api from '../../services/api';
import { Organization } from '../../types/Organization';
import { ResponseData } from '../../types/response/ResponseData';
import ResponseError from '../../types/response/ResponseError';
import { SessionUser } from '../../types/SessionUser';
import { AppThunkAction, AppThunkDispatch } from '../thunk';
import {
    userAuthenticated,
    userLoggedOut,
    getUserSucceeded,
    userErrorOccured,
    userRefreshErrorOccurred,
    setSelectedOrganizationStarted,
    setSelectedOrganizationSucceeded,
    setSelectedOrganizationFailed,
    isFetchingUser,
    getOrganizationStarted,
    getOrganizationSucceeded,
    getOrganizationFailed,
    setCesSurvey,
} from './sessionSlice';
import Logger from '../../services/logging/logger';

const saveUserSession =
    (token: string, expiryTime: number, now: number): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        dispatch(userAuthenticated({ token, expiryTime }));
        dispatch(isFetchingUser(true));

        try {
            const response = await api.get<SessionUser>(`auth/users/me`);

            dispatch(getUserSucceeded({ user: response, nextRefresh: now + 600000 }));

            if (response.organization) {
                dispatch(getOrganization(response.organization.id, true));
            }
        } catch (error) {
            dispatch(userErrorOccured(error as ResponseError));
        }
    };

const refreshUser =
    (now: number): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        try {
            const response = await api.get<SessionUser>(`auth/users/me`);
            dispatch(getUserSucceeded({ user: response, nextRefresh: now + 600000 }));

            if (response.organization) {
                dispatch(getOrganization(response.organization.id, false));
            }
        } catch (error) {
            Logger.error('Refresh error occurred', {
                error,
            });

            dispatch(userRefreshErrorOccurred(error as ResponseError));
        }
    };

const getOrganization =
    (organizationId: string, setOrganization: boolean): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        if (!organizationId) {
            return;
        }

        dispatch(getOrganizationStarted());
        if (setOrganization) dispatch(setSelectedOrganizationStarted());

        try {
            const response = await api.get<ResponseData<Organization>>(
                `/organizations/${organizationId}`
            );

            dispatch(getOrganizationSucceeded(response.data));

            if (setOrganization) dispatch(setSelectedOrganizationSucceeded(response.data));
        } catch (error) {
            dispatch(getOrganizationFailed());
            if (setOrganization) dispatch(setSelectedOrganizationFailed());
        }
    };

const setSelectedOrganization =
    (organizationId: string): AppThunkAction =>
    async (dispatch: AppThunkDispatch) => {
        if (!organizationId) {
            return;
        }

        dispatch(setSelectedOrganizationStarted());

        try {
            const response = await api.get<ResponseData<Organization>>(
                `/organizations/${organizationId}`
            );

            dispatch(setSelectedOrganizationSucceeded(response.data));
        } catch (error) {
            dispatch(setSelectedOrganizationFailed());
        }
    };

export default {
    saveUserSession,
    userLoggedOut,
    refreshUser,
    setSelectedOrganization,
    setCesSurvey,
};
