/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import Button from '../../components/atoms/Button/Button';
import Card from '../../components/atoms/Card/Card';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import { Modal } from '../../components/atoms/Modal/Modal';
import AddEditMatcherModal from '../../components/molecules/Marketing/AddEditMatcherModal';
import useModal from '../../services/hooks/useModal';
import { AppState } from '../../store/appstate';
import Matcher from '../../types/marketing/Matcher';
import marketingActions from '../../store/marketing/actions';
import { SessionOrganization } from '../../types/SessionUser';
import Spinner from '../../components/atoms/Spinner/Spinner';
import MatcherModalEnum from '../../types/marketing/MatcherModalEnum';
import CheckBox from '../../components/atoms/Checkbox/Checkbox';
import { MarketingState } from '../../store/marketing/marketingSlice';
import { useAppDispatch } from '../../store';
import Table from '../../components/atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

interface MarketingProps {
    theme: DefaultTheme;
}

const Marketing: React.FC<MarketingProps> = ({ theme }: MarketingProps) => {
    document.title = 'Merchant Hub - Marketing';
    const dispatch = useAppDispatch();

    const [currentRow, setCurrentRow] = useState<Matcher>();
    const [type, setType] = useState<MatcherModalEnum>(MatcherModalEnum.ADD);

    const addEditMatcherModal = useModal();

    const { matchers, isBusy, noMatchers } = useSelector<AppState, MarketingState>(
        s => s.marketing
    );
    const organization = useSelector<AppState, SessionOrganization>(
        s => s.session.selectedOrganization || { id: '', name: '', blockInvites: false }
    );
    const onRowClick = (row: Matcher) => {
        setType(MatcherModalEnum.EDIT);
        setCurrentRow(row);
        addEditMatcherModal.toggle();
    };

    const openAddModal = () => {
        setType(MatcherModalEnum.ADD);
        setCurrentRow(undefined);
        addEditMatcherModal.toggle();
    };

    const onRemoveUser = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        dispatch(marketingActions.deleteMatcher(organization.id, id));
    };

    useEffect(() => {
        if ((!matchers || matchers.length === 0) && !noMatchers) {
            dispatch(marketingActions.getMatchers(organization.id));
        }
    }, [matchers]);

    const columns: ColumnDef<Matcher>[] = [
        {
            id: 'name',
            header: 'Name',
            cell: (props: { row: { original: Matcher } }) => {
                return <span>{props.row.original.name ?? '-'}</span>;
            },
        },
        {
            id: 'isEnabled',
            meta: {
                style: {
                    textAlign: 'center',
                },
            },
            header: 'Enabled',
            cell: (props: { row: { original: Matcher } }) => {
                return <CheckBox disabled checked={props.row.original.isEnabled} />;
            },
        },
        {
            id: 'remove',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: Matcher } }) => {
                const { id } = props.row.original;

                return <Button onClick={e => onRemoveUser(e, id)}>Remove</Button>;
            },
        },
    ];

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <Header>
                        <Title>Matchers</Title>
                        <Button onClick={() => openAddModal()}>Add matcher</Button>
                    </Header>
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <Content>
                    {isBusy ? (
                        <SpinnerWrapper>
                            <Spinner color={theme.colors.primary} size={8} loading />
                        </SpinnerWrapper>
                    ) : (
                        <Card>
                            <Table<Matcher>
                                data={matchers}
                                columns={columns}
                                onRowClicked={onRowClick}
                            />
                        </Card>
                    )}
                </Content>
            </CenterContent>

            {addEditMatcherModal.isShown && (
                <Modal large isShown hide={addEditMatcherModal.toggle}>
                    <AddEditMatcherModal
                        type={type}
                        row={currentRow}
                        hide={addEditMatcherModal.toggle}
                    />
                </Modal>
            )}
        </>
    );
};

export default withTheme(Marketing) as React.ComponentType<Omit<MarketingProps, 'theme'>>;

const Title = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    font-size: 1.6rem;
    display: flex;
    align-items: center;
`;

const Content = styled.div`
    margin-top: 3rem;
    height: 100vh;
`;

const SpinnerWrapper = styled.div`
    margin-top: 8rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
