import React from 'react';
import styled from 'styled-components';

interface Props {
    name?: string;
    type?: string;
    address: string;
    city: string;
    postalCode: string;
    receiver?: string;
    phoneNumber?: string;
    address2?: string;
    coAddress?: string;
}

const Address: React.FC<Props> = ({
    name,
    type,
    address,
    city,
    postalCode,
    receiver,
    phoneNumber,
    address2,
    coAddress,
}: Props) => {
    return (
        <Container>
            {type && <Label>{type}</Label>}
            <StyledAddress>
                {name && <AddressLine>{name}</AddressLine>}
                {phoneNumber && <AddressLine>{phoneNumber}</AddressLine>}
                {receiver && <AddressLine>{receiver}</AddressLine>}
                {coAddress && <AddressLine>{coAddress}</AddressLine>}
                <AddressLine>{address}</AddressLine>
                {address2 && <AddressLine>{address2}</AddressLine>}
                <div>
                    <PostalCode>{postalCode}</PostalCode>, <City>{city}</City>
                </div>
            </StyledAddress>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-flow: column;
    align-items: top;
    width: 100;
    margin-bottom: 2rem;
`;

const Label = styled.div`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
`;

const StyledAddress = styled.div`
    color: ${props => props.theme.colors.text.primary};
    font-size: ${props => props.theme.text.size.medium};
    line-height: 2rem;
`;

const AddressLine = styled.div`
    font-size: ${props => props.theme.text.size.medium};
`;
const City = styled.span`
    margin-right: 0.5rem;
    font-size: ${props => props.theme.text.size.medium};
`;
const PostalCode = styled.span``;

Address.defaultProps = {
    receiver: undefined,
    phoneNumber: undefined,
    name: undefined,
    type: undefined,
    address2: undefined,
    coAddress: undefined,
};

export default Address;
