import styled, { DefaultTheme, withTheme } from 'styled-components';
import React from 'react';
import { transparentize } from 'polished';
import { invoiceStatus, orderStatus, paylinkStatus } from '../../../services/helpers/status';
import StatusTypes from '../../../types/StatusTypes';

type Props = {
    status?: string;
    background?: string;
    padding?: boolean;
    text?: string;
    type: StatusTypes;
    theme: DefaultTheme;
};

type StatusDivProps = {
    color: string;
};

const Status: React.FC<Props> = ({ status, background, padding, type, text, theme }: Props) => {
    const getCorrectStatus = () => {
        if (status && type === StatusTypes.Order) {
            return orderStatus(status, theme.colors.status);
        }
        if (status && type === StatusTypes.Invoice) {
            return invoiceStatus(status, theme.colors.status);
        }

        if (status && type === StatusTypes.Paylink) {
            return paylinkStatus(status, theme.colors.status);
        }
        return { text: status, color: theme.colors.status.default };
    };

    const statusType = getCorrectStatus();

    return (
        <FlexContainer background={background} status={status} padding={padding} type={type}>
            <StatusContainerDiv>
                <StatusDiv color={statusType.color} />
            </StatusContainerDiv>
            <StatusText>{text ?? statusType.text}</StatusText>
        </FlexContainer>
    );
};

Status.defaultProps = {
    status: undefined,
    background: undefined,
    padding: undefined,
    text: undefined,
};

const FlexContainer = styled.div<Props>`
    display: inline-flex;
    flex-direction: row;
    background-color: ${props =>
        props.background ??
        transparentize(0.9, orderStatus(props.status || '', props.theme.colors.status).color)};
    padding: ${props => (props.padding === false ? '0' : '0.5rem')};
    margin-right: 0.5rem;
    border-radius: 5rem;
    white-space: nowrap;
`;

const StatusContainerDiv = styled.div`
    padding-right: ${props => props.theme.layout.padding.small};
    align-self: center;
`;

const StatusDiv = styled.div<StatusDivProps>`
    width: 1rem;
    height: 1rem;
    border-radius: 5rem;
    align-self: center;
    background-color: ${props => props.color};
`;

const StatusText = styled.span`
    margin-right: ${props => props.theme.layout.padding.xsmall};
    align-self: center;
`;

export default withTheme(Status) as React.ComponentType<Omit<Props, 'theme'>>;
