import React, { useEffect, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useForm } from 'react-hook-form';

import { useSelector } from 'react-redux';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import FormRow from '../../atoms/Form/FormRow';
import GridInput from '../../atoms/Input/GridInput';
import { requiredValueConfig } from '../../../types/ValidationTypes';
import { AppState } from '../../../store/appstate';
import StickyNote from '../../atoms/StickyNote/StickyNote';
import Total from '../../atoms/Money/Total';
import SelectableNumberInput from '../../atoms/Input/SelectableNumberInput';
import CheckBox from '../../atoms/Checkbox/Checkbox';
import Matcher from '../../../types/marketing/Matcher';
import { SessionOrganization } from '../../../types/SessionUser';
import marketingActions from '../../../store/marketing/actions';
import { getCustomvalueDecorator } from '../../../services/helpers/dateTimeFormats';
import MatcherModalEnum from '../../../types/marketing/MatcherModalEnum';
import { MarketingState } from '../../../store/marketing/marketingSlice';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface AddEditMatcherModalProps {
    hide: () => void;
    theme: DefaultTheme;
    row?: Matcher;
    type: MatcherModalEnum;
}

type Inputs = {
    name: string;
    recency: string;
    frequency: number;
    monetary: number;
    isEnabled: boolean;
};

const AddEditMatcherModal: React.FC<AddEditMatcherModalProps> = ({
    hide,
    theme,
    row,
    type,
}: AddEditMatcherModalProps) => {
    const {
        register,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm<Inputs>({
        mode: 'onSubmit',
        defaultValues: {
            name: row?.name,
            recency: row?.recency,
            frequency: row?.frequency,
            monetary: row?.monetary,
            isEnabled: row?.isEnabled,
        },
    });
    const dispatch = useAppDispatch();

    const [customRecency, setCustomRecency] = useState<{
        value: number;
        decorator: string;
    }>({
        value: row ? getCustomvalueDecorator(row?.recency).customValue : 1,
        decorator: row ? getCustomvalueDecorator(row?.recency).customDecorator : 'Days',
    });
    const [currentPreview, setCurrentPreview] = useState(0);
    const [isEnabled, setIsEnabled] = useState(row?.isEnabled ?? false);

    const name = register('name', requiredValueConfig);
    const frequency = register('frequency', requiredValueConfig);
    const monetary = register('monetary', requiredValueConfig);

    const organization = useSelector<AppState, SessionOrganization>(
        s => s.session.selectedOrganization || { id: '', name: '', blockInvites: false }
    );

    const { matcherPreviews } = useSelector<AppState, MarketingState>(s => s.marketing);

    const onSubmit = (form: Inputs) => {
        if (type === MatcherModalEnum.EDIT && row && row.id) {
            const values = {
                name: form.name,
                recency: form.recency ?? '1d',
                frequency: form.frequency,
                monetary: form.monetary,
                isEnabled,
            };

            const operations = Object.entries(values).map(x => {
                return {
                    key: x[0],
                    newValue: x[1],
                };
            });

            dispatch(marketingActions.updateMatcher(organization.id, row?.id, operations));
        }

        if (type === MatcherModalEnum.ADD) {
            const newMatch = {
                name: form.name,
                recency: form.recency ?? '1d',
                frequency: form.frequency,
                monetary: form.monetary,
                isEnabled,
            };

            dispatch(marketingActions.createMatcher(organization.id, newMatch));
        }
        hide();
    };
    const handleCustomRangeChange = (value: number, decorator: string) => {
        setCustomRecency({
            value,
            decorator,
        });
        const decoratorConverter = decorator.charAt(0).toLowerCase() ?? 'd';
        setValue('recency', `${value}${decoratorConverter}`);
    };

    const onClickDownload = () => {};

    const onClickTestMatch = () => {
        if (row) dispatch(marketingActions.previewMatcher(organization.id, row?.id));
    };

    useEffect(() => {
        if (
            (row && matcherPreviews[row.id] === undefined) ||
            (row && matcherPreviews[row.id] !== currentPreview)
        ) {
            setCurrentPreview(matcherPreviews[row.id]);
            dispatch(marketingActions.previewMatcher(organization.id, row?.id));
        }
    }, [matcherPreviews, currentPreview]);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>
                    {type === MatcherModalEnum.ADD && 'Add matcher'}
                    {type === MatcherModalEnum.EDIT && 'Edit matcher'}
                </HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                <Grid>
                    <form id="change-email-order-form" onSubmit={handleSubmit(onSubmit)}>
                        <FormRow first>
                            <GridInput
                                background
                                id="name"
                                placeholder="Name"
                                type="text"
                                defaultValue={row?.name}
                                setRef={name.ref}
                                name={name.name}
                                onChange={name.onChange}
                                validationError={!!errors.name}
                                maxLength={255}
                            />
                        </FormRow>

                        <FormRow last>
                            <GridInput
                                background
                                id="frequency"
                                placeholder="Frequency"
                                type="number"
                                defaultValue={row?.frequency}
                                setRef={frequency.ref}
                                name={frequency.name}
                                onChange={frequency.onChange}
                                validationError={!!errors.frequency}
                                maxLength={255}
                            />

                            <GridInput
                                background
                                id="monetary"
                                placeholder="Monetary"
                                type="number"
                                defaultValue={row?.monetary}
                                setRef={monetary.ref}
                                name={monetary.name}
                                onChange={monetary.onChange}
                                validationError={!!errors.monetary}
                                maxLength={255}
                            />
                        </FormRow>

                        <RowCheckboxWrapper>
                            <FormRowWrapper>
                                <SelectableNumberInput
                                    inGrid
                                    name="recency"
                                    id="recency"
                                    focus={false}
                                    placeholder="Recency"
                                    selectValues={['Days', 'Weeks', 'Months', 'Years']}
                                    handleChange={handleCustomRangeChange}
                                    changeValue={{
                                        decorator: customRecency.decorator,
                                        value: customRecency.value,
                                    }}
                                    dataTestId="recency-test-id"
                                    min={1}
                                    max={1000}
                                />
                            </FormRowWrapper>
                            <CheckboxWrapper>
                                <CheckBox
                                    onChange={() => setIsEnabled(!isEnabled)}
                                    checked={isEnabled}
                                >
                                    Is enabled
                                </CheckBox>
                            </CheckboxWrapper>
                        </RowCheckboxWrapper>

                        <ActionButtons>
                            <Button onClick={() => onClickTestMatch()} large>
                                <span>Test matcher</span>
                            </Button>
                            {type === 'Edit' && (
                                <Button large onClick={() => onClickDownload()}>
                                    <span>CSV</span>
                                </Button>
                            )}
                        </ActionButtons>
                    </form>
                    <RightPanelContainer>
                        <MatchStickyNote>
                            <Label>Total matches made</Label>
                            <div>
                                <Total>{(row && matcherPreviews[row?.id]) ?? 'Not tested'}</Total>
                                {row && matcherPreviews[row?.id] ? (
                                    <Customers>Customers</Customers>
                                ) : (
                                    ''
                                )}
                            </div>
                        </MatchStickyNote>
                    </RightPanelContainer>
                </Grid>
            </ModalContainer>

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button onClick={hide} large>
                        Cancel
                    </Button>

                    <Button
                        tabIndex={0}
                        form="change-customer-details-order-form"
                        onClick={() => onSubmit(getValues())}
                        type="submit"
                        primary
                        large
                    >
                        <span>Save</span>
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

AddEditMatcherModal.defaultProps = {
    row: undefined,
};

const CheckboxWrapper = styled.div`
    align-self: center;
`;

const Customers = styled.span`
    margin-left: 0.5rem;
`;

const FormRowWrapper = styled.div`
    width: 25.2rem;
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};
`;

const RowCheckboxWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
`;

const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 25rem;
    margin-top: 3rem;
`;

const MatchStickyNote = styled(StickyNote)`
    height: 8rem;
`;

const RightPanelContainer = styled.div`
    grid-area: right;
    height: 100%;
`;

const Grid = styled.div`
    display: grid;
    grid-template-areas: 'content right';
    grid-template-columns: auto 40rem;
    grid-column-gap: 2rem;
    height: 100vh;
`;

const Label = styled.label`
    line-height: 2.5rem;
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
    margin-top: 1rem;
    display: block;
`;

export default withTheme(AddEditMatcherModal) as React.ComponentType<
    Omit<AddEditMatcherModalProps, 'theme'>
>;
