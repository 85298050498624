import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import { TableRowContent } from '../../../types/Layout/TableRowContent';
import Button from '../../atoms/Button/Button';

import DecoratorInput from '../../atoms/Input/DecoratorInput';
import ChoosableDnDList from '../../atoms/List/ChoosableDnDList';
import ListItem from '../../atoms/List/ListItem';
import userSettingsActions from '../../../store/userSettings/actions';
import ValidationError from '../../atoms/Validation/ValidationError';
import { createSearchQueryStringWithExistingFilters } from '../../../services/helpers/queryStringHelpers';
import TableHeaderTypes from '../../../types/tableHeaderType';
import { useAppDispatch } from '../../../store';
import { ColumnDef } from '@tanstack/react-table';
import useLogTimeSpentOnPage from '../../../services/hooks/useLogTimeSpentOnPage';

type SearchFilterMenuProps<T extends TableRowContent> = {
    columns: ColumnDef<T>[];
    allColumns: ColumnDef<T>[];
    defaultColumns: string[];
    setColumns?: React.Dispatch<React.SetStateAction<ColumnDef<T>[]>>;
    close: () => void;
    tableHeaderType: TableHeaderTypes | undefined;
};

function SearchFilterMenu<T extends TableRowContent>({
    columns,
    setColumns,
    allColumns,
    defaultColumns,
    close,
    tableHeaderType,
}: SearchFilterMenuProps<T>) {
    useLogTimeSpentOnPage(`searchFilterMenu-${tableHeaderType}`);

    const defaultColumnsList = columns.map(x => x.header) as string[];
    const choosableColumns = allColumns
        .map(x => x.header as string)
        .filter(x => !defaultColumns.includes(x) && !defaultColumnsList.includes(x)) as string[];

    const [chosen, setChosen] = useState(defaultColumnsList);
    const [notChosen, setNotChosen] = useState(choosableColumns);

    const [shownChosen, setShownChosen] = useState(chosen);
    const [shownNotChosen, setShownNotChosen] = useState(notChosen);

    const [warning, setWarning] = useState(false);
    const [search, setSearch] = useState('');

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { pathname, search: queryString } = useLocation();

    useEffect(() => {
        const newChosen = columns.map(x => x.header) as string[];

        setChosen(newChosen);

        const newNotChosen = allColumns
            .map(x => x.header as string)
            .filter(x => !newChosen.includes(x)) as string[];
        setNotChosen(newNotChosen);
    }, [columns]);

    const changeToChosen = (name: string) => {
        setWarning(false);

        const newNotChosen = notChosen;

        newNotChosen.splice(notChosen.indexOf(name), 1);

        setChosen(chosen.concat(name));
        setShownChosen(chosen.concat(name));

        setShownNotChosen(newNotChosen);
        setNotChosen(newNotChosen);
    };

    const removeChosen = (name: string) => {
        if (chosen.length === 1) {
            setWarning(true);
            return;
        }

        const newChosen = chosen;

        newChosen.splice(chosen.indexOf(name), 1);

        setNotChosen(notChosen.concat(name));
        setShownNotChosen(notChosen.concat(name));

        setShownChosen(newChosen);
        setChosen(newChosen);
    };

    const filterBySearch = () => {
        const defaultResults = chosen.filter(
            term => term.toLowerCase().includes(search) || term.includes(search)
        );
        const choosableResults = notChosen.filter(
            term => term.toLowerCase().includes(search) || term.includes(search)
        );
        setShownChosen(defaultResults);
        setShownNotChosen(choosableResults);
    };

    useEffect(() => {
        filterBySearch();
    }, [search, chosen, notChosen]);

    const onApply = () => {
        const columnsToApplyByDragAndDropOrder = chosen.map(x =>
            allColumns.find(y => y.header === x)
        ) as ColumnDef<T>[];

        setWarning(false);
        if (setColumns && tableHeaderType) {
            const newQuery = createSearchQueryStringWithExistingFilters(queryString, chosen);

            setColumns(columnsToApplyByDragAndDropOrder);
            dispatch(userSettingsActions.saveColumnFilters(chosen, tableHeaderType));

            navigate(`${pathname}?${newQuery}`);
        }

        if (defaultColumns === chosen && setColumns) {
            const restoreColumns = allColumns.filter(x =>
                defaultColumns.includes(x.header as string)
            );

            setColumns(restoreColumns);
        }
        close();
    };

    const restoreDefaults = () => {
        const restoredNotchosen = allColumns
            .filter(x => !defaultColumns.includes(x.header as string))
            .map(x => x.header) as string[];

        setChosen([...defaultColumns]);
        setNotChosen(restoredNotchosen);

        setShownChosen([...defaultColumns]);
        setShownNotChosen(restoredNotchosen);

        setWarning(false);
    };

    return (
        <Container>
            <Header>
                <Column>
                    <h3>Edit columns</h3>
                    <Button onClick={() => restoreDefaults()} data-testid="restore-defaults">
                        Restore defaults
                    </Button>
                </Column>
                <DecoratorInput
                    type="text"
                    name="searchFilter"
                    onChange={e => setSearch(e.target.value)}
                    value={search}
                    placeholder="Search"
                    id="searchFilterMenuSearch"
                    decorator={<SearchLineIcon size="17" />}
                />
            </Header>

            <ListContainer>
                {warning && <Warning>There needs to be at least one chosen column.</Warning>}
                <ChoosableDnDList
                    list={shownChosen}
                    setList={setChosen}
                    checkboxOnChange={removeChosen}
                    disableDrag={search.length > 0}
                    dataTestId="chosen-items"
                />

                <NotChosenListContainer data-testid="not-chosen-items">
                    {shownNotChosen.map(x => (
                        <ListItem
                            checked={chosen.includes(x)}
                            onChange={() => changeToChosen(x)}
                            key={x}
                            text={x}
                        />
                    ))}
                </NotChosenListContainer>
            </ListContainer>

            <Footer>
                <Button data-testid="filter-column-apply" large primary onClick={() => onApply()}>
                    Apply
                </Button>
            </Footer>
        </Container>
    );
}

SearchFilterMenu.defaultProps = {
    setColumns: undefined,
};

const Container = styled.div`
    display: grid;
    grid-template-rows: 13rem auto 20rem;
`;

const Header = styled.div`
    display: flex;
    flex-direction: column;
    font-family: ${props => props.theme.text.font.medium};
    padding: 1rem 5rem 2rem 5rem;
    position: sticky;
    background-color: white;
    z-index: 3;
    top: 0;
`;

const Footer = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    padding: 2rem 5rem 5rem 5rem;
    position: absolute;
    z-index: 3;
    background-color: white;
    bottom: 0;
    width: 40rem;
`;

const Column = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const ListContainer = styled.div`
    margin: 0 0 3rem 0;
    padding: 5rem;
    overflow-y: scroll;
    height: calc(var(--vh, 0.45vh) * 100);
`;

const NotChosenListContainer = styled.div`
    margin-top: 3rem;
`;

const Warning = styled(ValidationError)`
    margin-bottom: 2rem;
`;

export default SearchFilterMenu;
