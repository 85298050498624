import React, { Fragment, useState } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';

import TimeLineIcon from 'remixicon-react/TimeLineIcon';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import ArrowUpSLineIcon from 'remixicon-react/ArrowUpSLineIcon';

import { formatShortDate, formatTime } from '../../../services/helpers/dateTimeFormats';
import groupBy from '../../../services/helpers/groupBy';
import {
    eventStatusBadge,
    eventStatusText,
    eventTotalText,
} from '../../../services/helpers/status';

import { OrderEvent, OrderEventArticle, OrderEventField } from '../../../types/order/OrderEvent';
import OrderEventType from '../../../types/order/OrderEventType';
import StatusTypes from '../../../types/StatusTypes';

import Card from '../../atoms/Card/Card';
import CardBody from '../../atoms/Card/CardBody';
import CardFooter from '../../atoms/Card/CardFooter';

import Money from '../../atoms/Money/Money';
import SubTotal from '../../atoms/Money/SubTotal';
import VAT from '../../atoms/Money/VAT';
import Status from '../../atoms/Status/Status';

import InvoiceState from '../../../types/invoice/InvoiceState';
import TimeStamp from './TimeStamp';
import Table from '../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

type TimelineProps = {
    events: OrderEvent[];
    currency: string;
    theme: DefaultTheme;
};

type StyledProps = {
    clickable?: boolean;
};

interface StyledListItem {
    iteration: number;
    isOnlyEvent?: boolean;
    isFirstEvent?: boolean;
}

const Timeline2: React.FC<TimelineProps> = ({ events, currency, theme }: TimelineProps) => {
    const sortedEvents = [...events].sort(
        (a, b) => new Date(b.occurredAt).getTime() - new Date(a.occurredAt).getTime()
    );

    const grouped: Map<Date, Array<OrderEvent>> = groupBy(
        sortedEvents,
        (event: { occurredAt: string }) => formatShortDate(new Date(event.occurredAt))
    );
    const [expanded, setExpanded] = useState('');

    const expandRow = (id: string) => {
        if (expanded === '' || expanded !== id) {
            setExpanded(id);
        } else {
            setExpanded('');
        }
    };

    const rowsChange = (eventType: string) => {
        const eventTypeEnum = eventType as OrderEventType;

        if (
            [
                OrderEventType.ReturnMade,
                OrderEventType.RowsActivated,
                OrderEventType.AdjustmentMade,
                OrderEventType.RowsChanged,
                OrderEventType.RowsCanceled,
                OrderEventType.RowsExpired,
                OrderEventType.RowsExpirationExtended,
            ].includes(eventTypeEnum)
        ) {
            return true;
        }
        return false;
    };

    const columns: ColumnDef<OrderEventArticle>[] = [
        {
            header: 'Description',
            accessorKey: 'description',
            cell: (props: { row: { original: OrderEventArticle } }) => {
                const item = props.row.original;
                return <span>{item.isRemoved ? item.originalDescription : item.description}</span>;
            },
        },
        {
            header: 'Article number',
            accessorKey: 'articleNumber',
            cell: (props: { row: { original: OrderEventArticle } }) => {
                const item = props.row.original;
                return (
                    <span>{item.isRemoved ? item.originalArticleNumber : item.articleNumber}</span>
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Quantity',
            accessorKey: 'quantity',
            cell: (props: { row: { original: OrderEventArticle } }) => {
                const item = props.row.original;

                if (item.isRemoved) return <span>{item.originalQuantity}</span>;

                if (
                    !item.diffQuantity ||
                    item.diffQuantity === 0 ||
                    item.diffQuantity === undefined
                )
                    return <span>{item.quantity}</span>;

                return <span>{item.diffQuantity}</span>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'VAT',
            accessorKey: 'vatRate',
            cell: (props: { row: { original: OrderEventArticle } }) => {
                const item = props.row.original;

                if (item.diffVat) {
                    return (
                        <TableUnitPrice>
                            <VAT amount={item.diffVat ?? 0} rate={item.vatRate ?? 0} />
                        </TableUnitPrice>
                    );
                }

                const [vatAmount, vatRate] = item.isRemoved
                    ? [item.originalVatAmount ?? 0, item.originalVatRate ?? 0]
                    : [item.vatAmount ?? 0, item.vatRate ?? 0];

                return (
                    <TableUnitPrice>
                        <VAT amount={vatAmount} rate={vatRate} />
                    </TableUnitPrice>
                );
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            header: 'Unit price',
            accessorKey: 'price',
            cell: (props: { row: { original: OrderEventArticle } }) => {
                const item = props.row.original;
                return <Money>{item.isRemoved ? item.originalPrice ?? 0 : item.price ?? 0}</Money>;
            },
        },
    ];

    const OrderInvoiceAddressChangedColumns: ColumnDef<OrderEventField>[] = [
        {
            meta: {
                style: {
                    textAlign: 'left',
                },
            },
            header: 'Name',
            accessorKey: 'name',
            enableSorting: false,
            cell: (props: { row: { original: OrderEventField } }) => {
                const item = props.row.original;
                if (item.name === 'MobilePhone') {
                    return <span>Mobile phone</span>;
                }
                if (item.name === 'PhoneNumber') {
                    return <span>Phone number</span>;
                }
                return <span> {item.name} </span>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'left',
                },
            },
            header: 'New value',
            accessorKey: 'newValue',
            enableSorting: false,
            cell: (props: { row: { original: OrderEventField } }) => {
                const item = props.row.original;
                if (Array.isArray(item.newValue)) {
                    return item.newValue.map(x => (
                        <React.Fragment key={x}>
                            {x}
                            <br />
                        </React.Fragment>
                    ));
                }
                return <EllipsisValue title={item.newValue}>{item.newValue}</EllipsisValue>;
            },
        },
        {
            meta: {
                style: {
                    textAlign: 'left',
                },
            },
            header: 'Previous value',
            accessorKey: 'oldValue',
            enableSorting: false,
            cell: (props: { row: { original: OrderEventField } }) => {
                const item = props.row.original;
                if (Array.isArray(item.oldValue)) {
                    return item.oldValue.map(x => (
                        <React.Fragment key={x}>
                            {x}
                            <br />
                        </React.Fragment>
                    ));
                }
                return <EllipsisValue title={item.oldValue}>{item.oldValue}</EllipsisValue>;
            },
        },
    ];

    const showEventRows = (event: OrderEvent) => {
        return (
            event.metaData.returnedRows !== undefined ||
            event.metaData.activatedRows !== undefined ||
            event.metaData.changedRows !== undefined ||
            event.metaData.adjustedRows !== undefined ||
            event.metaData.canceledRows !== undefined ||
            event.metaData.expiredRows !== undefined ||
            event.metaData.extendedRows !== undefined
        );
    };

    const getOrderEventArticle = (event: OrderEvent) => {
        return (
            event.metaData.returnedRows ||
            event.metaData.changedRows ||
            event.metaData.activatedRows ||
            event.metaData.adjustedRows ||
            event.metaData.canceledRows ||
            event.metaData.expiredRows ||
            event.metaData.extendedRows ||
            ([] as OrderEventArticle[])
        ).map(x => ({ ...x, isCrossedOver: x.isRemoved, isAdded: x.isNewRow ?? false }));
    };

    const parseEventInvoiceState = (state: string) => {
        switch (state) {
            case InvoiceState.FinalReminder:
                return 'Final Reminder';
            case InvoiceState.CreditNote:
                return 'Credit Note';
            default:
                return state;
        }
    };

    const notificationDownloadTitle = (state: string) => {
        if (state === InvoiceState.CreditNote) return 'Show credit note as pdf';
        return 'Show notification as pdf';
    };

    return (
        <>
            <Header>
                <TimeLineIcon size={theme.icon.size.normal} />
                <OrderHistoryTitle>Order Events</OrderHistoryTitle>
            </Header>

            {Array.from(grouped.values()).map((groupedEvents: Array<OrderEvent>, index: number) => {
                const eventGroup = (iteration: number) => {
                    return groupedEvents.map((event: OrderEvent, eventIndex: number) => {
                        const amountChanged =
                            event.metaData.amount >= 0 || event.metaData.amount <= 0;
                        const badge = eventStatusBadge(event);

                        return (
                            <Fragment key={event.eventId}>
                                {eventIndex === 0 && (
                                    <TimeLineDateGroup iteration={iteration}>
                                        <TimeColumn>
                                            <DateTitle>
                                                {formatShortDate(new Date(event.occurredAt))}
                                            </DateTitle>
                                        </TimeColumn>
                                    </TimeLineDateGroup>
                                )}
                                <TimeLineEvent
                                    iteration={iteration}
                                    isOnlyEvent={groupedEvents.length === 1}
                                    isFirstEvent={eventIndex === groupedEvents.length - 1}
                                >
                                    <TimeColumn>
                                        <TimeStamp>
                                            {formatTime(new Date(event.occurredAt))}
                                        </TimeStamp>
                                    </TimeColumn>
                                    <InformationBox>
                                        <InformationBoxHeader>
                                            <EventType>{eventStatusText(event)}</EventType>
                                            <EventStatus>
                                                {badge && (
                                                    <Status
                                                        type={StatusTypes.Order}
                                                        status={badge?.status}
                                                        background="transparent"
                                                        padding={false}
                                                    />
                                                )}
                                            </EventStatus>
                                            {event.metaData.notificationUrl && (
                                                <ShowInvoiceLink
                                                    href={event.metaData.notificationUrl}
                                                    target="_blank"
                                                >
                                                    {notificationDownloadTitle(
                                                        event.metaData.invoiceState
                                                    )}
                                                </ShowInvoiceLink>
                                            )}
                                            {event.metaData.settlementId && (
                                                <ShowInvoiceLink
                                                    href={`/reports?query=_id:${event.metaData.settlementId}`}
                                                >
                                                    Show Settlement
                                                </ShowInvoiceLink>
                                            )}

                                            {event.metaData.targetAccountId && (
                                                <ShowInvoiceLink
                                                    href={`/accounts/${event.metaData.targetAccountId}`}
                                                >
                                                    Show account
                                                </ShowInvoiceLink>
                                            )}
                                        </InformationBoxHeader>
                                        {event.metaData && (
                                            <SubContainer>
                                                {(event.metaData.by ||
                                                    event.metaData.originatedFrom) && (
                                                    <ByTextInfo>
                                                        {event.metaData.by && (
                                                            <>By {event.metaData.by}</>
                                                        )}
                                                        {event.metaData.originatedFrom && (
                                                            <>
                                                                {event.metaData.by ? (
                                                                    <> via </>
                                                                ) : (
                                                                    <>From </>
                                                                )}
                                                                {event.metaData.originatedFrom}
                                                            </>
                                                        )}
                                                    </ByTextInfo>
                                                )}
                                                {event.metaData.actionReference && (
                                                    <WithDetails>
                                                        With action reference:{' '}
                                                        {event.metaData.actionReference}
                                                    </WithDetails>
                                                )}
                                                {event.metaData.paymentReferenceNumber && (
                                                    <WithDetails>
                                                        <>With payment reference </>
                                                        {event.metaData.paymentReferenceNumber}
                                                    </WithDetails>
                                                )}
                                                {event.metaData.invoiceNumber &&
                                                    event.metaData.invoiceState &&
                                                    event.metaData.invoiceState !==
                                                        InvoiceState.CreditNote && (
                                                        <WithDetails>
                                                            <>Invoice </>
                                                            {event.metaData.invoiceNumber}
                                                            <> with state </>
                                                            {parseEventInvoiceState(
                                                                event.metaData.invoiceState
                                                            )}
                                                        </WithDetails>
                                                    )}
                                                {event.metaData.notificationType && (
                                                    <WithDetails>
                                                        <>Sent by </>
                                                        {event.metaData.notificationType}
                                                        {event.metaData.notificationRecipient && (
                                                            <>
                                                                {' '}
                                                                to{' '}
                                                                {
                                                                    event.metaData
                                                                        .notificationRecipient
                                                                }
                                                            </>
                                                        )}
                                                    </WithDetails>
                                                )}
                                                {event.metaData.notificationDueDate && (
                                                    <WithDetails>
                                                        <>Due date extended from </>
                                                        {formatShortDate(
                                                            new Date(
                                                                event.metaData.notificationOriginalDueDate
                                                            )
                                                        )}
                                                        {event.metaData
                                                            .notificationOriginalDueDate && (
                                                            <>
                                                                {' '}
                                                                to{' '}
                                                                {formatShortDate(
                                                                    new Date(
                                                                        event.metaData.notificationDueDate
                                                                    )
                                                                )}
                                                            </>
                                                        )}
                                                    </WithDetails>
                                                )}
                                                {event.metaData.targetAccountId &&
                                                    event.metaData.purchaseIdentifier && (
                                                        <WithDetails>
                                                            {event.metaData.purchaseIdentifier} has
                                                            been moved from invoice to installment
                                                        </WithDetails>
                                                    )}
                                                {(event.metaData.reference ||
                                                    event.metaData.reference === '') && (
                                                    <WithDetails>
                                                        <>On settlement reference </>
                                                        {event.metaData.reference}
                                                    </WithDetails>
                                                )}
                                            </SubContainer>
                                        )}

                                        {amountChanged && (
                                            <ExpandRows
                                                data-testid="amount-changed-expand-rows"
                                                onClick={e =>
                                                    !rowsChange(event.eventType)
                                                        ? e.preventDefault()
                                                        : expandRow(event.eventId)
                                                }
                                                clickable={showEventRows(event)}
                                            >
                                                <AdjustmentText>
                                                    <Money>{event.metaData.amount}</Money>{' '}
                                                    {currency}
                                                </AdjustmentText>
                                                {rowsChange(event.eventType) === true &&
                                                    (expanded === event.eventId ? (
                                                        <ArrowUpSLineIcon
                                                            size={theme.icon.size.small}
                                                        />
                                                    ) : (
                                                        <ArrowDownSLineIcon
                                                            size={theme.icon.size.small}
                                                        />
                                                    ))}
                                            </ExpandRows>
                                        )}

                                        {event.eventType ===
                                            OrderEventType.OrderInvoiceAddressChanged && (
                                            <ExpandRows
                                                onClick={e => expandRow(event.eventId)}
                                                clickable
                                            >
                                                <AdjustmentText>View changes</AdjustmentText>
                                                {expanded === event.eventId ? (
                                                    <ArrowUpSLineIcon
                                                        size={theme.icon.size.small}
                                                    />
                                                ) : (
                                                    <ArrowDownSLineIcon
                                                        size={theme.icon.size.small}
                                                    />
                                                )}
                                            </ExpandRows>
                                        )}

                                        {expanded === event.eventId && (
                                            <Card>
                                                <ExpandableCard>
                                                    <CardBody>
                                                        <ExpandableRow>
                                                            {showEventRows(event) && (
                                                                <Table<OrderEventArticle>
                                                                    data={getOrderEventArticle(
                                                                        event
                                                                    )}
                                                                    columns={columns}
                                                                    stateFulData
                                                                />
                                                            )}
                                                            {event.eventType ===
                                                                OrderEventType.OrderInvoiceAddressChanged && (
                                                                <Table<OrderEventField>
                                                                    data={
                                                                        event.metaData
                                                                            .invoiceAddressChanges ||
                                                                        ([] as OrderEventField[])
                                                                    }
                                                                    columns={
                                                                        OrderInvoiceAddressChangedColumns
                                                                    }
                                                                    stateFulData
                                                                />
                                                            )}
                                                        </ExpandableRow>
                                                    </CardBody>
                                                    {showEventRows(event) && (
                                                        <CardFooter>
                                                            <SubTotal
                                                                label={eventTotalText(
                                                                    event.eventType
                                                                )}
                                                                amount={event.metaData.amount}
                                                                vat={event.metaData.vatAmount}
                                                            />
                                                        </CardFooter>
                                                    )}
                                                </ExpandableCard>
                                            </Card>
                                        )}
                                    </InformationBox>
                                </TimeLineEvent>
                            </Fragment>
                        );
                    });
                };

                return (
                    <EventGroup key={groupedEvents[0].eventId}>
                        <Container>
                            {eventGroup(index)}
                            {index === grouped.size - 1 && <StarterElement />}
                        </Container>
                    </EventGroup>
                );
            })}
        </>
    );
};

const Container = styled.ul`
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
`;

const TimeLineDateGroup = styled.li<StyledListItem>`
    margin-left: 12rem;
    height: 3rem;
    position: relative;

    border-left: ${props =>
        props.iteration === 0
            ? `0.2rem solid transparent`
            : `0.2rem solid ${props.theme.colors.border}`};
`;

const StarterElement = styled.li`
    margin-left: 12rem;
    position: relative;
    padding-bottom: 0.5rem;
    &::before {
        box-sizing: border-box;
        background: white;
        position: absolute;
        display: block;
        left: -0.5rem;
        border: 0.2rem solid ${props => props.theme.colors.text.primary};
        border-radius: 50%;
        height: 1.1rem;
        width: 1.1rem;
        z-index: 2;
        content: ' ';
    }
`;

const TimeLineEvent = styled.li<StyledListItem>`
    margin-left: 12rem;
    position: relative;
    border-left: 0.2rem solid ${props => props.theme.colors.border};
    padding-bottom: 0.5rem;

    &::before {
        background: ${props => props.theme.colors.text.primary};
        position: absolute;
        display: block;
        left: -0.7rem;
        top: 2.3rem;
        border-radius: 50%;
        height: 1.1rem;
        width: 1.1rem;
        z-index: 2;
        content: ' ';
    }

    &:nth-child(2) {
        ::after {
            position: absolute;
            display: block;
            left: -0.7rem;
            top: 0rem;
            background: ${props => (props.iteration === 0 ? '#fff' : 'transparent')};
            height: 2.3rem;
            width: 1rem;
            z-index: 1;
            content: ' ';
        }
    }
`;

const TimeColumn = styled.div`
    position: absolute;
    left: -12rem;
    width: 10rem;
    text-align: right;
`;

const DateTitle = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    height: 3rem;
`;

const InformationBox = styled.div`
    background-color: #fbfbfb;
    border-radius: 0.2rem;
    padding: 2rem;
    margin-bottom: 0.2rem;
    margin-left: 1rem;
`;

const EventGroup = styled.div``;

const InformationBoxHeader = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr 3fr;
    grid-template-areas: 'content' 'status' 'links';
`;

const EventType = styled.div`
    grid-area: 'content';
    font-family: ${props => props.theme.text.type.bold};
`;

const EventStatus = styled.div`
    font-size: ${props => props.theme.text.size.small};
    grid-area: 'status';
`;

const ShowInvoiceLink = styled.a`
    grid-area: 'links';
    color: ${props => props.theme.colors.primary};
    font-size: 1.2rem;
    cursor: pointer;
    justify-self: end;
`;

const SubContainer = styled.div`
    display: block;
`;

const ByTextInfo = styled.div`
    margin: ${props => props.theme.layout.margin.xsmall} 0;
    color: ${props => props.theme.colors.text.secondary};
    font-size: ${props => props.theme.text.size.xsmall};
`;

const WithDetails = styled.div`
    margin: ${props => props.theme.layout.margin.xsmall} 0;
    color: ${props => props.theme.colors.text.secondary};
    font-size: ${props => props.theme.text.size.xsmall};
`;

const ExpandRows = styled.div<StyledProps>`
    display: flex;
    flex-direction: row;
    ${props => (props.clickable ? 'cursor: pointer' : '')};
    gap: 0.2rem;
    margin: 1rem 0 0.7rem 0;
`;

const ExpandableRow = styled.div`
    display: flex;
    flex-direction: row;
`;

const ExpandableCard = styled.div`
    background-color: #ffff;
`;

const AdjustmentText = styled.div`
    font-size: 1.3rem;
`;

const TableUnitPrice = styled.div`
    text-align: right;
`;

const Header = styled.div`
    margin-right: 2rem;
    padding: 1rem;
    display: flex;
    align-items: center;
`;

const OrderHistoryTitle = styled.h3`
    margin: 0.4rem 0 0.5rem 1rem;
`;

const EllipsisValue = styled.span`
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 23rem;
`;

export default withTheme(Timeline2) as React.ComponentType<Omit<TimelineProps, 'theme'>>;
