/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store/appstate';
import {
    emailValidationConfig,
    inviteUserNameValidationConfig,
} from '../../../../types/ValidationTypes';
import InviteUserGroups from './InviteUserGroups';
import Spinner from '../../../atoms/Spinner/Spinner';
import ResponseError from '../../../../types/response/ResponseError';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import ModalContainer from '../../../atoms/Modal/ModalContainer';
import HeaderTitle from '../../../atoms/Modal/HeaderTitle';
import FormRow from '../../../atoms/Form/FormRow';
import GridInput from '../../../atoms/Input/GridInput';
import FooterButtonContainer from '../../../atoms/Modal/FooterButtonContainer';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import SuccessMessage from '../../../atoms/Message/Success/SuccessMessage';
import Button from '../../../atoms/Button/Button';

interface InviteUserFormProps {
    onInvite: (organization: string, email: string, name: string, groups: string[]) => void;
    hide: () => void;
    organizationId: string;
    organizationName: string;
    theme: DefaultTheme;
}

type Inputs = {
    email: string;
    name: string;
    groups: string[];
};

const InviteUserForm: React.FC<InviteUserFormProps> = ({
    onInvite,
    organizationId,
    organizationName,
    hide,
    theme,
}: InviteUserFormProps) => {
    const inviteSucceded = useSelector<AppState, boolean>(s => s.admin.users.invite.inviteSucceded);
    const isInviting = useSelector<AppState, boolean>(s => s.admin.users.invite.isInviting);
    const inviteError = useSelector<AppState, ResponseError | undefined>(
        s => s.admin.users.invite.error
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<Inputs>();
    const emailHasError = errors.email !== undefined || false;
    const nameHasError = errors.name !== undefined || false;
    const isDisabled = isInviting || inviteSucceded;
    const nameOrEmailError = nameHasError || emailHasError;

    const [selectedGroups, setSelectedGroups] = useState([] as string[]);

    const onSubmit = (data: Inputs) => {
        onInvite(organizationId, data.email, data.name, selectedGroups);
    };

    const name = register('name', inviteUserNameValidationConfig);
    const email = register('email', emailValidationConfig);

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>Invite user to {organizationName}</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                <form onSubmit={handleSubmit(onSubmit)} data-testid="form" id="invite-form">
                    <InputContainer>
                        <FormRow first>
                            <GridInput
                                type="text"
                                id="name"
                                placeholder="Name"
                                setRef={name.ref}
                                name={name.name}
                                onChange={name.onChange}
                                disabled={isDisabled}
                            />
                        </FormRow>

                        <FormRow last>
                            <GridInput
                                type="email"
                                id="email"
                                placeholder="Email address"
                                setRef={email.ref}
                                name={email.name}
                                onChange={email.onChange}
                                disabled={isDisabled}
                                validationError={emailHasError}
                            />
                        </FormRow>
                        <div>
                            {nameOrEmailError && (
                                <ErrorMessage error={inviteError} errorHeader={ErrorType.Invite} />
                            )}
                        </div>

                        <InviteUserGroups
                            organization={organizationId}
                            selectedGroups={selectedGroups}
                            setSelectedGroups={setSelectedGroups}
                            disabled={isDisabled}
                        />
                    </InputContainer>

                    {inviteSucceded && (
                        <>
                            <ResultContainer>
                                <SuccessMessage>Invitation sent!</SuccessMessage>
                            </ResultContainer>
                        </>
                    )}
                </form>
            </ModalContainer>

            {inviteError && (
                <ModalContainer position="error">
                    <ErrorMessage error={inviteError} errorHeader={ErrorType.Invite} />
                </ModalContainer>
            )}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    {!inviteSucceded && (
                        <Button onClick={hide} large>
                            Cancel
                        </Button>
                    )}
                    {!inviteSucceded && (
                        <Button
                            tabIndex={0}
                            form="invite-form"
                            type="submit"
                            large
                            primary
                            disabled={isDisabled}
                        >
                            {isInviting ? (
                                <Spinner color={theme.colors.light} size={8} loading />
                            ) : (
                                <span>Send invite</span>
                            )}
                        </Button>
                    )}
                    {inviteSucceded && (
                        <Button onClick={hide} large primary>
                            Close
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const InputContainer = styled.div`
    max-height: 30rem;
    display: block;
    padding-bottom: ${props => props.theme.layout.padding.small};
`;

const ResultContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2.5rem;
`;

export default withTheme(InviteUserForm) as React.ComponentType<Omit<InviteUserFormProps, 'theme'>>;
