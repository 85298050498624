/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import { AppState } from '../../../../store/appstate';
import Spinner from '../../../../components/atoms/Spinner/Spinner';
import usePermissions from '../../../../services/hooks/usePermissions';
import { useManagementTags } from '../../../../services/hooks/useTags';
import NotAuthorized from '../../../../components/molecules/NotAuthorized';
import GroupHeader from '../../../../components/molecules/Admin/Groups/GroupHeader';
import PermissionsForGroup from '../../../../components/molecules/Admin/Groups/PermissionsForGroup';
import StoresForGroup from '../../../../components/molecules/Admin/Groups/StoresForGroup';
import UsersInGroup from '../../../../components/molecules/Admin/Groups/UsersInGroup';
import usersActions from '../../../../store/admin/users/actions';
import ErrorMessage from '../../../../components/atoms/Message/Error/ErrorMessage';
import { SessionOrganization } from '../../../../types/SessionUser';
import CenterContent from '../../../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../../../components/atoms/Layout/HeaderContent';
import { ErrorType } from '../../../../types/response/ErrorCodes';

import groupsActions from '../../../../store/admin/groups/actions';
import { GroupStore } from '../../../../types/admin/GroupStore';
import {
    clearOrganizationPermissions,
    GroupsState,
} from '../../../../store/admin/groups/groupsSlice';
import { useAppDispatch } from '../../../../store';
import useLogTimeSpentOnPage from '../../../../services/hooks/useLogTimeSpentOnPage';
import Tabs, { TabContent } from '../../../../components/atoms/Tabs/Tabs';
import Flex from '../../../../components/atoms/Box/Flex';

interface LocationStateProps {
    tab: string;
}

enum GroupDetailsTab {
    Users = 'users',
    Permissions = 'permissions',
    Stores = 'stores',
}

const GroupDetails: React.FC = () => {
    useLogTimeSpentOnPage('manageAccess');

    const dispatch = useAppDispatch();
    const location = useLocation();

    const { groupId = '' } = useParams();
    const { userManagementUserTags } = useManagementTags();
    const { hasRead } = usePermissions(userManagementUserTags);

    const state = location.state as LocationStateProps;

    const tabsList: TabContent[] = [
        { key: GroupDetailsTab.Users, text: 'Users' },
        { key: GroupDetailsTab.Permissions, text: 'Permissions' },
        { key: GroupDetailsTab.Stores, text: 'Stores' },
    ];

    const selectedOrganization = useSelector<AppState, SessionOrganization>(
        s => s.session.selectedOrganization
    );

    const {
        group: { connectedStores: groupStores = [] },
        isSearching,
        error,
    } = useSelector<AppState, GroupsState>(s => s.admin.groups);

    const stores =
        selectedOrganization?.connectedStores
            ?.filter(x => x.id !== 'all')
            .map(
                (x): GroupStore => ({
                    storeId: x.id,
                    storeName: x.storeName ?? '',
                    countryCode: x.countryCode ?? '',
                    customerSalesType: x.customerSalesType ?? '',
                    selfReference: x.selfReference ?? '',
                    active: x.active,
                    storeDescription: `${x.id} - ${x.storeName}`,
                    checked: groupStores.includes(x.id),
                    isExpanded: false,
                    isRemoved: false,
                    isReturnableExpanded: false,
                    isAdded: false,
                    isChanged: false,
                    isCrossedOver: false,
                })
            ) ?? [];

    const getCorrectErrorHeader = () => {
        switch (error?.title) {
            case 'Could not delete user from group!':
                return ErrorType.DeleteGroupUser;
            default:
                return ErrorType.LoadGroups;
        }
    };

    useEffect(() => {
        if (!selectedOrganization) return () => ({});

        dispatch(groupsActions.getOrganizationPermissions(selectedOrganization.id));
        dispatch(groupsActions.getGroup(selectedOrganization.id, groupId));
        dispatch(groupsActions.listUsers(selectedOrganization.id, groupId));
        dispatch(usersActions.listUsers(selectedOrganization.id));

        return () => {
            dispatch(clearOrganizationPermissions());
        };
    }, [dispatch, selectedOrganization, groupId]);

    if (error && error.status === 404)
        return (
            <>
                <HeaderContent>
                    <CenterContent></CenterContent>
                </HeaderContent>
                <CenterContent>
                    <ContentWrapper>
                        <ErrorMessage error={error} errorHeader={getCorrectErrorHeader()} />
                    </ContentWrapper>
                </CenterContent>
            </>
        );

    return !hasRead ? (
        <NotAuthorized componentName="Group Details"> </NotAuthorized>
    ) : isSearching && !selectedOrganization ? (
        <Spinner loading={isSearching} />
    ) : (
        <>
            <HeaderContent>
                <CenterContent>
                    <GroupHeader />
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <ContentWrapper>
                    <ErrorMessage error={error} errorHeader={getCorrectErrorHeader()} />
                    <Flex rowGap="2rem" column>
                        <Tabs
                            tabsList={tabsList}
                            startTab={state !== null ? state.tab : GroupDetailsTab.Users}
                        >
                            <UsersInGroup
                                key={GroupDetailsTab.Users}
                                groupId={groupId}
                                organizationId={selectedOrganization?.id ?? ''}
                            />

                            <PermissionsForGroup
                                organization={selectedOrganization}
                                key={GroupDetailsTab.Permissions}
                            />

                            <StoresForGroup
                                organization={selectedOrganization}
                                stores={stores}
                                key={GroupDetailsTab.Stores}
                            />
                        </Tabs>
                    </Flex>
                </ContentWrapper>
            </CenterContent>
        </>
    );
};

const ContentWrapper = styled.div`
    margin-bottom: 2rem;
`;

export default GroupDetails;
