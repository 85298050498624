const enum Keys {
    ARROW_UP = 'ArrowUp',
    ARROW_DOWN = 'ArrowDown',
    SPACE = ' ',
    ENTER = 'Enter',
    ESCAPE = 'Escape',
    TAB = 'Tab',
}

export default Keys;
