import React, { useEffect } from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import Button from '../../atoms/Button/Button';
import GridInput from '../../atoms/Input/GridInput';
import FormRow from '../../atoms/Form/FormRow';
import { useForm, Controller } from 'react-hook-form';
import periodicReportActions from '../../../store/report/periodicReports/actions';
import { useAppDispatch } from '../../../store';
import SelectInput from '../../atoms/Input/SelectInput';
import { PeriodicReportType } from '../../../types/report/PeriodicReports';
import { DatePeriod } from '../../atoms/CalendarDateRange/DateRangePicker';
import { ConnectedStore } from '../../../types/ConnectedStore';
import DatePeriodInput from '../../atoms/DatePeriodInput/DatePeriodInput';
import StoreMultiSelectInput from '../../atoms/StoreMultiSelectInput/StoreMultiSelectInput';
import { AppState } from '../../../store/appstate';
import {
    PeriodicReportsState,
    clearGenerateReportsSuccess,
} from '../../../store/report/periodicReports/periodicReportsSlice';
import { useSelector } from 'react-redux';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import ValidationError from '../../atoms/Validation/ValidationError';
import Spinner from '../../atoms/Spinner/Spinner';
import { hasPermissions } from '../../../services/helpers/hasPermissions';
import PermissionType from '../../../types/PermissionType';
import { PermissionOperation } from '../../../types/PermissionOperation';
import CheckBox from '../../atoms/Checkbox/Checkbox';
import { ReportColumnType } from '../../../types/report/ReportColumnType';
import settlementSummaryReportColumns from '../../../constants/perodic-reports/settlementSummaryReportColumns';
import transactionsReportColumns from '../../../constants/perodic-reports/transactionsReportColumns';
import mergedReportColumns from '../../../constants/perodic-reports/mergedReportColumns';

interface GeneratePeriodicReportProps {
    hide: () => void;
    theme: DefaultTheme;
    availableStores: ConnectedStore[];
}

type Inputs = {
    name: string;
    type: PeriodicReportType;
    period: DatePeriod;
    stores: string[];
    includeSettlementRef: boolean;
    includeActionRef: boolean;
};

const getDefaultColumns = (type: PeriodicReportType) => {
    switch (type) {
        case PeriodicReportType.SETTLEMENT_SUMMARY:
            return settlementSummaryReportColumns;
        case PeriodicReportType.TRANSACTIONS:
            return transactionsReportColumns;
        case PeriodicReportType.MERGED:
            return mergedReportColumns;
        default:
            return {};
    }
};

const getColumns = (
    type: PeriodicReportType,
    includeSettlementRef: boolean,
    includeActionRef: boolean
): Record<string, ReportColumnType> | null => {
    let columns = getDefaultColumns(type);

    if (includeSettlementRef) {
        columns = { ...columns, 'Settlement Reference': ReportColumnType.SettlementReference };
    }

    if (type == PeriodicReportType.SETTLEMENT_SUMMARY) return columns;

    if (includeActionRef) {
        columns = { ...columns, 'Action Reference': ReportColumnType.ActionReference };
    }

    return columns as Record<string, ReportColumnType>;
};

const GeneratePeriodicReport: React.FC<GeneratePeriodicReportProps> = ({
    hide,
    theme,
    availableStores,
}: GeneratePeriodicReportProps) => {
    const dispatch = useAppDispatch();

    const { generatingReportsError, generateSucceeded, isGenerating } = useSelector<
        AppState,
        PeriodicReportsState
    >(s => s.report.periodicReports);

    const {
        register,
        watch,
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<Inputs>();

    const name = register('name', { required: true });
    const watchType = watch('type', PeriodicReportType.SETTLEMENT_SUMMARY);
    const includeSettlementRef = register('includeSettlementRef');
    const includeActionRef = register('includeActionRef');

    const isPeriodLongerThanAYear = (period: DatePeriod) => {
        const maxEnd = new Date(period.start);
        maxEnd.setFullYear(maxEnd.getFullYear() + 1);
        const end = new Date(period.end);

        return end > maxEnd;
    };

    const storesFilteredByPermission = (availableStores ?? []).filter(
        store =>
            hasPermissions(PermissionType.settlementDetails, PermissionOperation.read, store.id) &&
            store.active
    );

    const onSubmit = (formData: Inputs) => {
        dispatch(
            periodicReportActions.generateReport({
                name: formData.name,
                type: formData.type,
                period: formData.period,
                stores: formData.stores,
                columns: getColumns(
                    formData.type,
                    formData.includeSettlementRef,
                    formData.includeActionRef
                ),
            })
        );
    };

    useEffect(() => {
        if (generateSucceeded) {
            hide();
        }
        return () => {
            if (generateSucceeded || generatingReportsError)
                dispatch(clearGenerateReportsSuccess());
        };
    }, [generateSucceeded]);

    return (
        <>
            <ModalContainer position="header">
                <Title>Create periodic report</Title>
            </ModalContainer>
            <ModalContainer position="content" noScroll={true}>
                <StyledForm
                    onSubmit={handleSubmit(onSubmit)}
                    data-testid="generate-report-form"
                    id="generate-report-form"
                >
                    <FormRow first>
                        <GridInput
                            type="text"
                            id="grid-report-name"
                            name={name.name}
                            onChange={e => name.onChange(e)}
                            setRef={name.ref}
                            placeholder="Name of report"
                            dataTestId="grid-report-name"
                            maxLength={50}
                            validationError={!!errors.name}
                        />
                    </FormRow>
                    <FormRow>
                        <Controller
                            name="type"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <SelectInput
                                    id="type"
                                    placeholder="Type"
                                    setRef={field.ref}
                                    onChange={field.onChange}
                                    defaultValue=""
                                    value={field.value}
                                    options={[
                                        {
                                            label: 'Settlement Summary',
                                            value: PeriodicReportType.SETTLEMENT_SUMMARY,
                                        },
                                        {
                                            label: PeriodicReportType.TRANSACTIONS,
                                            value: PeriodicReportType.TRANSACTIONS,
                                        },
                                        {
                                            label: PeriodicReportType.MERGED,
                                            value: PeriodicReportType.MERGED,
                                        },
                                    ]}
                                    dataTestId="select-type-dropdown"
                                    validationError={!!errors.type}
                                />
                            )}
                        />
                    </FormRow>
                    {
                        <FormRow>
                            <GridCheckbox>
                                <CheckBox
                                    name={includeSettlementRef.name}
                                    setRef={includeSettlementRef.ref}
                                    onChange={includeSettlementRef.onChange}
                                    onBlur={includeSettlementRef.onBlur}
                                    dataTestId="include-settlement-reference"
                                >
                                    Include settlement reference
                                </CheckBox>
                            </GridCheckbox>
                            {(watchType == PeriodicReportType.TRANSACTIONS ||
                                watchType == PeriodicReportType.MERGED) && (
                                <GridCheckbox>
                                    <CheckBox
                                        name={includeActionRef.name}
                                        setRef={includeActionRef.ref}
                                        onChange={includeActionRef.onChange}
                                        onBlur={includeActionRef.onBlur}
                                        dataTestId="include-action-reference"
                                    >
                                        Include action reference
                                    </CheckBox>
                                </GridCheckbox>
                            )}
                        </FormRow>
                    }
                    <FormRow>
                        <Controller
                            name="period"
                            control={control}
                            rules={{
                                required: true,
                                validate: {
                                    longerThanAYear: period =>
                                        isPeriodLongerThanAYear(period)
                                            ? 'Period is too long, maximum length is one year.'
                                            : undefined,
                                },
                            }}
                            render={({ field }) => (
                                <DatePeriodInput
                                    setRef={field.ref}
                                    placeholder="Period"
                                    periodValue={field.value}
                                    onChange={field.onChange}
                                    validationError={!!errors.period}
                                    datePeriodMax={d =>
                                        new Date(d.setFullYear(d.getFullYear() + 1))
                                    }
                                    datePeriodMin={d =>
                                        new Date(d.setFullYear(d.getFullYear() - 1))
                                    }
                                />
                            )}
                        />
                    </FormRow>
                    <FormRow last>
                        <Controller
                            name="stores"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                                <StoreMultiSelectInput
                                    setRef={field.ref}
                                    placeholder="Stores (optional)"
                                    availableStores={storesFilteredByPermission}
                                    onStoreChange={field.onChange}
                                    selectedStores={field.value}
                                />
                            )}
                        />
                    </FormRow>
                </StyledForm>

                <ValidationError>
                    <p>{errors.period && errors.period.message}</p>
                </ValidationError>

                {generatingReportsError && (
                    <ErrorMessage
                        error={generatingReportsError}
                        errorHeader={ErrorType.GenerateReport}
                    />
                )}
            </ModalContainer>

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    <Button large onClick={() => hide()} disabled={isGenerating}>
                        Close
                    </Button>

                    <Button
                        large
                        primary
                        form="generate-report-form"
                        type="submit"
                        disabled={isGenerating}
                        data-testid="generate-report-form-submit"
                    >
                        {isGenerating ? (
                            <Spinner color={theme.colors.light} size={8} loading />
                        ) : (
                            <span>Generate report</span>
                        )}
                    </Button>
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

const Title = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    margin-right: 2rem;
    font-size: ${props => props.theme.text.size.xlarge};
    line-height: 2.5rem;
`;

const StyledForm = styled.form`
    position: relative;
`;

const GridCheckbox = styled.div`
    height: 6rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 1rem 0 1rem 1.8rem;
    box-sizing: border-box;
`;

export default withTheme(GeneratePeriodicReport) as React.ComponentType<
    Omit<GeneratePeriodicReportProps, 'theme'>
>;
