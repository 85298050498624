import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import groupsActions from '../../../../store/admin/groups/actions';
import { AppState } from '../../../../store/appstate';
import { User } from '../../../../types/User';
import Button from '../../../atoms/Button/Button';
import Spinner from '../../../atoms/Spinner/Spinner';
import { useManagementTags } from '../../../../services/hooks/useTags';
import usePermissions from '../../../../services/hooks/usePermissions';
import CardBody from '../../../atoms/Card/CardBody';
import Card from '../../../atoms/Card/Card';
import { useAppDispatch } from '../../../../store';
import Table from '../../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

interface Props {
    groupId: string;
    organizationId: string;
}

const UsersInGroup: React.FC<Props> = ({ groupId, organizationId }: Props) => {
    const dispatch = useAppDispatch();

    const { userManagementGroupTags } = useManagementTags();
    const { hasWrite } = usePermissions(userManagementGroupTags);

    const usersInGroup =
        useSelector<AppState, Array<User>>(s => s.admin.groups.groupListUsersResponse.data) || [];

    const fetchedUsersInGroup = useSelector<AppState, boolean>(
        s => s.admin.groups.fetchedUsersInGroup
    );

    const onRemoveFromGroup = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        dispatch(groupsActions.removeUserFromGroup(organizationId, id, groupId));
    };

    const userColumns: ColumnDef<User>[] = [
        {
            header: 'Users',
            accessorKey: 'name',
        },
        {
            header: '',
            id: 'removeFromGroupId',
            accessorKey: 'id',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: User } }) => {
                return (
                    <Button
                        onClick={e => onRemoveFromGroup(e, props.row.original.id)}
                        disabled={!hasWrite}
                    >
                        Remove from group
                    </Button>
                );
            },
        },
    ];

    return (
        <>
            {fetchedUsersInGroup ? (
                <Card>
                    <CardBody>
                        <Table<User> data={usersInGroup} columns={userColumns} />
                    </CardBody>
                </Card>
            ) : (
                <SpinnerContainer>
                    <Spinner text="Loading users in group" loading />
                </SpinnerContainer>
            )}
        </>
    );
};

const SpinnerContainer = styled.div`
    margin-top: 2rem;
`;

export default UsersInGroup;
