import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SortingRule } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { AppState } from '../../../store/appstate';
import searchActions from '../../../store/search/actions';
import { Order } from '../../../types/order/Order';
import Spinner from '../../atoms/Spinner/Spinner';
import CardBody from '../../atoms/Card/CardBody';
import Card from '../../atoms/Card/Card';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import TableHeaderTypes from '../../../types/tableHeaderType';
import BulkOrderActions from './BulkActions';
import { SearchState } from '../../../store/search/searchSlice';
import SpinnerWrapper from '../../atoms/Spinner/SpinnerWrapper';
import {
    createEncodedSearchQueryString,
    createSearchQueryString,
    generateColumnString,
    parseQuery,
    parseSearchUrl,
    sortColumns,
} from '../../../services/helpers/queryStringHelpers';
import { browsePagination, createSearchPath } from '../../../services/helpers/pagination';
import { Account } from '../../../types/account/Account';
import {
    defaultAccountColumnHeaders,
    generateAccountColumnHeaders,
} from '../HeaderColumns/AccountColumnHeaders';
import {
    setOrderAccountSearchTab,
    UserSettingsState,
} from '../../../store/userSettings/userSettingsSlice';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { SearchMetadata } from '../../../types/response/Metadata';
import { useAppDispatch } from '../../../store';
import Table from '../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumberWithoutDecimals } from '../../../services/helpers/numberFormats';
import OrderTabType from '../../../types/order/OrderTabType';
import ButtonTab from '../../atoms/Tabs/ButtonTab';
import {
    getAccountSearchFiltersForQuery,
    getOrderSearchFilters,
} from '../../../services/helpers/search/orderAccountSearchHelper';
import OrderSearchTabType from '../../../types/order/OrderTabType';

interface StyleProps {
    isSearching: boolean;
}

interface SearchResultProps {
    availableStores: ConnectedStore[];
    allColumnHeaders: ColumnDef<Order>[];
    defaultColumns: string[];
    tableHeaderType: TableHeaderTypes;
    setTableColumns: React.Dispatch<React.SetStateAction<ColumnDef<Order>[]>>;
    tableColumns: ColumnDef<Order>[];
    savedColumns: string[];
    resetTableSelects: boolean;
    setResetTableSelects: React.Dispatch<SetStateAction<boolean>>;
    tab: string;
    setTab: React.Dispatch<React.SetStateAction<string>>;
}

const SearchResults: React.FC<SearchResultProps> = ({
    availableStores,
    allColumnHeaders,
    defaultColumns,
    tableHeaderType,
    setTableColumns,
    tableColumns,
    savedColumns,
    resetTableSelects,
    setResetTableSelects,
    tab,
    setTab,
}: SearchResultProps) => {
    const [clearCheckedRows, setClearCheckedRows] = useState(() => () => {});
    const [selectedOrders, setSelectedOrders] = useState([] as Order[]);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { orderColumnFilters: sessionColumns, exactSearch } = useSelector<
        AppState,
        UserSettingsState
    >(s => s.userSettings);

    const { pathname, search: queryString } = useLocation();
    const { query, filterMap, sort, perPage, columns, page } = parseSearchUrl(
        queryString,
        sessionColumns
    );

    const allAccountColumnHeaders = generateAccountColumnHeaders(availableStores);
    const accountHeadersAsList = allAccountColumnHeaders.map(x => x.header) as string[];
    const columnString = generateColumnString(columns);
    const accountColumnString = generateColumnString(accountHeadersAsList);

    const {
        isSearching,
        isCanceled,
        searchFailed,
        error,
        searchResult: { links: orderLinks, metaData: orderMetaData, data: orderData = [] },
        isSearchingAccounts,
        accountSearchCanceled,
        searchAccountsError = undefined,
        searchAccountsResult: {
            links: accountLinks,
            metaData: accountMetaData,
            data: accountData = [],
        },
    } = useSelector<AppState, SearchState>(s => s.search);

    const getNumberOfRecordsTextFromMetaData = (recordsMetaData: SearchMetadata) => {
        return recordsMetaData
            ? `${formatNumberWithoutDecimals(recordsMetaData.totalNumberOfRecords)}`
            : '0';
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortOrderColumns = (sortby: Array<SortingRule<any>>) => {
        const params = sortColumns(sortby, orderLinks.self?.query);

        navigate(createSearchPath(params, pathname));
        dispatch(searchActions.searchOrders(params));
    };

    const getCorrectTabToDirectTo = (tabName: string) => {
        switch (tabName) {
            case OrderSearchTabType.Invoices:
                return 'accounts';
            default:
                return 'orders';
        }
    };

    const onRowClick = (row: Order | Account, newTab = false) => {
        const redirectTab = getCorrectTabToDirectTo(tab);

        if (newTab) {
            const win = window.open(`/${redirectTab}/${row.id}`, '_blank');
            if (win) win.focus();
        } else {
            navigate(`/${redirectTab}/${row.id}`, { state: { from: `${queryString}` } });
        }
    };

    const setSelectedRowsCallBack = useCallback(
        (orders: Order[]) => {
            if (selectedOrders.length !== orders.length) setSelectedOrders(orders);
        },
        [setSelectedOrders, selectedOrders]
    );

    const resetBulkState = () => {
        setSelectedOrders([]);
        clearCheckedRows();
    };

    const handleClickOnTab = (tabToSave: string) => {
        const isOrderTab = tabToSave === OrderSearchTabType.Orders;
        const navigateTo = createEncodedSearchQueryString(
            parseQuery(query, exactSearch ?? false),
            isOrderTab
                ? getOrderSearchFilters(filterMap)
                : getAccountSearchFiltersForQuery(filterMap),
            sort,
            perPage,
            columns,
            page,
            isOrderTab ? OrderSearchTabType.Orders : OrderSearchTabType.Invoices
        );

        dispatch(setOrderAccountSearchTab(tabToSave));
        browsePagination(navigate, pathname, navigateTo);
    };

    useEffect(() => {
        if (resetTableSelects) {
            setSelectedOrders([]);
            clearCheckedRows();
            setResetTableSelects(false);
        }
    }, [resetTableSelects]);

    const renderOrders = () => {
        if (searchFailed)
            return (
                <ErrorMessageWrapper>
                    <ErrorMessage error={error} errorHeader={ErrorType.Search} />
                </ErrorMessageWrapper>
            );

        return (
            <>
                {(isSearching || isCanceled) && (
                    <SpinnerWrapper>
                        <Spinner text="Searching..." loading={isSearching || isCanceled} />
                    </SpinnerWrapper>
                )}

                <TableWrapper isSearching={isSearching || isCanceled}>
                    <Table<Order>
                        data={orderData}
                        allColumns={allColumnHeaders}
                        columns={tableColumns}
                        savedColumns={savedColumns}
                        setColumns={setTableColumns}
                        onSelectedRowsChange={setSelectedRowsCallBack}
                        defaultColumns={defaultColumns}
                        tableHeaderType={tableHeaderType}
                        sort={{
                            initialSortbyState: sort,
                            manualsorting: true,
                            sortFunction: sortOrderColumns,
                        }}
                        pagination={{
                            metaData: orderMetaData,
                            links: orderLinks,
                            pageSize: perPage,
                            nextPage: () =>
                                browsePagination(
                                    navigate,
                                    pathname,
                                    `${orderLinks.next?.query}&tab=${OrderTabType.Orders}${columnString}`
                                ),
                            previousPage: () =>
                                browsePagination(
                                    navigate,
                                    pathname,
                                    `${orderLinks.prev?.query}&tab=${OrderTabType.Orders}${columnString}`
                                ),
                        }}
                        onRowClicked={onRowClick}
                        columnMenu
                        uncheckAllDelegate={setClearCheckedRows}
                    />
                </TableWrapper>
            </>
        );
    };

    const renderAccounts = () => {
        const generatePaginationUrlForAccounts = (paginationLink?: string) => {
            const {
                filterMap: accountFilters,
                sort: accountSort,
                perPage: accountPerPage,
                columns: accountColumns,
                page: accountPage,
                tab: accountTab,
            } = parseSearchUrl(paginationLink ?? '');

            return createSearchQueryString(
                query,
                getAccountSearchFiltersForQuery(accountFilters),
                accountSort,
                accountPerPage,
                accountColumns,
                accountPage,
                accountTab
            );
        };

        if (searchAccountsError)
            return (
                <ErrorMessageWrapper>
                    <ErrorMessage error={searchAccountsError} errorHeader={ErrorType.Search} />
                </ErrorMessageWrapper>
            );

        return (
            <>
                {(isSearchingAccounts || accountSearchCanceled) && (
                    <SpinnerWrapper>
                        <Spinner
                            text="Searching accounts..."
                            loading={isSearchingAccounts || accountSearchCanceled}
                        />
                    </SpinnerWrapper>
                )}

                <TableWrapper isSearching={isSearchingAccounts || accountSearchCanceled}>
                    <Table<Account>
                        data={accountData}
                        columns={allAccountColumnHeaders}
                        defaultColumns={defaultAccountColumnHeaders}
                        tableHeaderType={tableHeaderType}
                        sort={{
                            initialSortbyState: sort,
                            manualsorting: true,
                            sortFunction: sortOrderColumns,
                        }}
                        pagination={{
                            metaData: accountMetaData,
                            links: accountLinks,
                            pageSize: perPage,
                            nextPage: () =>
                                browsePagination(
                                    navigate,
                                    pathname,
                                    generatePaginationUrlForAccounts(
                                        `${accountLinks?.next?.query}&tab=${OrderTabType.Invoices}${accountColumnString}`
                                    )
                                ),
                            previousPage: () =>
                                browsePagination(
                                    navigate,
                                    pathname,
                                    generatePaginationUrlForAccounts(
                                        `${accountLinks.prev?.query}&tab=${OrderTabType.Invoices}${accountColumnString}`
                                    )
                                ),
                        }}
                        onRowClicked={onRowClick}
                    />
                </TableWrapper>
            </>
        );
    };

    return (
        <Container>
            <ButtonTabsActions>
                <ButtonTabs>
                    <ButtonTab
                        large
                        tabKey={OrderTabType.Orders}
                        text={'Orders'}
                        setTab={setTab}
                        currentTab={tab}
                        subLabel={getNumberOfRecordsTextFromMetaData(orderMetaData)}
                        onClick={() => handleClickOnTab(OrderTabType.Orders)}
                    />
                    <ButtonTab
                        large
                        tabKey={OrderTabType.Invoices}
                        text={'Invoices'}
                        setTab={setTab}
                        currentTab={tab}
                        subLabel={getNumberOfRecordsTextFromMetaData(accountMetaData)}
                        onClick={() => handleClickOnTab(OrderTabType.Invoices)}
                    />
                </ButtonTabs>

                {tab === OrderTabType.Orders && (
                    <BulkOrderActions selectedOrders={selectedOrders} executed={resetBulkState} />
                )}
            </ButtonTabsActions>

            <Card>
                <CardBody>
                    {tab === OrderTabType.Orders && renderOrders()}
                    {tab === OrderTabType.Invoices && renderAccounts()}
                </CardBody>
            </Card>
        </Container>
    );
};

export default SearchResults;

const TableWrapper = styled.div<StyleProps>`
    opacity: ${props => (props.isSearching ? 0.5 : 1)};
`;

const Container = styled.div``;

const ErrorMessageWrapper = styled.div`
    margin: 2rem;
    padding-top: 1rem;
`;

const ButtonTabsActions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const ButtonTabs = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;
    width: fit-content;
    border-radius: 0.5rem;
    background: ${props => props.theme.colors.medium};
`;
