import { useSelector } from 'react-redux';
import { AppState } from '../../store/appstate';
import { ConnectedStore } from '../../types/ConnectedStore';

const buildTag = (firstPart: string, secondPart: string, thirdPart: string) => {
    return `${firstPart}-${secondPart}-${thirdPart}`;
};

const getParts = (value: string) => {
    const partList: string[] = ['all', value];
    return partList;
};

const generateTags = (firstSection: string, secondSection: string, thirdSection: string) => {
    const tagsList: string[] = [];
    const firstSectionParts = getParts(firstSection);
    const secondSectionParts = getParts(secondSection);
    const thirdSectionParts = getParts(thirdSection);

    firstSectionParts.forEach(fs => {
        secondSectionParts.forEach(ss => {
            thirdSectionParts.forEach(ts => {
                tagsList.push(buildTag(fs, ss, ts));
            });
        });
    });
    return tagsList;
};

export const useManagementTags = () => {
    const organization = useSelector<AppState, string>(s => s.session.user?.organization?.id || '');
    const availableStores = useSelector<AppState, ConnectedStore[]>(
        s => s.session.selectedOrganization?.connectedStores || []
    );

    const userManagementUserTags = generateTags('usermanagement', organization, 'userlist');
    const userManagementGroupTags = generateTags('usermanagement', organization, 'grouplist');
    const apiSecretTags = generateTags('usermanagement', organization, 'apisecret');
    const webhooksTags = (availableStores?.map(x => `all-${x.id}-webhookendpoints`) || []).concat([
        'all-all-webhookendpoints',
    ]);

    return {
        userManagementUserTags,
        userManagementGroupTags,
        apiSecretTags,
        webhooksTags,
    };
};

export default { useManagementTags };
