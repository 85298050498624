import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import { AppState } from '../../../store/appstate';
import { Order } from '../../../types/order/Order';
import OrderStatus from '../../../types/order/OrderStatus';
import FooterButtonContainer from '../../atoms/Modal/FooterButtonContainer';
import HeaderTitle from '../../atoms/Modal/HeaderTitle';
import ModalContainer from '../../atoms/Modal/ModalContainer';
import Spinner from '../../atoms/Spinner/Spinner';
import orderActions from '../../../store/order/actions';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import ResponseError from '../../../types/response/ResponseError';
import { ErrorType } from '../../../types/response/ErrorCodes';
import LinearProgressBar from '../../atoms/ProgressBar/LinearProgressBar';
import MultistatusMessage from '../../atoms/Message/MultistatusMessage';
import { OrderState } from '../../../store/order/orderSlice';
import SuccessMessage from '../../atoms/Message/Success/SuccessMessage';
import Button from '../../atoms/Button/Button';
import { useAppDispatch } from '../../../store';

interface BulkdOrderModalProps {
    items: Order[];
    action: string;
    hide: (reason: string) => void;
    progress: number;
    handleFakeLoading: () => void;
    theme: DefaultTheme;
}

const BulkOrderModal: React.FC<BulkdOrderModalProps> = ({
    items,
    action,
    hide,
    progress,
    handleFakeLoading,
    theme,
}: BulkdOrderModalProps) => {
    const dispatch = useAppDispatch();
    const { bulkMultiStatus, bulkSucceeded, bulkError, bulkOrderProcessing } = useSelector<
        AppState,
        OrderState
    >(s => s.order);

    const getStatusToActOn = (value: string) => {
        switch (value) {
            case 'Activate':
                return [OrderStatus.NotActivated, OrderStatus.PartActivated];
            case 'Cancel':
                return [OrderStatus.NotActivated, OrderStatus.PartActivated, OrderStatus.Expired];
            default:
                return [];
        }
    };

    const onBulk = (bulkAction: string) => {
        const itemsToSend = statusValidItems.map(x => x.id);
        dispatch(orderActions.multipleBulkOrders(itemsToSend, bulkAction));
    };

    const statusForAction = getStatusToActOn(action).map(x => x.toString());
    const statusValidItems = items.filter(x => statusForAction.includes(x.status ?? ''));
    const statusInvalidItems = items.filter(x => !statusForAction.includes(x.status ?? ''));

    useEffect(() => {
        if (bulkSucceeded) {
            handleFakeLoading();
        }
    });

    const getOrderAmountTitle = (orderArray: Order[]) => {
        if (orderArray.length === 1) return 'order';
        return 'orders';
    };

    const multiStatusErrors =
        (bulkMultiStatus && bulkMultiStatus.filter(x => x.status === 422 || x.status === 500)) ??
        [];

    return (
        <>
            <ModalContainer position="header">
                <HeaderTitle>{action} selected orders</HeaderTitle>
            </ModalContainer>

            <ModalContainer position="content">
                {!bulkSucceeded && !bulkOrderProcessing && (
                    <>
                        <Container>
                            {statusValidItems.length > 0 ? (
                                <OrderProcessText>
                                    <TitleText>{statusValidItems.length}</TitleText>
                                    {getOrderAmountTitle(statusValidItems)} will be processed.
                                </OrderProcessText>
                            ) : (
                                <NonChosenText>No valid orders</NonChosenText>
                            )}
                        </Container>

                        <Container>
                            {statusInvalidItems.length > 0 && (
                                <OrderProcessText>
                                    <TitleText>{statusInvalidItems.length}</TitleText>
                                    {getOrderAmountTitle(statusInvalidItems)} will not be processed
                                    due to invalid order status.
                                </OrderProcessText>
                            )}
                        </Container>
                    </>
                )}

                {(bulkOrderProcessing || bulkSucceeded) && progress < 100 && !bulkError && (
                    <ProcessingContainer>
                        <ProcessingMessage>
                            Please wait while we process your request. Please do not close this
                            dialog.
                        </ProcessingMessage>
                        <LinearProgressBar
                            backgroundColor={theme.colors.primary}
                            completed={progress}
                        />
                    </ProcessingContainer>
                )}

                {progress >= 100 && !bulkError && (
                    <>
                        <ResultContainer>
                            <SuccessMessage>The selected orders has been processed!</SuccessMessage>
                        </ResultContainer>

                        {multiStatusErrors.length > 0 && (
                            <>
                                <MultistatusErrorLabel>
                                    Following orders were not processed correctly:{' '}
                                </MultistatusErrorLabel>
                                <ModalContainer position="error">
                                    <MultistatusMessage messages={multiStatusErrors} />
                                </ModalContainer>
                            </>
                        )}
                    </>
                )}
            </ModalContainer>

            {bulkError &&
                bulkError.map((x: ResponseError) => (
                    <ModalContainer key={x.title} position="error">
                        <ErrorMessage error={x} errorHeader={ErrorType.BulkError} />
                    </ModalContainer>
                ))}

            <ModalContainer position="footer">
                <FooterButtonContainer>
                    {!bulkSucceeded && (
                        <Button
                            data-testid="bulk-modal-cancel-button"
                            disabled={bulkOrderProcessing || bulkSucceeded}
                            onClick={() => hide('cancel')}
                            large
                        >
                            Cancel
                        </Button>
                    )}

                    {!bulkOrderProcessing && !bulkSucceeded && progress === 0 && (
                        <Button
                            data-testid="bulk-modal-confirm-button"
                            tabIndex={0}
                            onClick={() => onBulk(action)}
                            primary
                            large
                            disabled={statusValidItems.length === 0}
                        >
                            Confirm
                        </Button>
                    )}
                    {(bulkOrderProcessing || (bulkSucceeded && progress < 100)) && (
                        <Button
                            data-testid="bulk-modal-loading-button"
                            tabIndex={0}
                            primary
                            large
                            disabled
                        >
                            <Spinner color={theme.colors.light} size={8} loading />
                        </Button>
                    )}
                    {bulkSucceeded && progress === 100 && (
                        <Button
                            data-testid="bulk-modal-close-button"
                            tabIndex={0}
                            onClick={() => hide('executed')}
                            primary
                            large
                        >
                            Close
                        </Button>
                    )}
                </FooterButtonContainer>
            </ModalContainer>
        </>
    );
};

export default withTheme(BulkOrderModal) as React.ComponentType<
    Omit<BulkdOrderModalProps, 'theme'>
>;

const Container = styled.div`
    margin-bottom: ${props => props.theme.layout.margin.xLarge};
`;

const TitleText = styled.div`
    font-family: ${props => props.theme.text.font.bold};
    margin-right: ${props => props.theme.layout.margin.xxsmall};
`;

const OrderProcessText = styled.div`
    display: flex;
`;

const ProcessingMessage = styled.div`
    margin: 2rem 0;
`;
const MultistatusErrorLabel = styled.div`
    text-align: center;
    color: ${props => props.theme.colors.error};
`;

const NonChosenText = styled.div`
    color: ${props => props.theme.colors.text.secondary};
`;

const ResultContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding-bottom: 2.5rem;
`;

const ProcessingContainer = styled.div`
    margin-bottom: 0.5rem;
    text-align: center;
`;
