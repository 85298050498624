import styled, { css } from 'styled-components';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Button from './Button';
import useOnClickOutside from '../Dropdown/useOnClickOutside';

interface Action {
    key: string;
    label: ReactNode;
    action: () => void;
    show: boolean;
    dataTestId?: string;
}

interface MultiactionButtonProps {
    children: ReactNode;
    actions: Action[];
    dataTestId?: string;
    noneButton?: boolean;
}

enum Position {
    Above = 'above',
    Below = 'below',
}

const MultiactionButton: React.FC<MultiactionButtonProps> = ({
    children,
    actions,
    dataTestId,
    noneButton,
}: MultiactionButtonProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const refDropdown = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(true);
    const [visibility, setVisibility] = useState(false);
    const [buttonHeight, setButtonHeight] = useState(0);
    const [position, setPosition] = useState<Position>(Position.Below);
    const kebabIconHeightRem = 3;

    useEffect(() => {
        setButtonHeight(refDropdown.current?.clientHeight ?? 0);
        setOpen(false);
        setVisibility(true);
    }, []);

    const handleClickOutside = () => {
        if (open) setOpen(!open);
    };

    useOnClickOutside(ref, handleClickOutside);

    const convertRemToPixels = (rem: number) => {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    };

    const checkIfEnoughSpaceForDropdownBelow = () => {
        return (
            document.documentElement.clientHeight - ref.current!.getBoundingClientRect().y >
            buttonHeight + convertRemToPixels(kebabIconHeightRem)
        );
    };

    const onClick = () => {
        setOpen(!open);

        if (checkIfEnoughSpaceForDropdownBelow()) {
            setPosition(Position.Below);
        } else {
            setPosition(Position.Above);
        }
    };

    const renderOptions = () => {
        const documentIsScrollable = document.body.scrollHeight > window.innerHeight ?? false;

        return (
            <Dropdown
                ref={refDropdown}
                $visibility={visibility}
                $display={open}
                $noneButton={noneButton ?? false}
                $position={position}
                $iconHeightRem={kebabIconHeightRem}
                $fixed={!documentIsScrollable}
                data-testid="multi-action-dropdown"
            >
                {actions.map(x => {
                    return (
                        x.show && (
                            <ActionSelect
                                key={`action-${x.key}`}
                                onClick={() => x.action()}
                                data-testid={x.dataTestId}
                            >
                                {x.label}
                            </ActionSelect>
                        )
                    );
                })}
            </Dropdown>
        );
    };

    return (
        <div ref={ref}>
            {noneButton ? (
                <NoneButton onClick={onClick} data-testid={dataTestId}>
                    {children}
                    {renderOptions()}
                </NoneButton>
            ) : (
                <MultiButton large onClick={() => setOpen(!open)} primary data-testid={dataTestId}>
                    <Header>{children}</Header>
                    {renderOptions()}
                </MultiButton>
            )}
        </div>
    );
};

export default MultiactionButton;

const NoneButton = styled.div`
    cursor: pointer;
    position: relative;
`;

const MultiButton = styled(Button)`
    position: relative;
    display: inline-block;
`;

const Header = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
`;

const Dropdown = styled.div<{
    $visibility: boolean;
    $display: boolean;
    $noneButton: boolean;
    $position: Position;
    $iconHeightRem: number;
    $fixed: boolean;
}>`
    position: absolute;
    visibility: ${props => (props.$visibility ? 'visible' : 'hidden')};
    display: ${props => (props.$display ? 'block' : 'none')};
    box-shadow: 0 0.8rem 1.6rem 0 rgba(0, 0, 0, 0.2);
    background-color: ${props => props.theme.background};

    ${props =>
        props.$noneButton
            ? css`
                  width: fit-content;
                  transform: translateX(calc(-100% + 2.5rem));
              `
            : css`
                  max-width: fit-content;
                  min-width: 100%;
                  top: 4rem;
                  right: 0;
              `}

    ${props =>
        props.$noneButton &&
        props.$position === Position.Above &&
        css`
            transform: translateX(calc(-100% + 2.5rem))
                translateY(calc(-100% - ${props.$iconHeightRem}rem));
        `}

    ${props =>
        props.$noneButton &&
        props.$fixed &&
        css`
            position: fixed;
        `}

    z-index: 1;
`;

const ActionSelect = styled.div`
    padding: 1.5rem;
    color: ${props => props.theme.colors.text.primary};
    border-bottom: 0.1rem solid ${props => props.theme.colors.border};

    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: left;
    white-space: nowrap;

    gap: 0.5rem;

    &:hover {
        background-color: ${props => props.theme.colors.text.subtle};
    }
`;
