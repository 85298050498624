import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';
import { SortingRule } from 'react-table';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import ArticleLineIcon from 'remixicon-react/ArticleLineIcon';
import ArchiveLineIcon from 'remixicon-react/ArchiveLineIcon';
import { transparentize } from 'polished';
import Address from '../../components/atoms/Contact/Address';
import Email from '../../components/atoms/Contact/Email';
import Phone from '../../components/atoms/Contact/Phone';
import CenterContent from '../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../components/atoms/Layout/HeaderContent';
import TwoRowCell from '../../components/atoms/Table/TwoRowCell';
import { formatDate } from '../../services/helpers/dateTimeFormats';
import { TableRowContent } from '../../types/Layout/TableRowContent';
import Card from '../../components/atoms/Card/Card';
import StatusTypes from '../../types/StatusTypes';
import Status from '../../components/atoms/Status/Status';
import Money from '../../components/atoms/Money/Money';
import Tabs from '../../components/atoms/Tabs/Tabs';
import customerActions from '../../store/customer/actions';
import { clearCustomerData, CustomerState } from '../../store/customer/customerSlice';
import { AppState } from '../../store/appstate';
import Spinner from '../../components/atoms/Spinner/Spinner';
import PhoneNumberType from '../../types/PhoneNumber';
import usePermissions from '../../services/hooks/usePermissions';
import EmptyHeaderErrorMessage from '../../components/molecules/Error/EmptyHeaderErrorMessage';
import NotAuthorized from '../../components/molecules/NotAuthorized';
import { Order } from '../../types/order/Order';
import FlagIcon from '../../components/atoms/FlagIcon/FlagIcon';
import CustomerIcon from '../../components/atoms/CustomerIcon/CustomerIcon';
import { PagingLinks } from '../../types/response/PagingLinks';
import { SearchMetadata } from '../../types/response/Metadata';
import { Notification } from '../../types/account/Notification';
import { ConnectedStore } from '../../types/ConnectedStore';
import { ErrorType } from '../../types/response/ErrorCodes';
import ErrorMessage from '../../components/atoms/Message/Error/ErrorMessage';
import ResponseError from '../../types/response/ResponseError';
import { sortColumns } from '../../services/helpers/queryStringHelpers';
import PhoneEmailContainer from '../../components/atoms/Contact/PhoneEmailContainer';
import EmptyHeader from '../../components/atoms/Header/EmptyHeader';
import getStoreInfo from '../../services/helpers/storeHelper';
import EmptyState from '../../components/atoms/Information/EmptyState';
import CardBody from '../../components/atoms/Card/CardBody';
import CustomerType from '../../types/CustomerType';
import ColumnStoreLabel from '../../components/atoms/Store/ColumnStoreLabel';
import { useAppDispatch } from '../../store';
import RegistrationNumberFormatter from '../../components/atoms/RegistrationNumberFormatter';
import Button from '../../components/atoms/Button/Button';
import {
    hasReadCustomerDetails,
    hasWriteCreditAccountForStore,
    hasPermissionForTagsAndStore,
} from '../../services/helpers/hasPermissions';
import { config } from '../../config';
import Table from '../../components/atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import { formatNumber } from '../../services/helpers/numberFormats';
import NotificationState from '../../components/atoms/NotificationState/NotificationState';
import { browsePaginationWithDispatch } from '../../services/helpers/pagination';
import Flex from '../../components/atoms/Box/Flex';

interface CustomerDetailsProps {
    customerId: string;
    availableStores: ConnectedStore[];
    theme: DefaultTheme;
}

interface CustomerNotification extends TableRowContent, Notification {
    storeId: number;
    invoiceType: string;
    accountId: string;
    currency: string;
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
    customerId,
    availableStores,
    theme,
}: CustomerDetailsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { pathname, search: queryString } = useLocation();

    const {
        error,
        orderError,
        accountError,
        isBusy,
        isGettingOrders,
        isGettingAccounts,
        customer,
        customerLinks,
        orderSearchResult: {
            metaData: orderSearchMetaData = {},
            links: orderSearchLinks = {},
            data: orderSearchData,
        } = {},
        accountSearchResult: {
            metaData: accountSearchMetaData = {},
            links: accountSearchLinks = {},
            data: accountsSearchData,
        } = {},
    } = useSelector<AppState, CustomerState>(s => s.customer);
    const isInternal = useSelector<AppState, boolean>(s => s.session.user?.isInternal ?? false);

    const { hasRead } = usePermissions(customer?.tags);

    const filteredPermissions =
        availableStores
            .map(x => x.id)
            .filter(
                store =>
                    hasWriteCreditAccountForStore(store) &&
                    hasReadCustomerDetails(store) &&
                    hasPermissionForTagsAndStore('customerdetails', customer?.tags ?? [], store)
            ) ?? [];

    const sortByState = '';

    const firstInvoiceForAccounts = !accountsSearchData
        ? undefined
        : (accountsSearchData
              .filter(x => x.notifications !== undefined && x.notifications?.length > 0)
              .map(account => {
                  return {
                      ...account.notifications[0].data,
                      storeId: account.storeId,
                      invoiceType: account.type,
                      accountId: account.id,
                      currency: account.currency,
                  };
              }) as CustomerNotification[]);

    const sortOrders = (sortby: Array<SortingRule<CustomerNotification>>) => {
        const params = sortColumns(sortby, orderSearchLinks.self?.query);

        dispatch(customerActions.getOrders(`orders?${params}`));
    };

    const notificationHeaders: ColumnDef<CustomerNotification>[] = [
        {
            header: 'Invoice no',
            enableSorting: false,
            accessorKey: 'invoiceNumber',
            cell: (props: { row: { original: CustomerNotification } }) => {
                return <span>{props.row.original.invoiceNumber}</span>;
            },
        },
        {
            header: 'Invoice date',
            enableSorting: false,
            accessorKey: 'notificationDate',
            cell: (props: { row: { original: CustomerNotification } }) => {
                const notification = props.row.original;
                const timeArray = formatDate(new Date(notification.notificationDate));
                return (
                    <>
                        {notification.notificationDate ? (
                            <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            },
        },
        {
            header: 'Store',
            enableSorting: false,
            accessorKey: 'storeId',
            cell: (props: { row: { original: CustomerNotification } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Type',
            enableSorting: false,
            accessorKey: 'invoiceType',
            cell: (props: { row: { original: CustomerNotification } }) => {
                return <span>{props.row.original.invoiceType}</span>;
            },
        },
        {
            header: 'Payment status',
            enableSorting: false,
            accessorKey: 'paymentStatus',
            cell: (props: { row: { original: CustomerNotification } }) => {
                return props.row.original.paymentStatus ? (
                    <Status type={StatusTypes.Invoice} status={props.row.original.paymentStatus} />
                ) : (
                    <CenterContent>-</CenterContent>
                );
            },
        },
        {
            header: 'State',
            enableSorting: false,
            accessorKey: 'state',
            cell: (props: { row: { original: CustomerNotification } }) => {
                return (
                    <NotificationState
                        state={props.row.original.state}
                        canceled={props.row.original.canceled}
                    />
                );
            },
        },
        {
            header: 'Total amount',
            enableSorting: false,
            accessorKey: 'totalAmountToPay',
            cell: (props: { row: { original: CustomerNotification } }) => {
                const totalAmountFormats = formatNumber(props.row.original.totalAmountToPay ?? 0);
                return (
                    <TwoRowCell
                        upper={totalAmountFormats}
                        lower={props.row.original.currency}
                        align="right"
                    />
                );
            },
        },
    ];

    const orderHeaders: ColumnDef<Order>[] = [
        {
            header: 'Order no',
            accessorKey: 'orderNumber',
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.orderNumber}</span>;
            },
        },
        {
            header: 'Placed at',
            accessorKey: 'placedAt',
            cell: (props: { row: { original: Order } }) => {
                const order = props.row.original;
                const timeArray = formatDate(new Date(order.placedAt));
                return (
                    <>
                        {order.placedAt ? (
                            <TwoRowCell upper={timeArray.yyyyMMdd} lower={timeArray.HHmm} />
                        ) : (
                            <span>-</span>
                        )}
                    </>
                );
            },
        },
        {
            header: 'Store',
            accessorKey: 'storeId',
            cell: (props: { row: { original: Order } }) => {
                const storeId = props.row.original.storeId?.toString() ?? '';
                const storeInfo = storeId && getStoreInfo(storeId, availableStores);

                return storeInfo && storeInfo.storeName ? (
                    <ColumnStoreLabel store={storeInfo} />
                ) : (
                    <span>{storeId}</span>
                );
            },
        },
        {
            header: 'Payment method',
            accessorKey: 'paymentMethod',
            cell: (props: { row: { original: Order } }) => {
                return <span>{props.row.original.paymentMethod}</span>;
            },
        },
        {
            header: 'Status',
            accessorKey: 'status',
            cell: (props: { row: { original: Order } }) => {
                return <Status type={StatusTypes.Order} status={props.row.original.status} />;
            },
        },
        {
            header: 'Total amount',
            accessorKey: 'totalAmount',
            cell: (props: { row: { original: Order } }) => {
                return <Money>{props.row.original.totalAmount}</Money>;
            },
        },
    ];

    useEffect(() => {
        return () => {
            dispatch(clearCustomerData());
        };
    }, []);

    useEffect(() => {
        dispatch(customerActions.getCustomerDetails(customerId));
    }, [dispatch]);

    useEffect(() => {
        if (customerLinks?.orders?.href)
            dispatch(customerActions.getOrders(customerLinks?.orders?.href ?? ''));
        if (customerLinks?.invoices?.href)
            dispatch(customerActions.getAccounts(customerLinks?.invoices?.href));
    }, [dispatch, customerLinks, customerId]);

    useEffect(() => {
        if (customer && !customer.countryCode) {
            window.location.assign(`/orders?query=customerDetails.customerId:"${customerId}"`);
        }
    }, [customer]);

    const parseCountryCode = (countryCode: string) => {
        switch (countryCode) {
            case 'SE':
                return 'Sweden';
            case 'DK':
                return 'Denmark';
            case 'NO':
                return 'Norway';
            case 'DE':
                return 'Germany';
            case 'FI':
                return 'Finland';
            case 'NL':
                return 'Netherlands';
            case 'AT':
                return 'Austria';
            default:
                return '-';
        }
    };

    const redirect = (page: string, id: string) => {
        navigate(`/${page}/${id}`, { state: { from: `${queryString}` } });
    };

    const onRowClickAccount = (row: CustomerNotification, newTab = false) => {
        redirect('accounts', row.accountId);
    };

    const onRowClickOrder = (row: Order, newTab = false) => {
        redirect('orders', row.id);
    };

    const renderCustomerName = () => {
        if (customer?.firstName) return `${customer?.firstName} ${customer?.lastName}`;
        if (customer?.companyName !== '' && customer?.companyName) return customer?.companyName;

        return customer?.email !== '' ? customer?.email : '[Not available]';
    };

    const creditAccount = (storeId: string) => {
        window.open(`${config.WalleyCoroebus}/customer/view/${customerId}/${storeId}`, '_blank');
    };

    const renderTable = <T extends TableRowContent>(
        type: string,
        headers: ColumnDef<T>[],
        data: T[] | undefined,
        tableError: ResponseError | undefined,
        errorType: ErrorType,
        busy: boolean,
        rowClick: (row: T, newTab?: boolean) => void,
        tableSearchLinks: PagingLinks,
        metaData: SearchMetadata,
        nextPage: () => void,
        prevPage: () => void,
        sortFunction?: (sortby: Array<SortingRule<T>>) => void
    ) => {
        const icon =
            type === 'invoices' ? (
                <ArticleLineIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
            ) : (
                <ArchiveLineIcon size={theme.icon.size.xlarge} color={theme.colors.accent3} />
            );

        return (
            <Card key={type}>
                <CardBody>
                    {tableError && <ErrorMessage error={tableError} errorHeader={errorType} />}

                    {!tableError && (
                        <TableWrapper isSearching={busy}>
                            {(busy || !data) && (
                                <SpinnerWrapper>
                                    <Spinner text="Fetching data..." loading={busy} />
                                </SpinnerWrapper>
                            )}
                            <Table<T>
                                data={data ?? []}
                                columns={headers}
                                sort={{
                                    initialSortbyState: sortByState,
                                    manualsorting: true,
                                    sortFunction: sortFunction || (() => null),
                                }}
                                pagination={{
                                    metaData,
                                    links: tableSearchLinks,
                                    pageSize: '30',
                                    nextPage,
                                    previousPage: prevPage,
                                }}
                                onRowClicked={rowClick}
                            />

                            {!busy && data && data.length === 0 && (
                                <EmptyState title={`The customer has no ${type}`} icon={icon}>
                                    <div />
                                </EmptyState>
                            )}
                        </TableWrapper>
                    )}
                </CardBody>
            </Card>
        );
    };

    if (isBusy || (!isBusy && !customer && !error)) {
        return (
            <>
                <HeaderContent />
                <CenterContent>
                    <Spinner text="Fetching customer..." loading={isBusy} size={15} />
                </CenterContent>
            </>
        );
    }
    if (error) {
        if (error?.status === 404) {
            return <></>;
        }
        return (
            <EmptyHeaderErrorMessage
                error={error}
                keyWord="customer"
                errorType={ErrorType.Customer}
            />
        );
    }

    if (customer && !customer.countryCode) {
        return <></>;
    }

    return hasRead && customer ? (
        <Page>
            <HeaderContent>
                <CenterContent>
                    <Row>
                        <>
                            <Col>
                                <HeaderLabel>Customer</HeaderLabel>
                                <Content>
                                    <FlagIcon countryCode={customer.countryCode} />
                                    <CustomerIcon customerType={customer.typeOfCustomer} />
                                    <BoldText>{renderCustomerName()}</BoldText>
                                </Content>
                            </Col>

                            {customer.typeOfCustomer === CustomerType.PrivateFirm && (
                                <Col>
                                    <HeaderLabel>Company Name</HeaderLabel>
                                    <Content>
                                        <BoldText>
                                            {customer?.companyName !== ''
                                                ? customer.companyName
                                                : `${customer?.firstName} ${customer?.lastName}`}
                                        </BoldText>
                                        {customer.typeOfCustomer !== CustomerType.PrivateFirm && (
                                            <>
                                                <FlagIcon countryCode={customer.countryCode} />
                                                <CustomerIcon
                                                    customerType={customer.typeOfCustomer}
                                                />
                                            </>
                                        )}
                                    </Content>
                                </Col>
                            )}
                            <Col>
                                <HeaderLabel>Civic reg no.</HeaderLabel>
                                <Content>
                                    <BoldText>
                                        <RegistrationNumberFormatter
                                            registrationNumber={customer.registrationNumber}
                                        />
                                    </BoldText>
                                </Content>
                            </Col>
                            <Col>
                                <HeaderLabel>Country</HeaderLabel>
                                <Content>{parseCountryCode(customer.countryCode ?? '')}</Content>
                            </Col>
                        </>

                        {customer.customerRedFlags.isBlockedForPurchase && (
                            <Col>
                                <HeaderLabel>Status</HeaderLabel>
                                <Content>
                                    <DotContainer>
                                        <Dot />
                                    </DotContainer>
                                    Blocked for purchase
                                </Content>
                            </Col>
                        )}
                    </Row>
                </CenterContent>
            </HeaderContent>
            <CenterContent>
                <Grid>
                    <MainContent column rowGap={'1.5rem'}>
                        <Tabs
                            tabsList={[
                                { key: 'orders', text: 'Orders' },
                                { key: 'invoices', text: 'Invoices' },
                            ]}
                            startTab={'orders'}
                        >
                            {renderTable(
                                'invoices',
                                notificationHeaders,
                                firstInvoiceForAccounts,
                                accountError,
                                ErrorType.Account,
                                isGettingAccounts,
                                onRowClickAccount,
                                accountSearchLinks,
                                accountSearchMetaData as SearchMetadata,
                                () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        accountSearchLinks.next?.query,
                                        customerActions.getAccounts(accountSearchLinks.next?.href),
                                        dispatch,
                                        false
                                    ),
                                () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        accountSearchLinks.prev?.query,
                                        customerActions.getAccounts(accountSearchLinks.prev?.href),
                                        dispatch,
                                        false
                                    )
                            )}
                            {renderTable(
                                'orders',
                                orderHeaders,
                                orderSearchData,
                                orderError,
                                ErrorType.Order,
                                isGettingOrders,
                                onRowClickOrder,
                                orderSearchLinks,
                                orderSearchMetaData as SearchMetadata,
                                () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        orderSearchLinks.next?.query,
                                        customerActions.getOrders(orderSearchLinks.next?.href),
                                        dispatch,
                                        false
                                    ),
                                () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        orderSearchLinks.prev?.query,
                                        customerActions.getOrders(orderSearchLinks.prev?.href),
                                        dispatch,
                                        false
                                    ),
                                sortOrders
                            )}
                        </Tabs>
                    </MainContent>
                    <RightPanelContainer>
                        <Container>
                            {filteredPermissions !== null && filteredPermissions.length > 0 && (
                                <InformationSection>
                                    {filteredPermissions.map(x => (
                                        <CreditAccountButton
                                            primary
                                            large
                                            key={x}
                                            onClick={() => creditAccount(x)}
                                        >
                                            Show Account
                                        </CreditAccountButton>
                                    ))}
                                </InformationSection>
                            )}
                            <Person>
                                {customer.preferredEmail && (
                                    <TitleWrapper>
                                        <MiniTitle>Preferred Email</MiniTitle>
                                        <Email>{customer.preferredEmail}</Email>
                                    </TitleWrapper>
                                )}

                                <PhoneEmailContainer>
                                    <Phone type={PhoneNumberType.Mobile}>
                                        {customer.mobilePhoneNumber}
                                    </Phone>

                                    <Phone type={PhoneNumberType.Home}>
                                        {customer.homePhoneNumber}
                                    </Phone>

                                    <Email>{customer.email}</Email>
                                </PhoneEmailContainer>
                                {isInternal && customer && (
                                    <InternalIdContainer>
                                        <HeaderLabel>Internal customer no</HeaderLabel>
                                        <Content>{customer.id}</Content>
                                    </InternalIdContainer>
                                )}
                            </Person>
                            {(customer.physicalAddress || customer.preferredInvoicingAddress) && (
                                <InformationSection>
                                    {customer.physicalAddress &&
                                        customer.physicalAddress.addressLine1 !== '' && (
                                            <Address
                                                type="Customer address"
                                                name={`${customer?.firstName ?? ''} ${
                                                    customer?.lastName ?? ''
                                                }`}
                                                coAddress={customer.physicalAddress.coAddress ?? ''}
                                                address={
                                                    customer.physicalAddress.addressLine1 ?? ''
                                                }
                                                address2={
                                                    customer.physicalAddress.addressLine2 ?? ''
                                                }
                                                city={customer.physicalAddress.city ?? ''}
                                                postalCode={
                                                    customer.physicalAddress.postalCode ?? ''
                                                }
                                            />
                                        )}

                                    {customer.preferredInvoicingAddress &&
                                        customer.preferredInvoicingAddress.addressLine1 !== '' && (
                                            <Address
                                                type="Preferred invoicing address"
                                                name={`${customer?.firstName ?? ''} ${
                                                    customer?.lastName ?? ''
                                                }`}
                                                coAddress={
                                                    customer.preferredInvoicingAddress.coAddress ??
                                                    ''
                                                }
                                                address={
                                                    customer.preferredInvoicingAddress
                                                        .addressLine1 ?? ''
                                                }
                                                address2={
                                                    customer.preferredInvoicingAddress
                                                        .addressLine2 ?? ''
                                                }
                                                city={customer.preferredInvoicingAddress.city ?? ''}
                                                postalCode={
                                                    customer.preferredInvoicingAddress.postalCode ??
                                                    ''
                                                }
                                            />
                                        )}
                                </InformationSection>
                            )}
                        </Container>
                    </RightPanelContainer>
                </Grid>
            </CenterContent>
        </Page>
    ) : (
        <>
            <HeaderContent>
                <EmptyHeader />
            </HeaderContent>
            <CenterContent>
                <NotAuthorized componentName="Customer Details" />
            </CenterContent>
        </>
    );
};

interface StyleProps {
    isSearching: boolean;
}

const Container = styled.div`
    background-color: #fff;
    border: 0.1rem solid ${props => props.theme.colors.border};
    margin-bottom: 1rem;
`;

const TableWrapper = styled.div<StyleProps>`
    position: relative;
    opacity: ${props => (props.isSearching ? 0.5 : 1)};
`;

const Page = styled.div`
    margin-bottom: 2rem;
`;

const CreditAccountButton = styled(Button)`
    margin-bottom: 2rem;
`;

const Content = styled.div`
    color: ${props => props.theme.colors.text.primary};
    margin-right: 2rem;
    display: flex;
    flex-direction: row;
    display: inline-flex;
    flex-direction: row;
    margin-right: 0.5rem;
    white-space: nowrap;
`;

const InternalIdContainer = styled.div`
    margin-top: 1.5rem;
`;

const DotContainer = styled(Content)`
    align-self: center;
`;

const Dot = styled.div`
    background-color: red;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    align-self: center;
    background-color: ${props => props.color};
    margin-right: 0.5rem;
`;

const Person = styled.div`
    padding: 2rem;

    &:not(:only-child) {
        border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    }
`;

const InformationSection = styled.div`
    padding: 2rem 2rem 0 2rem;

    &:not(:only-child) {
        border-bottom: 0.1rem solid ${props => props.theme.colors.border};
    }
`;

const BoldText = styled.span`
    font-family: ${props => props.theme.text.font.medium};
    margin-right: 1rem;
`;

const MiniTitle = styled.div`
    font-size: ${props => props.theme.text.size.small};
    color: ${props => props.theme.colors.text.secondary};
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-bottom: 2rem;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: ${props => props.theme.text.size.medium};
`;

const Col = styled.div`
    padding: 0 ${props => props.theme.layout.padding.medium};
    display: flex;
    flex-direction: column;
    border-right: 0.2rem solid ${props => props.theme.colors.text.subtle};
    &:first-child {
        padding-left: 0;
    }
    &:last-child {
        padding-right: 0;
        border-right: 0;
    }
`;

const HeaderLabel = styled.div`
    font-size: ${props => props.theme.text.size.small};
    margin-bottom: 0.3rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const Grid = styled.div`
    display: grid;
    grid-template-areas: 'content right';
    grid-template-columns: auto 40rem;
    grid-column-gap: 2rem;
    height: 100vh;
`;

const MainContent = styled(Flex)`
    margin-top: 2rem;
    margin-bottom: 4rem;
    grid-area: content;
`;

const RightPanelContainer = styled.div`
    grid-area: right;
    background-color: ${props => props.theme.colors.subtle.light};
    padding: 2rem;
    height: 100%;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    background: ${props => transparentize(0.5, props.theme.colors.light)};
    left: 50%;
    margin-left: -4rem;
    margin-top: 5rem;
`;

export default withTheme(CustomerDetails) as React.ComponentType<
    Omit<CustomerDetailsProps, 'theme'>
>;
