import React from 'react';
import InformationMessage from '../atoms/Message/Information/InformationMessage';

interface NotAuthorizedProps {
    componentName: string;
    message?: string;
    children?: React.ReactNode;
}

const NotAuthorized: React.FC<NotAuthorizedProps> = ({
    componentName,
    message,
}: NotAuthorizedProps) => {
    const noAuthMessage = `You are not authorized to access ${componentName}`;
    const messageArray = [noAuthMessage, message || ''];
    return <InformationMessage messages={messageArray} messageHeader="Not Authorized" />;
};

NotAuthorized.defaultProps = {
    message: 'Please contact your administrator!',
};

export default NotAuthorized;
