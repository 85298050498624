import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import CenterContent from '../../../components/atoms/Layout/CenterContent';
import HeaderContent from '../../../components/atoms/Layout/HeaderContent';
import Spinner from '../../../components/atoms/Spinner/Spinner';
import { AppState } from '../../../store/appstate';
import StoreHeader from '../../../components/molecules/Admin/Stores/StoreHeader';
import { StoresState } from '../../../store/admin/stores/storesSlice';
import storesActions from '../../../store/admin/stores/actions';
import { useAppDispatch } from '../../../store';

const StoreDetails: React.FC = () => {
    const dispatch = useAppDispatch();
    const { store, isLoading } = useSelector<AppState, StoresState>(s => s.admin.stores);
    const { storeId = '' } = useParams();

    useEffect(() => {
        dispatch(storesActions.getStore(storeId));
    }, [storeId]);

    if (isLoading && !store?.data) return <Spinner loading={isLoading} />;

    if (!store || !store.data) return <div />;

    return (
        <>
            <HeaderContent>
                <CenterContent>
                    <StoreHeader store={store?.data} />
                </CenterContent>
            </HeaderContent>

            <CenterContent>
                <ContentWrapper />
            </CenterContent>
        </>
    );
};

const ContentWrapper = styled.div`
    margin: 2rem;
`;

export default StoreDetails;
