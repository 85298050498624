import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import groupActions from '../../../../store/admin/groups/actions';
import { AppState } from '../../../../store/appstate';
import { UserGroup } from '../../../../types/UserGroup';
import { SessionOrganization } from '../../../../types/SessionUser';

import Spinner from '../../../atoms/Spinner/Spinner';
import Button from '../../../atoms/Button/Button';
import CardBody from '../../../atoms/Card/CardBody';
import Card from '../../../atoms/Card/Card';
import { GroupsState } from '../../../../store/admin/groups/groupsSlice';
import { ErrorType } from '../../../../types/response/ErrorCodes';
import ErrorMessage from '../../../atoms/Message/Error/ErrorMessage';
import { useAppDispatch } from '../../../../store';
import Table from '../../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';
import DecoratorInput from '../../../atoms/Input/DecoratorInput';
import FilterLineIcon from 'remixicon-react/Filter3LineIcon';
import styled from 'styled-components';
import useFilter from '../../../../services/hooks/useFilter';

interface SearchResultsProps {
    hasWrite: boolean;
    data: UserGroup[];
}

const GroupSearchResults: React.FC<SearchResultsProps> = ({
    hasWrite,
    data,
}: SearchResultsProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { isSearching, searchError, error } = useSelector<AppState, GroupsState>(
        s => s.admin.groups
    );

    const filterGroups = (x: UserGroup, filterBySearchWord: (property: string) => boolean) =>
        filterBySearchWord(x.name);

    const { search, setSearch, showing, showFilter } = useFilter(data, filterGroups);
    const organization = useSelector<AppState, SessionOrganization>(
        s => s.session.selectedOrganization
    );

    const onRowClick = (row: UserGroup) => {
        navigate(`/admin/groups/${row.id}`);
    };
    const onRemoveGroup = (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        dispatch(groupActions.deleteGroup(organization.id, id));
    };

    const columns: ColumnDef<UserGroup>[] = [
        {
            header: 'Name',
            accessorKey: 'name',
        },
        {
            header: '',
            id: 'removeGroupId',
            accessorKey: 'id',
            meta: {
                style: {
                    textAlign: 'right',
                },
            },
            cell: (props: { row: { original: UserGroup } }) => {
                return (
                    <Button
                        onClick={e => onRemoveGroup(e, props.row.original.id)}
                        distinct
                        disabled={!hasWrite || props.row.original.name == 'Administrators'}
                    >
                        Remove
                    </Button>
                );
            },
        },
    ];

    const renderSearchResults = () => (
        <>
            {(searchError || error) && (
                <ErrorMessage error={searchError ?? error} errorHeader={ErrorType.LoadGroups} />
            )}

            {showFilter && (
                <SearchWrapper>
                    <DecoratorInput
                        type="text"
                        name="filterGroups"
                        onChange={e => setSearch(e.target.value)}
                        value={search}
                        placeholder="Filter groups"
                        id="filter-groups"
                        decorator={<FilterLineIcon size="17" />}
                    />
                </SearchWrapper>
            )}

            <Card>
                <CardBody>
                    <Table<UserGroup> data={showing} columns={columns} onRowClicked={onRowClick} />
                </CardBody>
            </Card>
        </>
    );

    return isSearching ? <Spinner loading={isSearching} /> : renderSearchResults();
};

export default GroupSearchResults;

const SearchWrapper = styled.div`
    margin-bottom: 1rem;
    background: white;
`;
