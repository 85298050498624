import styled, { DefaultTheme, withTheme } from 'styled-components';
import React, { useId } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StatusTypes from '../../../types/StatusTypes';
import Status from '../../atoms/Status/Status';
import Column from '../../atoms/Header/Column';
import Row from '../../atoms/Header/Row';
import Label from '../../atoms/Label/Label';
import { formatNumber } from '../../../services/helpers/numberFormats';
import CheckoutSession from '../../../types/paylink/CheckoutSession';
import PaylinkStatus from '../../../types/paylink/PaylinkStatus';
import StyledLink from '../../atoms/Link/StyledLink';
import Money from '../../atoms/Money/Money';
import usePermissions from '../../../services/hooks/usePermissions';
import { ConnectedStore } from '../../../types/ConnectedStore';
import { AppState } from '../../../store/appstate';
import getStoreInfo from '../../../services/helpers/storeHelper';
import CompactStoreLabel from '../../atoms/Store/CompactStoreLabel';
import MultiactionButton from '../../atoms/Button/MultiactionButton';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import DeleteBinLineIcon from 'remixicon-react/DeleteBinLineIcon';
import FileCopyLineIcon from 'remixicon-react/FileCopyLineIcon';
import ExternalLinkLineIcon from 'remixicon-react/ExternalLinkLineIcon';
import SendPlaneLineIcon from 'remixicon-react/SendPlaneLineIcon';
import ArticleLineIcon from 'remixicon-react/ArticleLineIcon';
import QrCodeLine from 'remixicon-react/QrCodeLineIcon';

interface PaylinkDetailsHeaderProps {
    paylink: CheckoutSession;
    paylinkQrCode?: string;
    onClick: () => void;
    onClickShowQr: () => void;
    onClickDelete: () => void;
    onClickCopy: () => void;
    onClickOpen: () => void;
    theme: DefaultTheme;
}

const PaylinkDetailsHeader: React.FC<PaylinkDetailsHeaderProps> = ({
    paylink,
    paylinkQrCode,
    onClick,
    onClickShowQr,
    onClickDelete,
    onClickCopy,
    onClickOpen,
    theme,
}: PaylinkDetailsHeaderProps) => {
    const navigate = useNavigate();
    const { hasWrite, hasRead } = usePermissions(paylink?.tags);
    const availableStores =
        useSelector<AppState, ConnectedStore[]>(s => s.session.availableStores) ?? [];

    const renderOrderReference = () => {
        if (paylink.status === PaylinkStatus.Completed) {
            return (
                <StyledLink to={`/orders/${paylink.orderId}`} target="_blank">
                    {paylink.reference ?? '[Not Set]'}
                </StyledLink>
            );
        }

        if (!paylink.reference) return <span>[Not Set]</span>;

        return <span>{paylink.reference}</span>;
    };

    const storeId = paylink.storeId.toString();
    const storeInfo = paylink.storeId && getStoreInfo(storeId, availableStores);

    const actionList = [
        {
            key: useId(),
            label: (
                <>
                    <SendPlaneLineIcon size={theme.icon.size.small} />
                    Send
                </>
            ),
            action: () => onClick(),
            show:
                hasWrite &&
                paylink.status != PaylinkStatus.Completed &&
                paylink.status != PaylinkStatus.Aborted &&
                paylink.status != PaylinkStatus.Expired,
        },
        {
            key: useId(),
            label: (
                <>
                    <QrCodeLine size={theme.icon.size.small} />
                    Show QR
                </>
            ),
            action: () => onClickShowQr(),
            show: !!paylinkQrCode && hasRead,
        },
        {
            key: useId(),
            label: (
                <>
                    <ExternalLinkLineIcon size={theme.icon.size.small} />
                    Open
                </>
            ),
            action: () => onClickOpen(),
            show: hasRead,
        },
        {
            key: useId(),
            label: (
                <>
                    <FileCopyLineIcon size={theme.icon.size.small} />
                    Copy link
                </>
            ),
            action: () => onClickCopy(),
            show: hasRead,
        },
        {
            key: useId(),
            label: (
                <>
                    <DeleteBinLineIcon size={theme.icon.size.small} />
                    Abort
                </>
            ),
            action: () => onClickDelete(),
            show:
                hasWrite &&
                (paylink.status == PaylinkStatus.Pending ||
                    paylink.status == PaylinkStatus.CustomerIdentified),
        },
        {
            key: useId(),
            label: (
                <>
                    <ArticleLineIcon size={theme.icon.size.small} />
                    Go to order
                </>
            ),
            action: () => navigate(`/orders/${paylink.orderId}`),
            show: hasRead && paylink.status == PaylinkStatus.Completed,
        },
    ];

    return (
        <HeaderContentWrapper>
            <LeftHeaderContent>
                <Row>
                    <Column>
                        <Label>Order number</Label>

                        {renderOrderReference()}
                    </Column>
                    <Column>
                        <Label>Status</Label>
                        <StatusWrapper>
                            {paylink.status ? (
                                <Status status={paylink.status} type={StatusTypes.Paylink} />
                            ) : (
                                '-'
                            )}
                        </StatusWrapper>
                    </Column>

                    <Column>
                        <Label>Sales person</Label>
                        <div>
                            <SalesPerson title={paylink.salesPerson} data-testid="salesPersonText">
                                {paylink.salesPerson ?? '-'}
                            </SalesPerson>
                        </div>
                    </Column>

                    <Column>
                        <Label>Currency</Label>
                        <div>{paylink.currency}</div>
                    </Column>

                    {storeInfo && (
                        <StoreColumn>
                            <Label>Store</Label>
                            <CompactStoreLabel store={storeInfo} />
                        </StoreColumn>
                    )}
                </Row>
            </LeftHeaderContent>
            <HeaderRight>
                <OrderTotalWrapper>
                    <div>Total</div>
                    <TotalWrapper>
                        <OrderTotal>{formatNumber(paylink.total ?? 0)}</OrderTotal>
                        <VatRate>
                            (<Money>{paylink.totalVat}</Money> VAT)
                        </VatRate>
                    </TotalWrapper>
                </OrderTotalWrapper>

                <MultiactionButton actions={actionList}>
                    <>
                        Actions <ArrowDownSLineIcon size={theme.icon.size.small} />
                    </>
                </MultiactionButton>
            </HeaderRight>
        </HeaderContentWrapper>
    );
};

export default withTheme(PaylinkDetailsHeader) as React.ComponentType<
    Omit<PaylinkDetailsHeaderProps, 'theme'>
>;

const HeaderRight = styled.div`
    display: flex;
    justify-self: right;
    gap: 2rem;
    align-items: end;
    padding: 0 0 0.4rem 1.5rem;
    border-left: 0.2rem solid ${props => props.theme.colors.text.subtle};
`;

const OrderTotal = styled.div`
    font-family: ${props => props.theme.text.font.medium};
    font-size: ${props => props.theme.text.size.medium};
`;

const VatRate = styled.div`
    font-size: ${props => props.theme.text.size.small};
`;

const StoreColumn = styled(Column)`
    max-width: 20rem;
`;

const OrderTotalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const TotalWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
`;

const SalesPerson = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
    width: 100%;
    max-width: 15rem;
`;

const LeftHeaderContent = styled.div`
    display: flex;
    gap: 1.5rem;
`;

const StatusWrapper = styled.div`
    margin-top: -0.4rem;
    display: flex;
    align-self: center;
`;
const HeaderContentWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0 2.5rem;
    margin: 0 auto;
    max-width: 144rem;
    @media (max-width: 50rem) {
        max-width: 100rem;
    }
    transition: max-width 0.5s;
`;
