import React from 'react';
import styled, { DefaultTheme, withTheme } from 'styled-components';
import UserLineIcon from 'remixicon-react/UserLineIcon';
import BuildingLineIcon from 'remixicon-react/BuildingLineIcon';
import CustomerType from '../../../types/CustomerType';

interface Props {
    customerType?: CustomerType;
    theme: DefaultTheme;
}

const CustomerIcon: React.FC<Props> = ({ customerType, theme }: Props) => {
    const PrivateFirm: React.FC = () => {
        return (
            <>
                <BuildingLineIcon size={theme.icon.size.small} />
                <UserLineIcon size={theme.icon.size.small} />
            </>
        );
    };

    const getCustomerTypeIcon = (type: string) => {
        switch (type) {
            case CustomerType.Company:
            case CustomerType.Business:
                return <BuildingLineIcon size={theme.icon.size.small} />;
            case CustomerType.Private:
            case CustomerType.Person:
                return <UserLineIcon size={theme.icon.size.small} />;
            case CustomerType.PrivateFirm:
                return <PrivateFirm />;
            default:
                return <UserLineIcon size={theme.icon.size.small} />;
        }
    };

    return <>{customerType && <IconWrapper>{getCustomerTypeIcon(customerType)}</IconWrapper>}</>;
};

CustomerIcon.defaultProps = {
    customerType: undefined,
};

const IconWrapper = styled.div`
    color: ${props => props.theme.colors.subtle.regular};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export default withTheme(CustomerIcon) as React.ComponentType<Omit<Props, 'theme'>>;
