import styled from 'styled-components';
import React from 'react';

interface TwoRowCellProps {
    upper?: string | number;
    lower?: string;
    align?: string;
}

const AlignedContainer = styled.div<TwoRowCellProps>`
    text-align: ${props => props.align};
`;

const UpperContainer = styled.div`
    width: 100%;
    min-width: max-content;
`;

const LowerContainer = styled.div`
    padding-top: 0.2rem;
    color: ${props => props.theme.colors.text.secondary};
`;

const TwoRowCell: React.FC<TwoRowCellProps> = ({ upper, lower, align }: TwoRowCellProps) => {
    return (
        <AlignedContainer align={align}>
            {upper && <UpperContainer>{upper}</UpperContainer>}
            {lower && <LowerContainer>{lower}</LowerContainer>}
        </AlignedContainer>
    );
};

TwoRowCell.defaultProps = {
    upper: undefined,
    lower: undefined,
    align: 'left',
};

export default TwoRowCell;
