import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children: ReactNode;
    position: ModalPosition;
    noScroll?: boolean;
}

export type ModalPosition = 'header' | 'content' | 'footer' | 'error';

const ModalContainer: React.FC<Props> = ({ position, children, noScroll = false }: Props) => {
    return (
        <Container noScroll={noScroll} position={position}>
            {children}
        </Container>
    );
};

const Container = styled.div<Props>`
    grid-area: ${props => props.position};
    padding: ${props => props.theme.layout.padding.large};

    ${props => props.position === 'content' && !props.noScroll && `overflow-y: auto;`};

    ${props => props.position === 'error' && `align-self: end;`};

    ${props =>
        (props.position === 'header' || props.position === 'footer') &&
        `background: ${props.theme.colors.subtle.light};
                  ${
                      props.position === 'header'
                          ? `white-space: nowrap; overflow: hidden; border-bottom: 0.1rem solid ${props.theme.colors.border}; border-radius: 0.5rem 0.5rem 0 0;`
                          : `border-top: 0.1rem solid ${props.theme.colors.border}; border-radius: 0 0 0.5rem 0.5rem;`
                  };
        `}
`;

ModalContainer.defaultProps = {
    noScroll: false,
};

export default ModalContainer;
