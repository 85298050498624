import React from 'react';
import SyncLoader from 'react-spinners/SyncLoader';
import styled, { DefaultTheme, withTheme } from 'styled-components';

interface SpinnerProps {
    loading?: boolean;
    text?: string;
    color?: string;
    size?: number;
    theme: DefaultTheme;
}

const Spinner: React.FC<SpinnerProps> = ({ text, loading, theme, color, size }: SpinnerProps) => {
    return (
        <Container data-testid="spinner">
            {text && <p hidden={!loading}>{text}</p>}
            <SyncLoader size={size || 15} color={color || theme.colors.primary} loading={loading} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

Spinner.defaultProps = {
    loading: true,
    text: undefined,
    color: undefined,
    size: undefined,
};

export default withTheme(Spinner) as React.ComponentType<Omit<SpinnerProps, 'theme'>>;
