/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SortingRule } from 'react-table';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { DefaultTheme } from 'styled-components';
import { transparentize } from 'polished';
import { withTheme } from 'styled-components';
import { AppState } from '../../../store/appstate';
import Spinner from '../../atoms/Spinner/Spinner';
import CardBody from '../../atoms/Card/CardBody';
import Card from '../../atoms/Card/Card';
import ErrorMessage from '../../atoms/Message/Error/ErrorMessage';
import { ErrorType } from '../../../types/response/ErrorCodes';
import TableHeaderTypes from '../../../types/tableHeaderType';
import { Settlement } from '../../../types/report/Settlement';
import settlementsActions from '../../../store/report/settlement/actions';
import fetchSettlementFile from '../../../services/helpers/fetchFilesHelper';
import { checkMatchByLowercase } from '../../../services/helpers/orderColumnFilter';
import { parseSearchUrl, sortColumns } from '../../../services/helpers/queryStringHelpers';
import Snackbar from '../../atoms/Snackbar/Snackbar';
import { LinkData } from '../../../types/response/LinkData';
import {
    SettlementState,
    settlementDownloadFailed,
    clearSettlementDownloadError,
    settlementDownloadSetProcessing,
} from '../../../store/report/settlement/settlementSlice';
import {
    browsePaginationWithDispatch,
    createSearchPath,
} from '../../../services/helpers/pagination';
import {
    defaultSettlementColumnHeaders,
    generateSettlementColumnHeaders,
} from './HeaderColumns/SettlementColumnHeaders';
import { useAppDispatch } from '../../../store';
import Table from '../../atoms/Table/Table';
import { ColumnDef } from '@tanstack/react-table';

interface StyleProps {
    isSearching: boolean;
}

interface SearchResultProps {
    availableStores: any;
    tableHeaderType: TableHeaderTypes;
    theme: DefaultTheme;
}

const SearchResults: React.FC<SearchResultProps> = ({
    availableStores,
    tableHeaderType,
    theme,
}: SearchResultProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { pathname, search: queryString } = useLocation();
    const {
        isSearching,
        searchFailed,
        error,
        searchResult: { links, metaData, data = [] },
    } = useSelector<AppState, SettlementState>(s => s.report.settlement);

    const sessionColumns = useSelector<AppState, string[]>(
        s => s.userSettings.settlementColumnFilters
    );
    const singleFileDownloadProcessing = useSelector<AppState, boolean>(
        s => s.report.settlement.singleFileDownloadProcessing
    );

    const [shouldReload, reload] = useState(true);
    const { sort, perPage, columns } = parseSearchUrl(queryString, sessionColumns);

    const sortReports = (sortby: Array<SortingRule<any>>) => {
        const params = sortColumns(sortby, links.self?.query);

        navigate(createSearchPath(params, pathname));
        dispatch(settlementsActions.search(params));
    };

    const fileDownloadError = useSelector<AppState, boolean>(
        s => s.report.settlement.singleFileDownloadError
    );
    const token = useSelector<AppState, string | undefined>(s => s.session.token);

    const sendDownloadError = () => {
        dispatch(settlementDownloadFailed());
    };
    const setIsProcessing = (isProcessing: boolean) => {
        dispatch(settlementDownloadSetProcessing(isProcessing));
    };

    const onRowDownloadClicked = (link: LinkData) => {
        dispatch(clearSettlementDownloadError());

        if (token) fetchSettlementFile(token, link, sendDownloadError, setIsProcessing);
    };

    const allColumnHeaders = generateSettlementColumnHeaders(availableStores, onRowDownloadClicked);

    const startingColumns = allColumnHeaders.filter(x =>
        defaultSettlementColumnHeaders.includes(x.header as string)
    );

    const columnsForPaging = columns.length > 0 ? columns : defaultSettlementColumnHeaders;
    const queryFilterColumns = columnsForPaging.map(x =>
        allColumnHeaders.find(y => y.header === x || checkMatchByLowercase(y.header as string, x))
    ) as ColumnDef<Settlement>[];

    const columnString = `&columns=${columnsForPaging.join(',')}`;
    const isInvalidArray = queryFilterColumns.some(x => x === undefined);
    const tableColumns =
        queryFilterColumns.length !== 0 && !isInvalidArray ? queryFilterColumns : startingColumns;

    useEffect(() => {
        singleFileDownloadProcessing
            ? (document.body.style.overflow = 'hidden')
            : (document.body.style.overflow = 'unset');

        if (singleFileDownloadProcessing) document.documentElement.style.overflowY = 'hidden';

        return () => {
            if (singleFileDownloadProcessing) document.documentElement.style.overflowY = 'initial';
        };
    }, [singleFileDownloadProcessing]);

    useEffect(() => {
        let mounted = true;
        if (shouldReload && mounted) {
            dispatch(settlementsActions.search(queryString));
            reload(false);
        }
        return () => {
            mounted = false;
            reload(false);
        };
    }, [shouldReload, dispatch, queryString]);

    if (searchFailed) {
        return <ErrorMessage error={error} errorHeader={ErrorType.Search} />;
    }

    return (
        <div>
            {fileDownloadError && (
                <Snackbar
                    clear={() => dispatch(clearSettlementDownloadError())}
                    open={fileDownloadError}
                    color={theme.colors.snackbar.error.background}
                    message="Could not download file. Try again later"
                />
            )}

            <Card>
                <CardBody>
                    {singleFileDownloadProcessing && (
                        <DownloadSpinnerWrapper>
                            <Spinner text="Downloading..." loading={singleFileDownloadProcessing} />
                        </DownloadSpinnerWrapper>
                    )}

                    {isSearching && (
                        <SpinnerWrapper>
                            <Spinner text="Searching..." loading={isSearching} />
                        </SpinnerWrapper>
                    )}

                    <TableWrapper isSearching={isSearching || singleFileDownloadProcessing}>
                        <Table<Settlement>
                            data={data}
                            allColumns={allColumnHeaders}
                            columns={tableColumns}
                            savedColumns={sessionColumns}
                            setColumns={_ => {}}
                            defaultColumns={defaultSettlementColumnHeaders}
                            tableHeaderType={tableHeaderType}
                            sort={{
                                initialSortbyState: sort,
                                manualsorting: true,
                                sortFunction: sortReports,
                            }}
                            pagination={{
                                metaData,
                                links,
                                pageSize: perPage,
                                nextPage: () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        `${links.next?.query}${columnString}`,
                                        settlementsActions.search(
                                            `${links.next?.query}${columnString}`
                                        ),
                                        dispatch
                                    ),
                                previousPage: () =>
                                    browsePaginationWithDispatch(
                                        navigate,
                                        pathname,
                                        `${links?.prev?.query}${columnString}`,
                                        settlementsActions.search(
                                            `${links?.prev?.query}${columnString}`
                                        ),
                                        dispatch
                                    ),
                            }}
                            columnMenu
                        />
                    </TableWrapper>
                </CardBody>
            </Card>
        </div>
    );
};

const DownloadSpinnerWrapper = styled.div`
    position: fixed;
    background: ${props => transparentize(0.5, props.theme.colors.light)};
    left: 50%;
    top: 35%;
    margin-left: -1rem;
    margin-top: 10rem;
    z-index: 8;
`;

const SpinnerWrapper = styled.div`
    position: absolute;
    background: ${props => transparentize(0.5, props.theme.colors.light)};
    left: 50%;
    top: 25%;
    margin-left: -1rem;
    margin-top: 28rem;
`;

const TableWrapper = styled.div<StyleProps>`
    opacity: ${props => (props.isSearching ? 0.5 : 1)};
`;

export default withTheme(SearchResults) as React.ComponentType<Omit<SearchResultProps, 'theme'>>;
